import { forwardRef, Fragment, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller, useFieldArray } from "react-hook-form";

import Slide from '@mui/material/Slide';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { default as AutocompleteMui } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
/* import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import AddBoxIcon from '@mui/icons-material/AddBox'; */


import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';

import dayjs from 'dayjs';

import Text from "../../heading/Text";
import { AddSignaturesStyles } from "./index.css";
import { to, verifiVariable } from "../../../utils/enums";
import { Context } from "../../../context/utilsContext";

// import { ReactComponent as DeleteIconInput } from "./img/delete-icon-input.svg";
import ScreenSizeHook from "../../../hooks/ScreenSizeHook";
import { saveSchedulingInfo } from "../../../store/scheduling";
import { blackList, getNotary } from "./services";

const styledButtoms = {
    marginBottom: '25px'
}

// eslint-disable-next-line
const AddDirectionButton = styled(Button)((props) => ({
    borderRadius: '7px',
    fontStyle: 'normal',
    boxShadow: 'none',
    fontWeight: 'bolder',
    textTransform: 'none',
    fontSize: 16,
    padding: '11px 40px',
    marginTop: '20px',
    fontFamily: [
        '"PoppinsBold"'
    ].join(','),
}));

// eslint-disable-next-line
const CustomSnackBar = styled(Snackbar)(({ theme }) => ({
    '& .MuiSnackbarContent-root': {
        backgroundColor: 'transparent',
        boxShadow: 'none'
    }
}));

// eslint-disable-next-line
const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Scheduling = () => {
    const screenSizeHook = ScreenSizeHook();
    const { nextPage, setNextPage, toResumen, setToResumen } = useContext(Context);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [valueCalendar, setValueCalendar] = useState(dayjs(dayjs().format('YYYY-MM-DD')));
    const [valueTimePicker, setValueTimePicker] = useState(dayjs(dayjs().format('YYYY-MM-DDTHH:mm')));
    const styles = AddSignaturesStyles();
    const {
        session: {
            login: { user }
        },
        scheduling: {
            fourthStep: {
                hours,
                commune,
                jurisdiction,
                date,
                directions
            },
            communeList,
            juridictionList,
            notaryList
        }
    } = useSelector((state) => state);
    // eslint-disable-next-line
    const [blackListDate, setBlackListDate] = useState([]);
    const [blackListTime, setBlackListTime] = useState([]);
    const refInputControl = useRef();

    // eslint-disable-next-line
    const tomorrow = dayjs().add(1, 'day');
    const today = dayjs();
    // eslint-disable-next-line
    const twoPM = dayjs().set('hour', 14).startOf('hour');
    const [endTime, setEndTime] = useState(dayjs().set('hour', 17).startOf('hour'));
    const [startTime, setStartTime] = useState(dayjs().set('hour', 9).startOf('hour'));
    const nameInputHours = 'hours';
    const nameInputCommune = 'commune';
    const nameInputJurisdiction = 'jurisdiction';
    const nameInputDate = 'date';

    const { getValues, setValue, control, formState: { errors }, trigger, watch } = useForm({
        defaultValues: {
            [nameInputHours]: dayjs(dayjs().format('YYYY-MM-DDTHH:mm')),
            [nameInputCommune]: '',
            [nameInputJurisdiction]: '',
            [nameInputDate]: dayjs(dayjs().format('YYYY-MM-DD'))
        }
    });

    const jurisdictionWatch = watch(nameInputJurisdiction);

    const { fields, append, remove } = useFieldArray({
        control,
        name: "directions"
    });

    const isWeekend = (date) => {
        const day = date.day();

        return day === 0 || day === 6;
    }

    const addNewDirection = () => {
        if (fields.length < 2) {
            append({ direction: '' });
        }
    }

    // eslint-disable-next-line
    const removeDirection = (index) => {
        remove(index);
    }


    const consulNotary = async () => {
        try {

            const notary = notaryList.find((item) => jurisdictionWatch['id'] === item['juridiction_id']);

            const { token } = user;

            const notaryReq = await getNotary(notary['id'], token);

            if (notaryReq['status'] === 'Success') {

                setStartTime(dayjs().set('hour', parseInt(notaryReq['data']['start_hour'].split(':')[0])).startOf('hour'));

                setEndTime(dayjs().set('hour', parseInt(notaryReq['data']['end_hour'].split(':')[0])).startOf('hour'));

            }

        } catch (error) {
            console.log(error);
        }
    }

    const consulBlackList = async () => {
        try {

            const { token } = user;

            const response = await blackList(dayjs().format('YYYY-MM'), token);

            if (response['status'] === 'Success') setBlackListDate(response['data']);

        } catch (error) {
            console.log(error);
        }
    }

    const saveData = async (url) => {

        try {

            await trigger();

            const formData = { ...getValues() };

            if (Object.keys(errors).length === 0) {
                dispatch(saveSchedulingInfo({ indexe: 'fourthStep', value: formData }));
                navigate(url);
            }

        } catch (error) {
            console.log('error ===>> ', error);
        }

    }

    useEffect(() => {

        if (verifiVariable(jurisdiction)) {

            juridictionList.forEach(element => {
                if (element['name'] === 'Santiago') setValue(nameInputJurisdiction, element);
            });

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [juridictionList]);

    useEffect(() => {

        if (!verifiVariable(jurisdictionWatch)) {
            consulBlackList();
            consulNotary();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jurisdictionWatch]);

    useEffect(() => {
        console.log('screenSizeHook: ', screenSizeHook);//TODO bloquear asignacion de firma en monitores con dimenciones de md hacia abajo
    }, [screenSizeHook]);

    useEffect(() => {

        if (nextPage !== 0) saveData(to.SCHEDULE_CREATION_PAY);

        return () => {
            setNextPage(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nextPage]);

    useEffect(() => {

        if (!verifiVariable(toResumen)) saveData(toResumen);

        return () => {
            setToResumen(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toResumen]);

    useEffect(() => {

        if (verifiVariable(directions)) {
            if (fields.length < 1) {
                addNewDirection();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

        if (!verifiVariable(date)) {
            setValueCalendar(dayjs(date));
            setValue(nameInputDate, date);
        }

        if (!verifiVariable(date) && !verifiVariable(hours)) {
            setValueTimePicker(dayjs(`${date}T${hours}`));
            setValue(nameInputHours, hours);
        }

        if (!verifiVariable(commune)) setValue(nameInputCommune, commune);
        if (!verifiVariable(jurisdiction)) setValue(nameInputJurisdiction, jurisdiction);

        if (!verifiVariable(directions)) {
            directions.forEach(element => {
                append(element);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (

        <div className="procedure-creation__form-container">

            <div className="form__container" style={{ width: '90%' }}>

                <form>

                    <Grid container spacing={2}>

                        <Grid item xs={6} >

                            <Controller
                                name={nameInputDate}
                                control={control}
                                rules={{
                                    required: "Este campo es requerido",
                                    validate: {
                                        optionVlidate: (value) => typeof value === "string" || 'Debe seleccionar una fecha'
                                    }
                                }}
                                render={({
                                    field: { onChange, onBlur, value, ref },
                                    fieldState
                                }) => {
                                    return (
                                        <FormControl
                                            style={styledButtoms}
                                            fullWidth
                                            error={fieldState.error ? true : false}
                                        >

                                            <LocalizationProvider dateAdapter={AdapterDayjs}>

                                                <DemoContainer components={['DateCalendar']}>

                                                    <DemoItem
                                                        label={
                                                            <Text
                                                                className={styles.labelStyles}
                                                                color={fieldState.error ? 'error.main' : undefined}
                                                                align="left"
                                                                mgButton
                                                                variant="h6"
                                                            >
                                                                Seleccione una fecha
                                                            </Text>
                                                        }
                                                    >

                                                        <DateCalendar
                                                            disablePast
                                                            // shouldDisableDate={(day) => (today.format('YYYY-MM-DD') === day.format('YYYY-MM-DD') || isWeekend(day))}
                                                            shouldDisableDate={(day) => [today.format('YYYY-MM-DD')].includes(day.format('YYYY-MM-DD')) || isWeekend(day)}
                                                            value={valueCalendar}
                                                            onChange={(newValue) => {

                                                                const blackListData = [...blackListDate].filter((item) => item['date'] === newValue.format('YYYY-MM-DD'));
                                                                const arrayData = [];

                                                                blackListData.forEach(element => {
                                                                    arrayData.push(element['start_hour'].split(':')[0]);
                                                                    // arrayData.push(element['end_hour'].split(':')[0]);
                                                                });

                                                                setBlackListTime(arrayData);

                                                                setValueCalendar(newValue);

                                                                onChange(newValue.format('YYYY-MM-DD'));
                                                            }}
                                                        />

                                                    </DemoItem>

                                                </DemoContainer>

                                            </LocalizationProvider>

                                            <FormHelperText hidden={fieldState.error ? false : true}>{fieldState.error ? fieldState.error.message : null}</FormHelperText>

                                        </FormControl>
                                    );
                                }}
                            />

                        </Grid>

                        <Grid item xs={6}>

                            <Controller
                                name={nameInputHours}
                                control={control}
                                rules={{
                                    required: "Este campo es requerido",
                                    validate: {
                                        optionVlidate: (value) => typeof value === "string" || 'Debe seleccionar una hora'
                                    }
                                }}
                                render={({
                                    field: { onChange, onBlur, value, ref },
                                    fieldState
                                }) => {
                                    return (
                                        <FormControl
                                            style={styledButtoms}
                                            fullWidth
                                            error={fieldState.error ? true : false}
                                        >

                                            <LocalizationProvider dateAdapter={AdapterDayjs}>

                                                <DemoContainer
                                                    components={[
                                                        'MobileTimePicker'
                                                    ]}
                                                >

                                                    <DemoItem
                                                        label={
                                                            <Text
                                                                className={styles.labelStyles}
                                                                color={fieldState.error ? 'error.main' : undefined}
                                                                align="left"
                                                                mgButton
                                                                variant="h6"
                                                            >
                                                                Seleccione una hora
                                                            </Text>
                                                        }
                                                    >

                                                        <MobileTimePicker
                                                            minTime={startTime}
                                                            maxTime={endTime}
                                                            shouldDisableTime={(hour, view) => {
                                                                return view === "hours" && blackListTime.includes(hour.format('HH'));
                                                            }}
                                                            value={valueTimePicker}
                                                            onChange={(newValue) => {
                                                                setValueTimePicker(newValue);
                                                                onChange(newValue.format('HH:mm'));
                                                            }}
                                                        />

                                                    </DemoItem>

                                                </DemoContainer>

                                            </LocalizationProvider>

                                            <FormHelperText hidden={fieldState.error ? false : true}>{fieldState.error ? fieldState.error.message : null}</FormHelperText>

                                        </FormControl>
                                    );
                                }}
                            />

                            <Controller
                                name={nameInputCommune}
                                control={control}
                                rules={{
                                    required: "Este campo es requerido"
                                }}
                                render={({
                                    field: { onChange, onBlur, value, ref },
                                    fieldState
                                }) => {
                                    return (
                                        <FormControl
                                            style={styledButtoms}
                                            fullWidth
                                            error={fieldState.error ? true : false}
                                        >

                                            <label>
                                                <Text
                                                    className={styles.labelStyles}
                                                    color={fieldState.error ? 'error.main' : undefined}
                                                    align="left"
                                                    mgButton
                                                    variant="h6"
                                                >
                                                    Seleccione comuna
                                                </Text>
                                            </label>

                                            <AutocompleteMui
                                                defaultValue={commune}
                                                isOptionEqualToValue={(option, value) => option.name === value.name}
                                                multiple={false}
                                                options={communeList}
                                                getOptionLabel={(option) => option.name}
                                                onChange={(e, options) => { onChange(options); }}
                                                renderInput={(params) => <TextField
                                                    ref={refInputControl}
                                                    {...params}
                                                    placeholder='¿Qué trámite necesitas gestionar?'
                                                    className={styles.autocompleteInput}
                                                />}

                                            />

                                            <FormHelperText hidden={fieldState.error ? false : true}>{fieldState.error ? fieldState.error.message : null}</FormHelperText>

                                        </FormControl>
                                    );
                                }}
                            />

                            {/* <Controller
                                name={nameInputJurisdiction}
                                control={control}
                                rules={{
                                    required: "Este campo es requerido"
                                }}
                                render={({
                                    field: { onChange, onBlur, value, ref },
                                    fieldState
                                }) => {
                                    return (
                                        <FormControl
                                            fullWidth
                                            error={fieldState.error ? true : false}
                                        >

                                            <label>
                                                <Text
                                                    className={styles.labelStyles}
                                                    color={fieldState.error ? 'error.main' : undefined}
                                                    align="left"
                                                    mgButton
                                                    variant="h6"
                                                >
                                                    Seleccione jurisdicción
                                                </Text>
                                            </label>

                                            <AutocompleteMui
                                                defaultValue={jurisdiction}
                                                isOptionEqualToValue={(option, value) => option.name === value.name}
                                                multiple={false}
                                                options={juridictionList}
                                                getOptionLabel={(option) => option.name}
                                                onChange={(e, options) => { onChange(options); }}
                                                renderInput={(params) => <TextField
                                                    ref={refInputControl}
                                                    {...params}
                                                    placeholder='¿Qué trámite necesitas gestionar?'
                                                    className={styles.autocompleteInput}
                                                />}

                                            />

                                            <FormHelperText hidden={fieldState.error ? false : true}>{fieldState.error ? fieldState.error.message : null}</FormHelperText>

                                        </FormControl>
                                    );
                                }}
                            /> */}

                        </Grid>

                        <Grid item xs={12}>

                            {fields.map((item, index) => (

                                <Fragment key={item.id}>

                                    <Controller
                                        name={`directions.${index}.direction`}
                                        control={control}
                                        rules={{
                                            required: "Este campo es requerido"
                                        }}
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                            fieldState,
                                        }) => {
                                            return (
                                                <FormControl
                                                    fullWidth
                                                    error={fieldState.error ? true : false}
                                                >
                                                    <label>
                                                        <Text
                                                            color={fieldState.error ? 'error.main' : undefined}
                                                            className={styles.labelStyles}
                                                            align="left"
                                                            mgButton
                                                            variant="h6"
                                                        >
                                                            Dirección {index + 1}
                                                        </Text>
                                                    </label>

                                                    <TextField
                                                        type="text"
                                                        error={fieldState.error ? true : false}
                                                        ref={ref}
                                                        onBlur={onBlur}
                                                        className={styles.autocompleteInput}
                                                        onChange={onChange}
                                                        value={value}
                                                        /* InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">

                                                                    <IconButton disabled={fields.length === 1} aria-label="delete" onClick={() => removeDirection(index)}>

                                                                        <DeleteIconInput />

                                                                    </IconButton>

                                                                </InputAdornment>
                                                            )
                                                        }} */

                                                        placeholder={"Rosario Norte 407. Las Condes Santiago, 7561156."}
                                                        color={
                                                            fieldState.error
                                                                ? "error"
                                                                : "success"
                                                        }
                                                        focused={
                                                            fieldState.isTouched ||
                                                            fieldState.isDirty
                                                        }
                                                        fullWidth
                                                    />

                                                    <FormHelperText hidden={fieldState.error ? false : true}>{fieldState.error ? fieldState.error.message : null}</FormHelperText>

                                                    <br />

                                                </FormControl>
                                            );
                                        }}
                                    />

                                </Fragment>

                            ))}

                        </Grid>

                    </Grid>

                    {/* <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        spacing={2}
                    >

                        <AddDirectionButton
                            disabled={fields.length === 2}
                            variant="outlined"
                            startIcon={<AddBoxIcon className="icon--blue icon--lg" />}
                            onClick={() => addNewDirection()}
                        >
                            Agregar dirección
                        </AddDirectionButton>

                    </Grid> */}

                </form>

            </div>

        </div>

    );
}

export default Scheduling;