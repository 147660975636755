import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
    name: "formTramit",
    initialState: {
        previewPdfSigned: undefined,
        firstStep: {},
        secondStep: [],
        thirdStep: {},
        fourthStep: undefined,
        firms: {},
        editSigner: undefined
    },
    reducers: {

        FORM_USERS_REGISTER_SAVE_INFO: (state, action) => {
            state[action.payload['indexe']] = action.payload['value'];
        },

        FORM_GET_EDIT_SIGNER: (state, action) => {
            state.editSigner = action.payload;
        },

        FORM_CLEAR_EDIT_SIGNER: (state, action) => {
            state.editSigner = undefined;
        },

        FORM_USERS_REGISTER_PURGE_STATE: (state, action) => {
            state.firstStep = {};
            state.secondStep = [];
            state.thirdStep = {};
            state.fourthStep = undefined;
            state.previewPdfSigned = undefined;
            state.firms = {};
            state.editSigner = undefined
        }

    }
});

export const {
    FORM_CLEAR_EDIT_SIGNER,
    FORM_GET_EDIT_SIGNER,
    FORM_USERS_REGISTER_SAVE_INFO,
    FORM_USERS_REGISTER_PURGE_STATE
} = slice.actions;

export const getEditSigner = (data) => {
    return { type: FORM_GET_EDIT_SIGNER.type, payload: data };
}

export const clearEditSigner = () => {
    return { type: FORM_CLEAR_EDIT_SIGNER.type, payload: undefined };
}

export const saveFormInfo = (data) => {
    return { type: FORM_USERS_REGISTER_SAVE_INFO.type, payload: data };
};

export const clearForm = () => {
    return { type: FORM_USERS_REGISTER_PURGE_STATE.type, payload: null };
};

export default slice.reducer;
