import React from 'react';

import { Box } from '@mui/material';

import Text from '../../../../heading/Text';
import { styles } from '../../../../../helpers';

const WithDocument = ({ item }) => {

    return (
        <Box sx={{
            position: "relative",
            display: "flex",
            alignContent: 'center',
            padding: "6px",
            whiteSpace: 'normal'
        }} className="flex--1 flex--center">

            <Text sx={styles.userNameStyles} variant="h6">{item === 0 ? 'NO' : 'SI'}</Text>

        </Box>
    );

};

export default WithDocument;