import { forwardRef, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Animated } from "react-animated-css";
import { FaWhatsapp } from "react-icons/fa";
import { Steps } from "intro.js-react";
import { Controller, useForm } from "react-hook-form";

import TextField from '@mui/material/TextField';
import DialogContentText from '@mui/material/DialogContentText';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import IconButton from '@mui/material/IconButton';

import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import * as PdfjsLib from 'pdfjs-dist';

import { PositionFirmStyles } from "./index.css";
import DraggableBox from "./molecules/draglableBox";
import AddSign from "./molecules/addSign";
import Btn from "../../buttons/Button";
import { stepsTramit3_4 } from "../../toursSteps/tourTramitStep3/inde";
import { optionsSteps, randomRgba } from "../../../utils/enums";
import SignatoryAlert from "./molecules/signatoryAlert";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const buildId = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
};

const PositionFirm = ({ filePdf, resetFile, editedFile, custonSigner }) => {
    const {
        contract: {
            contractCreator: {
                contractCreatedDetails
            }
        }
    } = useSelector((state) => state);
    const styles = PositionFirmStyles();
    const [fileBase64, setFileBase64] = useState(null);
    const [docPdf, setDocPdf] = useState(null);
    const [firms, setFirms] = useState({});
    // eslint-disable-next-line
    const [zoom, setZoom] = useState(1);
    const refCanvas = useRef();
    const refPdfViewer = useRef();
    const refInputControl = useRef();
    const [openSigModal, setOpenSigModal] = useState(false);
    const [layerX, setLayerX] = useState(0);
    const [layerY, setLayerY] = useState(0);
    const [open, setOpen] = useState(false);
    const [missingSigners, setMissingSigners] = useState([]);
    const [stepsEnabled1, setStepsEnabled1] = useState(false);
    // eslint-disable-next-line
    const [list, setList] = useState((![null, undefined, ''].includes(custonSigner) ? custonSigner : (contractCreatedDetails !== null ? contractCreatedDetails.firmantes : [])).map((item) => ({ ...item, textColor: randomRgba() })));

    const { control, watch, setValue } = useForm({
        defaultValues: {
            currentPage: 1,
        }
    });

    let currentPage = watch('currentPage');

    const handleClickOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const boxDelete = (id) => {

        const objectSignet = { ...firms };

        delete objectSignet[id];

        setFirms(objectSignet);
    }

    const verifySigners = () => {

        const addedSignatures = Object.values(firms);
        const missing = [];

        list.forEach(element => {

            const findItem = addedSignatures.find(firm => firm['rut'] === element['RUT']);

            if (!findItem) missing.push(element['full_name']);

        });

        if (missing.length !== 0) {
            setMissingSigners(missing);
        } else {
            descargar();
        }

    }

    const descargar = async () => {

        try {

            if ([null, undefined, ''].includes(fileBase64)) return;

            const pdfDoc = await PDFDocument.load(fileBase64, {ignoreEncryption: true});

            // Embed the Helvetica font
            const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

            // Get the first page of the document
            const pages = pdfDoc.getPages();

            Object.keys(firms).forEach((item) => {

                const signedPage = pages[(firms[item]['page']) - 1];

                // Draw a string of text diagonally across the first page
                signedPage.drawText(firms[item]['text'], Object.assign({ ...firms[item] }, { font: helveticaFont }));

            });

            const pdfBytes = await pdfDoc.save();

            const file = new File([pdfBytes], filePdf.name, { type: 'application/pdf' });

            // download(file, "prueba-javi.pdf", "application/pdf");

            editedFile(file);

        } catch (error) {
            handleClickOpen();
        }

    }

    const goPrevious = () => {
        if (docPdf === null || currentPage === 1) return;
        setValue('currentPage', (currentPage) -= 1);
    }

    const goNext = () => {
        if (docPdf == null || currentPage >= docPdf._pdfInfo.numPages) return;
        setValue('currentPage', (currentPage) += 1);
    }

    const addFirmante = (signer) => {

        if ([null, undefined, ''].includes(signer)) return;

        const parseSigner = JSON.parse(signer);

        setFirms((itemList) => Object.assign({
            ...itemList,
            [buildId(4)]: {
                numbSigned: parseSigner['sign'] + 1,
                text: `[[s|${parseSigner['sign']}]]`,
                textTootip: parseSigner['full_name'],
                rut: parseSigner['RUT'],
                x: layerX,
                y: layerY,
                size: 20,
                textColor: parseSigner['textColor'],
                page: currentPage,
                color: rgb(1, 1, 1),
                rotate: degrees(0)
            }
        }));

    }

    const openModal = (e) => {
        const element = e;

        const nodeName = element['target']['nodeName'];

        if (!['CANVAS'].includes(nodeName)) return;

        setLayerX(element['nativeEvent']['layerX']);

        setLayerY(element['nativeEvent']['layerY']);

        setOpenSigModal(true);
    }

    useEffect(() => {

        (async () => {

            if ([null, undefined, ''].includes(filePdf) || list.length === 0) return

            if (filePdf.type.indexOf('pdf') === -1) {
                console.log('no es un pdf');
                // handleOpenWarningModal('En este módulo de prueba sólo se aceptan archivos con formato PDF.')
                return
            }

            /* if (filePdf.size > 1000000) {
                // Mayor a 1Mb
                handleOpenWarningModal('Para este módulo de prueba sólo se aceptan archivos de máximo 1MB.')
                return
            }

            if (!termsAndConditionsAccepted) {
                handleOpenWarningModal('Es necesario que aceptes los términos y condiciones de uso de este módulo.')
                return
            } */

            const reader = new global.FileReader();

            reader.onload = async () => {

                setFileBase64(reader.result);

                const loadPdfDocumentTask = PdfjsLib.getDocument(reader.result);

                const pdf = await loadPdfDocumentTask.promise;

                setDocPdf(pdf);

                setStepsEnabled1(true);

            }

            reader.readAsDataURL(filePdf);

        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filePdf]);

    useEffect(() => {

        (async () => {

            const parsetCurrentPage = parseInt(currentPage);

            if ([null, undefined, ''].includes(docPdf) || [0, null, undefined].includes(parsetCurrentPage) || parsetCurrentPage > docPdf._pdfInfo.numPages) return;

            let page = await docPdf.getPage(parsetCurrentPage);

            let canvas = refCanvas.current;
            let ctx = canvas.getContext('2d');
            let viewport = page.getViewport({ scale: zoom });

            canvas.width = viewport.width;
            canvas.height = viewport.height;
            page.render({
                canvasContext: ctx,
                viewport: viewport
            });

            viewport = page.getViewport({ scale: zoom });

            window['jQuery'](refPdfViewer.current).width(viewport.width).height(viewport.height);

            window['jQuery'](refInputControl.current).width(viewport.width - (151.5 * 2));

        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [docPdf, currentPage]);

    return (
        <div className={styles.mainContainer}>{/* missingSigners */}

            <SignatoryAlert agree={descargar} missingSigners={missingSigners} />

            <Steps
                enabled={stepsEnabled1}
                steps={stepsTramit3_4}
                initialStep={0}
                onExit={() => setStepsEnabled1(false)}
                options={optionsSteps}
            />

            <Dialog
                fullWidth={true}
                TransitionComponent={Transition}
                open={open}
                onClose={handleClose}
                maxWidth='xs'
                className={styles.mainContainerDialog}
            >

                <DialogTitle sx={{ textAlign: 'center', color: 'red' }}>
                    Ah ocurrido un error!
                </DialogTitle>

                <DialogContent>

                    <DialogContentText>
                        Lo sentimos, ah ocurrido un error.
                        Le pedimos disculpas por las molestias causadas y lo invitamos a
                        reportar el error a nuestro equipo de soporte desde el siguiente enlace.

                        <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="label"
                            onClick={() => {
                                window.open("https://api.whatsapp.com/send/?phone=56930139035&text&type=phone_number&app_absent=0", "_blank");
                                handleClose();
                            }}
                        >
                            <FaWhatsapp className="icon--white" style={{ fontSize: 28, fill: '#5cc858' }} />
                        </IconButton>


                    </DialogContentText>

                </DialogContent>

            </Dialog>

            <AddSign
                key={'flkafkje'}
                openSigModal={openSigModal}
                setOpenSigModal={(e) => setOpenSigModal(e)}
                signatory={(e) => addFirmante(e)}
                signatories={list}
            />


            <div className={styles.actionButtoms}>

                <Btn
                    buttonClass="buttom-cancel-sign"
                    variant="contained"
                    text="cancelar"
                    onClick={() => resetFile()}
                    sx={{
                        backgroundColor: "secondary.light",
                        height: '39px'
                    }}
                />

                <Animated
                    className="buttom-finish-sign"
                    animationIn="swing"
                    animationOut="bounceOutLeft"
                    isVisible={Object.keys(firms).length !== 0 ? true : false}
                >

                    <Btn
                        variant="contained"
                        text="guardar firmas"
                        // buttonClass="buttom-finish-sign"
                        onClick={() => verifySigners()}
                        sx={{
                            backgroundColor: "primary.light",
                            marginTop: '10px',
                            height: '39px'
                        }}
                    />

                </Animated>

            </div>

            <div id="my_pdf_viewer" className={styles.pdfViewer} ref={refPdfViewer}>

                <div id="canvas_container" className={styles.canvasContainer} onClick={(e) => openModal(e)}>

                    {Object.keys(firms).map((item) => {

                        return firms[item]['page'] === currentPage && (<DraggableBox
                            key={item}
                            positions={(element) => setFirms((itemList) => Object.assign({ ...itemList }, { [item]: element }))}
                            deleteBox={() => boxDelete(item)}
                            refPdfViewer={refPdfViewer}
                            signatory={firms[item]}
                        />)

                    })}

                    <canvas id="pdf_renderer" ref={refCanvas}></canvas>

                </div>

                <div id="navigation_controls" className={styles.navigationControls}>

                    <Btn
                        buttonClass="buttom-prev"
                        disabled={currentPage === 1}
                        id="go_next"
                        variant="contained"
                        text="Página anterior"
                        onClick={() => goPrevious()}
                        size="small"
                        sx={{
                            backgroundColor: "primary.light"
                        }}
                    />

                    <Controller
                        name="currentPage"
                        control={control}
                        render={({
                            field: { onChange, onBlur, value, ref },
                            fieldState,
                        }) => {
                            return (
                                <TextField
                                    id="input-control"
                                    inputProps={{ min: 1, max: ![null, undefined, ''].includes(docPdf) ? docPdf._pdfInfo.numPages : currentPage }}
                                    type="number"
                                    // ref={ref}
                                    onBlur={onBlur}
                                    variant={'standard'}
                                    ref={refInputControl}
                                    onChange={onChange}
                                    className={styles.inputControl}
                                    value={value}
                                    focused={
                                        fieldState.isTouched ||
                                        fieldState.isDirty
                                    }
                                    fullWidth
                                />
                            );
                        }}
                    />

                    <Btn
                        buttonClass="buttom-next"
                        disabled={[null, undefined, ''].includes(docPdf) ? false : (currentPage === docPdf._pdfInfo.numPages)}
                        id="go_next"
                        variant="contained"
                        text="Página siguiente"
                        size="small"
                        onClick={() => goNext()}
                        sx={{
                            backgroundColor: "primary.light"
                        }}
                    />

                </div>

            </div>

        </div>
    );
}

export default PositionFirm;