import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { BiRightArrowAlt, BiCheck } from "react-icons/bi";

import { motion } from "framer-motion";

import Button from "@material-ui/core/Button";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { errorStepFormSolicitud, saveInfoFormSolicitud } from "../../../../store/formSolicitud/formSolicitud";
import { FormularioSolicitudStyles } from "../../index.css";
import Text from "../../../heading/Text";
import { ReactComponent as FileUploadIcon } from "../../../../assets/icons/fileupload.svg";
import Spinner from "../../../spinners/Spinner";
import { createContract } from "../../../../store/contract/contractDetail";
import { contractStoreFilesRequest, mergeDocs, sendContractEmail } from "../../../../utils/api";


const StepEight = ({ watch, trigger, setValue, getValues }) => {
    const styles = FormularioSolicitudStyles();
    const {
        session: {
            login: {
                user
            }
        },
        contract: {
            contractCreator: {
                contract
            }
        },
        formSolicitud: {
            document,
            sOwnerType,
            sPaymentStatus,
            iPaymentService,
            iContractTypeFeeID,
            iSignedCount
        }
    } = useSelector((state) => state);
    const dispatch = useDispatch();
    const {
        register,
        formState: { errors },
    } = useForm();
    const refFirstLoad = React.useRef({});
    const [errorInput, setErrorInput] = React.useState(true);
    // eslint-disable-next-line
    const [docNames, setDocNames] = React.useState([]);
    const [runSpinner, setRunSpinner] = React.useState(false);
    const [filePdf, setFilePdf] = React.useState('');
    const [upLoadFilePdf, setUpLoadFilePdf] = React.useState(false);

    const watchDocument = watch("document");
    const getSigners = getValues('signers');

    const fileForm = {
        flex: "1"
    };

    const titleHintStyle = {
        color: "quinary.xdark",
        fontWeight: "500",
        fontSize: "1.1rem",
        marginTop: "21px",
    };

    const fileNameStyles = {
        color: "black.main",
        fontWeight: "500"
    };

    const base64toBlob = (base64Data) => {
        const sliceSize = 1024;
        const byteCharacters = atob(base64Data);
        const bytesLength = byteCharacters.length;
        const slicesCount = Math.ceil(bytesLength / sliceSize);
        const byteArrays = new Array(slicesCount);

        for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
            const begin = sliceIndex * sliceSize;
            const end = Math.min(begin + sliceSize, bytesLength);

            const bytes = new Array(end - begin);
            for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[i] = byteCharacters[offset].charCodeAt(0);
            }
            byteArrays[sliceIndex] = new Uint8Array(bytes);
        }
        return new Blob(byteArrays, { type: "application/pdf" });
    }

    const openBase64NewTab = (base64Pdf) => {
        var blob = base64toBlob(base64Pdf);
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, "pdfBase64.pdf");
        } else {
            const blobUrl = URL.createObjectURL(blob);
            window.open(blobUrl);
        }
    }

    const fileToBase64 = (file, cb) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function () {
            cb(null, reader.result)
        }
        reader.onerror = function (error) {
            cb(error, null)
        }
    }

    const displayDocNames = () => {
        if (docNames.length > 0) {
            return docNames.map((name, index) => {
                return (
                    <li key={index} id={name} className="file-name__container">
                        <Text sx={fileNameStyles} align="left">{`${index + 1
                            }. ${name}`}</Text>
                        <BiCheck
                            className="icon--md icon--black"
                        />
                    </li>
                );
            });
        }
    };

    const onInput = async ({ target }) => {

        setErrorInput(true);
        setRunSpinner(true);

        const { token } = user;

        const formData = new FormData();
        const contractID = contract.detail.sContractID;
        const randomID = Math.floor(Math.random() * (999999999 - 0));

        formData.append("fileUp", target.files[0], `${contractID}_${randomID}.pdf`);

        try {

            if (target.files < 1 || !target.validity.valid) {
                setErrorInput(undefined);
                setRunSpinner(false);
                return
            }

            fileToBase64(target.files[0], (err, result) => {
                if (result) {

                    const docPdfBase64 = result.replace('data:application/pdf;base64,', '');

                    setFilePdf(docPdfBase64);
                    dispatch(saveInfoFormSolicitud({ indexe: 'document', value: docPdfBase64 }));
                }
            })

            await contractStoreFilesRequest(formData, token);

            await mergeDocs(contract.detail.sContractID, token);

            await sendContractEmail(contract.detail.sContractID, token);

            setUpLoadFilePdf(true);
            setErrorInput(undefined);
            setRunSpinner(false);

        } catch (err) {
            setErrorInput(undefined);
            setRunSpinner(false);
            console.log('err', err);
        }
    };

    React.useEffect(() => {

        const { token } = user;

        const firmantes = getSigners.filter((item) => !['', null, undefined].includes(item['nombres']));

        const countSigned = firmantes.filter((v) => v.pay).length;

        for (let index = 0; index < firmantes.length; index++) {
            const element = firmantes[index];
            if (element['pay']) element['portion'] = countSigned === 1 ? '100' : '50';

            Object.assign(element, { full_name: element['nombres'] });
        }

        const body = {
            callback: "",
            iAgreementId: 0,
            iPriceId: 0,
            sOwnerType,
            sPaymentStatus,
            iPaymentService,
            iContractTypeFeeID,
            iSignedCount,
            signers: firmantes
        }

        dispatch(createContract(body, token));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getSigners]);

    React.useEffect(() => {
        if (![undefined].includes(errorInput)) {
            dispatch(errorStepFormSolicitud(true));
        } else {
            dispatch(errorStepFormSolicitud(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorInput]);

    React.useEffect(() => {
        setValue("document", document);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [document]);

    refFirstLoad.current = false;

    React.useEffect(() => {

        trigger();

        if (!['', null, undefined].includes(watchDocument) && watchDocument !== document) dispatch(saveInfoFormSolicitud({ indexe: 'document', value: watchDocument }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchDocument]);

    return (
        <div className={styles.stepBox}>

            <motion.div
                initial={{ y: "50vh" }}
                animate={{ y: 0 }}
                transition={{ stiffness: 150 }}
            >

                <Grid container xs={12}>

                    <Grid item xs={1} className={styles.boxIndetify}>

                        8<BiRightArrowAlt />

                    </Grid>

                    <Grid item xs={11}>

                        <Typography variant="h5" className={styles.cssTexto1} gutterBottom>
                            ¿Cuáles son los documentos necesarios para la firma (<em><strong>IMPORTANTE</strong>: todos juntos en 1 sólo archivo Word o PDF. Puedes usar <a href="https://firmavirtual.web.app/" rel="noopener noreferrer" target={'_blank'}>esta herramienta</a> <a href="https://firmavirtual.web.app/" rel="noopener noreferrer" target={'_blank'}>(https://firmavirtual.web.app/)</a>,
                                para lograrlo (<strong>orden</strong>: 1. documento a firmar 2. anexos si aplica 3. cédula de identidad de ambos lados o pasaporte de todos los firmantes 4. E-RUT de personas jurídicas si aplica)</em>. ? *
                        </Typography>

                        <Typography variant="h5" className={styles.cssTexto2} gutterBottom>
                            <strong>Requerimiento Mínimo</strong> (revisa video): <strong>TODOS juntos en 1 sólo archivo</strong> Word o PDF. Subir al menos el documento a firmar y la cédula de identidad de ambos lados de los firmantes.
                            Para personas jurídicas, favor adjuntar adicionalmente E-RUT de cada firmante <em>(<strong>orden</strong>: 1. documento a firmar 2.
                                anexos si aplica 3.cédula de identidad de ambos lados o pasaporte de todos los firmantes 4. E-RUT de personas jurídicas si aplica)</em>.
                        </Typography>

                        <br />
                        <br />

                        <Grid xs={12} display={'flex'} item justifyContent={'center'}>

                            <iframe frameborder="0" allowfullscreen="1" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" title="Paso a Paso: Como preparar un documento para FirmaVirtual" width="640" height="360" src="https://www.youtube.com/embed/YLwzvbcN-20?autoplay=0&amp;disablekb=1&amp;modestbranding=1&amp;origin=https%3A%2F%2Fform.typeform.com&amp;rel=0&amp;loop=1&amp;widget_referrer=https%3A%2F%2Ffirmavirtual.legal%2F&amp;enablejsapi=1&amp;widgetid=1" id="widget2"></iframe>

                        </Grid>

                        <br />
                        <br />

                        <Box sx={fileForm}>
                            <form>
                                <div className="input-container">
                                    <div className="input-file__container">
                                        <input
                                            disabled={upLoadFilePdf}
                                            type="file"
                                            multiple={false}
                                            onInput={onInput}
                                            accept=".pdf"
                                            className={
                                                errors.proceduredocs &&
                                                "input-container--error"
                                            }
                                            {...register("proceduredocs", {
                                                required: true,
                                            })}
                                        />
                                        <div className="input-file-hint__container">
                                            <FileUploadIcon />
                                            <Text sx={titleHintStyle} align="center">
                                                Haz click o arrastra a esta área para
                                                subir tu PDF
                                            </Text>
                                        </div>
                                        {runSpinner && <Spinner loading={true} type="bar" opacity={true} />}
                                    </div>
                                    <ul className="file-list__container">
                                        {displayDocNames()}
                                    </ul>
                                    {errors.proceduredocs?.type === "required" && (
                                        <span className="input--error">
                                            Debes adjuntar los documentos
                                            correspondientes
                                        </span>
                                    )}
                                </div>
                            </form>
                        </Box>

                        {(!runSpinner && ![null, undefined, ''].includes(filePdf)) && (<Grid item xs={12} display={'flex'} justifyContent={'center'}>

                            <Button
                                variant="contained"
                                size="large"
                                onClick={() => openBase64NewTab(filePdf)}
                            >
                                Ver Documento cargado
                            </Button>

                        </Grid>)}

                    </Grid>

                </Grid>

            </motion.div>

        </div >
    );
}

export default StepEight;