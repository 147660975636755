import React, { useState, useEffect, useContext } from "react";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import CustomToolbar from "./molecules/CustomToolbar";
import { styles } from "../../../helpers";
import { gridColumns, localizedTextsMap } from "./constants";
import { useSelector } from "react-redux";
import { bodyTextStyles } from "../../../utils/validations";
import Spinner from "../../spinners/Spinner";
import PopUp from "../../floats/PopUp";
import { Btn, Text } from "../..";
import { getScheduleList } from "./services";
import dayjs from "dayjs";
import { Context } from "../../../context/utilsContext";

const ManageSchedule = () => {
    const {
        session: {
            login: { user },
        },
        users: {
            status: requestStatusUsersList,
            message: resquestUserListMsg
        },
        contract: {
            tramites: {
                status,
                message
            }
        }
    } = useSelector((state) => state);
    const { loading, setLoading, reload } = useContext(Context);

    const [key, setKey] = useState("");
    const [textSpinner, setTextSpinner] = useState("");
    const [dataGrid, setDataGrid] = useState([]);
    const [showModal, setShowModal] = useState(false);
    // eslint-disable-next-line
    const [modalIcon, setModalIcon] = useState();
    const [modalTitle, setModalTitle] = useState("");
    const [modalMsg, setModalMsg] = useState("");
    const [modalButtonTitle, setModalButtonTitle] = useState("");


    const getList = async () => {
        try {

            setTextSpinner("Cargando listado de Agendamiento...");

            setLoading(true);

            const { token } = user;

            const response = await getScheduleList(dayjs().format('YYYY-MM'), token);

            if (response['status'] === 'Success') {

                setDataGrid(response['data']);

            } else {
                setModalTitle("¡Ups!, Algo ha pasado");
                setModalMsg('Consulte con un administrador del sistema');
                setModalButtonTitle("Cerrar");
                setShowModal(true);
            }

            setLoading(false);

        } catch (error) {
            console.log(error);
            setLoading(false);

            setModalTitle("¡Ups!, Algo ha pasado");
            setModalMsg('Consulte con un administrador del sistema');
            setModalButtonTitle("Cerrar");
            setShowModal(true);
        }
    }

    useEffect(() => {

        if (![null, undefined, 'success', ''].includes(status)) {
            setModalTitle("¡Ups!, Algo ha pasado");
            setModalMsg(message);
            setModalButtonTitle("Cerrar");
            setShowModal(true);
        } else if (![null, undefined, 'success', ''].includes(requestStatusUsersList)) {
            setModalTitle("¡Ups!, Algo ha pasado");
            setModalMsg(resquestUserListMsg);
            setModalButtonTitle("Cerrar");
            setShowModal(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, requestStatusUsersList]);


    useEffect(() => {
        getList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (reload === true) getList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload]);

    const genKey = () =>
        setKey(
            Math.random()
                .toString()
                .split(".")
                .reduce((a, b) => a + b)
        );

    const handleModal = () => {
        setModalTitle("");
        setModalMsg("");
        setModalButtonTitle("");
        setShowModal(false);
    };

    const renderList = React.useCallback(() => {
        return (
            <div
                className="container--relative contracts-status-list__container"
                style={{ height: "auto", width: "100%" }}
            >
                <div style={{ display: "flex", height: "100%" }}>
                    <div style={{ flexGrow: 1 }}>
                        <DataGrid
                            rows={dataGrid}
                            columns={gridColumns}
                            pageSize={8}
                            rowsPerPageOptions={[8]}
                            autoHeight={true}
                            autoPageSize={true}
                            getRowId={(row) => `${row.sContractID}-${row.sContractTypeName}`}
                            getCellClassName={null}
                            rowHeight={56}
                            loading={loading}
                            sx={[
                                styles.gridContainerStyles,
                                {
                                    [`& .${gridClasses.cell}`]: {
                                        py: 2,
                                        fontSize: "8px",
                                    },
                                },
                            ]}
                            localeText={localizedTextsMap}
                            disableSelectionOnClick={true}
                            components={{
                                Toolbar: () => (
                                    <CustomToolbar
                                        genKey={genKey}
                                        updateData={() => {
                                            setTextSpinner(
                                                "Actualizando listado de agendamiento..."
                                            );
                                            getList();
                                        }}
                                    />
                                ),
                                NoRowsOverlay: () => (
                                    <div className="data-grid__no-rows">
                                        No hay registros
                                    </div>
                                ),
                            }}
                            sortingOrder={["desc", "asc"]}
                            initialState={{
                                sorting: {
                                    sortModel: [
                                        { field: "contractAutoId", sort: "desc" },
                                    ],
                                },
                            }}
                        />
                    </div>
                </div>
            </div>
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataGrid, loading, gridColumns]);

    return (
        <div className="content-detail__container" key={key}>
            {loading && (
                <Spinner
                    loading={true}
                    type="bar"
                    text={textSpinner}
                    opacity={true}
                />
            )}

            <PopUp
                open={showModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                title={modalTitle}
                icon={modalIcon}
                onBackdropClick={() => setShowModal(!showModal)}
            >
                <Text
                    sx={bodyTextStyles}
                    align="center"
                    variant="body1"
                    paragraph
                >
                    {modalMsg}
                </Text>
                <Btn
                    variant="contained"
                    text={modalButtonTitle}
                    size="lg"
                    color="primary"
                    type="submit"
                    buttonClass="button-submit"
                    onClick={handleModal}
                    fullWidth
                />
            </PopUp>

            {renderList()}
        </div>
    );
};

export default ManageSchedule;
