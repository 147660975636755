import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const DetailSignatoriesStyles = makeStyles((theme) => {

    return createStyles({

        statusHeader: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        cardHeader: {
            padding: '9px !important'
        },
        mainContainer: {
            display: 'flex',
            background: 'transparent',
            marginBottom: '30px'
        },
        cardContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'initial !important',
            width: '354px',
            minHeight: '285.6px',
            background: theme.palette.white.main,
            padding: '15px 20px !important'
        },
        contentActionButtoms: {
            height: '389.19px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            background: 'transparent',
            marginLeft: '20px',
            '& > div': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '52.94px',
                height: '50.82px',
                borderRadius: '7px',
                cursor: 'pointer',
                background: theme.palette.white.main
            }
        },
        statusPay: {
            display: 'flex',
            alignItems: 'center',
            marginTop: '5px',
            '& > svg': {
                marginRight: '3px'
            }
        },
        expandMoreContent: {
            display: 'flex',
            flexDirection: 'column',
            alignSelf: 'stretch',
            '& > div': {
                display: 'flex',
                justifyContent: 'center'
            }
        }
    })

});