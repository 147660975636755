import React from 'react';
import { useSelector } from 'react-redux';

import { Box } from '@mui/material';
import { BiDownload } from "react-icons/bi";

import { downloadCompiledContractDoc } from '../../../../../utils/api';


const RenderActions = ({ id }) => {
    const {
        session: {
            login: {
                user
            }
        }
    } = useSelector(state => state);

    const downloadContract = async (contract) => {

        try {

            const { userId, token } = user;

            if (![null, undefined].includes(userId)) {
                
                await downloadCompiledContractDoc(
                    contract,
                    token
                );

            }
            
        } catch (error) {
            console.log(error);
        }
    };

    return (

        <Box sx={{
            position: "relative",
            display: "flex",
            alignContent: 'center'
        }} className="flex--1 flex--center">

            <Box sx={{padding: "6px"}} onClick={()=>downloadContract(id)}>
                <BiDownload className="icon--xs icon-pointer icon--blue-light" />
            </Box>

        </Box>
    );

};

export default RenderActions;