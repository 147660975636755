import React from 'react';

import { Box } from '@mui/material';

import { FaUserAlt } from 'react-icons/fa';

const RenderSigners = ({ item }) => {

    return (

        <Box sx={{
            position: "relative",
            display: "flex",
            alignContent: 'center',
            padding: "6px",
            whiteSpace: 'normal'
        }} className="flex--1 flex--center">

            <FaUserAlt className="icon--xxs icon--blue-light" />
            <span className="whitespace--left-md">{item}</span>

        </Box>
    );
};

export default RenderSigners;