import React from 'react';
import { useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Grid';

import { icons, manageProcedures, to } from '../../../utils/enums';
import { normalizeString } from "../../../utils/transform";
import CardOption from './molecules/cardOption';

const HomeManagementProcedures = () => {
    const navigate = useNavigate();

    return (
        <div className="content-detail__container">

            <div className="detail__container">

                <Grid container spacing={2}>

                    <Grid item xs={12} sm={6} lg={4}>

                        <CardOption
                            title={manageProcedures.PROCEDURES_CREATION}
                            id={normalizeString(manageProcedures.PROCEDURES_CREATION)}
                            subtitle="Ir al tramite"
                            icon={icons.NEW_PROCEDURES_ICON}
                            onClick={() => navigate(to.CONTRACT_CREATION)}
                        />

                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>

                        <CardOption
                            title={manageProcedures.MANAGE_REVIEWS}
                            id={normalizeString(manageProcedures.MANAGE_REVIEWS)}
                            subtitle="Ir al tramite"
                            icon={icons.REVIEW_PROCEDURES_ICON}
                            onClick={() => navigate(to.CONTRACT_STATUS)}
                        />

                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>

                        <CardOption
                            title={manageProcedures.MANAGE_PAYMENTS}
                            id={normalizeString(manageProcedures.MANAGE_PAYMENTS)}
                            subtitle="Ir al tramite"
                            icon={icons.PAYMENT_PROCEDURES_ICON}
                            onClick={() => navigate(to.CONTRACT_APROVED)}
                        />

                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>

                        <CardOption
                            title={manageProcedures.MANAGE_SIGNETURES}
                            id={normalizeString(manageProcedures.MANAGE_SIGNETURES)}
                            subtitle="Ir al tramite"
                            icon={icons.SIGNATURE_PROCEDURES_ICON}
                            onClick={() => navigate(to.CONTRACT_SIGNATURE_PENDING)}
                        />

                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>

                        <CardOption
                            title={manageProcedures.MANAGE_NOTARIES}
                            id={normalizeString(manageProcedures.MANAGE_NOTARIES)}
                            subtitle="Ir al tramite"
                            icon={icons.NOTARIES_PROCEDURES_ICON}
                            onClick={() => navigate(to.MANAGE_NOTARY)}
                        />

                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>

                        <CardOption
                            title={manageProcedures.MANAGE_DELIVERY}
                            id={normalizeString(manageProcedures.MANAGE_DELIVERY)}
                            subtitle="Ir al tramite"
                            icon={icons.DELIVERY_PROCEDURES_ICON}
                            onClick={() => navigate(to.MANAGE_DELIVERY)}
                        />

                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>

                        <CardOption
                            title={manageProcedures.MANAGE_DOCUMENTATION}
                            id={normalizeString(manageProcedures.MANAGE_DOCUMENTATION)}
                            subtitle="Ir al tramite"
                            icon={icons.DOCUMENTS_PROCEDURES_ICON}
                            onClick={() => navigate(to.DOCUMENT_MANAGE)}
                        />

                    </Grid>

                </Grid>

            </div>

        </div>
    );
};

export default HomeManagementProcedures;