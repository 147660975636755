import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';

import { ReactComponent as FileIcon } from "../../img/file-icon.svg";
import { ReactComponent as DownloadIcon } from "../../img/download-icon.svg";
import { ContractDetailReviewStyles } from "../../index.css";
import { verifiVariable } from "../../../../../utils/enums";
import { downloadCompiledContractDoc } from "../../../../../utils/api";
import ViewModalDocs from "../viewModalDocs";
import ChangeModalDocs from "../changeModalDocs";
import AddSignDoc from "../addSignDoc";


const InfoDocs = ({ loading, textLoading, restUpDoc }) => {
    const styles = ContractDetailReviewStyles();
    const {
        contract: {
            contractCreator: {
                contractCreatedDetails
            }
        },
        session: {
            login: {
                user
            }
        }
    } = useSelector((state) => state);
    const { state } = useLocation();
    const [detailContract, setDetailContract] = useState(undefined);
    const [mergedDoc, setMergedDoc] = useState(undefined);


    const downloadContract = async (open) => {
        try {
            const { token } = user;
            loading(true);
            textLoading('Descargando documento');

            const response = await downloadCompiledContractDoc(
                state,
                token,
                open
            );

            response && loading(false);

        } catch (error) {
            loading(false);
            console.log(error);
        }
    };


    useEffect(() => {
        if (!verifiVariable(contractCreatedDetails)) setDetailContract(contractCreatedDetails);
    }, [contractCreatedDetails]);

    return (
        <div className={styles.infoDocsContainer}>

            <AddSignDoc
                docToSign={mergedDoc}
                loading={(e) => loading(e)}
                textLoading={(e) => textLoading(e)}
                restUpDoc={() => restUpDoc()}
            />

            {(!verifiVariable(detailContract) && detailContract['contrato'][0]['withDocument'] === 1) && (<div className={styles.infoDocs}>

                <div className={styles.userTitleInfo}>

                    <Typography variant="subtitle1" gutterBottom>
                        Documento a firmar
                    </Typography>

                </div>

                {!verifiVariable(detailContract) && (
                    <div className={`${styles.userInfo} ${styles.fileIcon}`}>
                        <FileIcon />
                        <Typography variant="subtitle1" gutterBottom fontSize='0.9rem'>
                            {`fvl_${detailContract['contrato'][0]['sContractID']}_document.pdf`}
                        </Typography>
                    </div>
                )}

            </div>)}

            <div className={styles.optionDocs}>

                <ViewModalDocs loading={(e) => loading(e)} textLoading={(e) => textLoading(e)} />

                {(!verifiVariable(detailContract) && ['CREATED', 'REVIEW'].includes(detailContract['contrato'][0]['sStatus'])) && (<div>
                    <ChangeModalDocs
                        loading={(e) => loading(e)}
                        textLoading={(e) => textLoading(e)}
                        resultMergeDocs={(e) => setMergedDoc(e)}
                    />
                </div>)}

                {(!verifiVariable(detailContract) && detailContract['contrato'][0]['withDocument'] === 1) && (
                    <Tooltip
                        title={
                            <Typography variant="subtitle1" color={['white.main']} gutterBottom>
                                Descargar contrato
                            </Typography>
                        }
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                        arrow
                    >

                        <div>
                            <DownloadIcon onClick={() => downloadContract(false)} />
                        </div>

                    </Tooltip>
                )}

            </div>

        </div>
    );

};

export default InfoDocs;