const stepsTramit3_2 = [
    {
        element: ".tool",
        intro: "Puede generar un solo documento PDF con los que ya posee usando nuestra herramienta"
    },
    {
        element: ".option-load",
        intro: "Si ya cuenta con el docuemnto debidamente creado entonces puede continuar pulsando aqui"
    }
];

const stepsTramit3_3 = [
    {
        element: ".input-load-doc",
        intro: "Arrastre o cargue su documento a tramitar aqui"
    },
    {
        element: ".finish",
        intro: "Tambien puede finalizar el proceso y durante la revision puede subir el documento"
    }
];

const stepsTramit3_4 = [
    {
        intro: "Haga clic en el documento para empezar a asignar la firma, en donde quiere que firmen los participantes de este trámite."
    },
    {
        element: ".buttom-cancel-sign",
        intro: "Puede cancelar el proceso de asignación de firma en cualquier momento y seleccionar otro documento."
    },
    {
        element: ".buttom-prev",
        intro: "Navegar por las páginas anteriores."
    },
    {
        element: "#input-control",
        intro: "Colocar el número de página al cual desee ir."
    },
    {
        element: ".buttom-next",
        intro: "Navegar por las siguientes páginas."
    }
];

const stepsTramit3_5 = [
    {
        element: ".buttom-finish-sign",
        intro: "Puede terminar el proceso de asignacion de firma una vez haber terminado las asignaciones"
    }
];

export { stepsTramit3_2, stepsTramit3_3, stepsTramit3_4, stepsTramit3_5 };