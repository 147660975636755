import { useEffect, useState } from 'react';

const StatusHook = (status) => {

    const [data, setData] = useState(undefined);

    useEffect(() => {

        switch (status) {
            case "CREATED":
                return setData({ class: "status__container created", text: "Contrato creado" });
            case "REVIEW":
                return setData({ class: "status__container pending", text: "Contrato en revisión" });
            case "REJECTED":
                return setData({ class: "status__container rejected", text: "Contratos(s) rechazado(s)" });
            case "APPROVED":
                return setData({ class: "status__container approved", text: "Contrato(s) aprobado(s)" });
            case "IN_NOTARY":
                return setData({ class: "status__container pending", text: "En Notaria" });
            case "FINISHED":
                return setData({ class: "status__container approved", text: "Entregado" });
            default:
                return setData({ class: "status__container pending", text: "Pendiente de firma" });
        }

    }, [status]);

    return data;

};

export default StatusHook;