import { Fragment, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";

import { styled } from '@mui/material/styles';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Snackbar from '@mui/material/Snackbar';

import * as PdfjsLib from 'pdfjs-dist';
// eslint-disable-next-line
import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib';

import { ReactComponent as MissedFirms } from "../../img/aun-no-se-han-agregado-firmantes.svg";
import { ReactComponent as SusscesChange } from "../../img/sussces-change.svg";
import { ReactComponent as ErrorRequest } from "../../img/error-request.svg";

import { ContractDetailReviewStyles } from "../../index.css";
import { randomRgba, verifiVariable } from "../../../../../utils/enums";
import DraggableBox from "../draglableBox";
import { contractStoreFilesRequest, mergeDocs, sendContractPriotity } from "../../../../../utils/api";


const CustomSnackBar = styled(Snackbar)(({ theme }) => ({
    '& .MuiSnackbarContent-root': {
        backgroundColor: 'transparent',
        boxShadow: 'none'
    }
}));

const CustomTextControls = styled(Typography)((props) => ({
    color: props.theme.palette.black.main,
    fontWeight: 100,
    marginLeft: '25px',
    marginRight: '25px'
}));

const ControlsCustomButtom = styled(Button)((props) => ({
    borderRadius: '7px',
    background: props.theme.palette.primary.light,
    marginTop: '11px',
    marginBottom: '20px',
    padding: '14px 22px',
    '&:hover': {
        background: props.theme.palette.primary.light
    },
    '&:disabled': {
        background: props.theme.palette.gray.dark,
        color: props.theme.palette.white.main
    },
    fontFamily: [
        '"PoppinsBold"'
    ].join(',')
}));

const CancelActionCustomButtom = styled(Button)((props) => ({
    borderRadius: '7px',
    fontStyle: 'normal',
    boxShadow: 'none',
    fontWeight: 'bolder',
    textTransform: 'none',
    color: props.theme.palette.secondary.main,
    fontSize: 16,
    padding: '11px 32px',
    border: `1px solid ${props.theme.palette.secondary.light}`,
    '&:hover': {
        border: `1px solid ${props.theme.palette.secondary.light}`
    },
    fontFamily: [
        '"PoppinsBold"'
    ].join(',')
}));

const CustomActionButtom = styled(Button)((props) => ({
    borderRadius: '7px',
    fontStyle: 'normal',
    boxShadow: 'none',
    fontWeight: 'bolder',
    textTransform: 'none',
    fontSize: 16,
    padding: '11px 32px',
    background: props.theme.palette.primary.light,
    border: `1px solid ${props.theme.palette.primary.light}`,
    '&:hover': {
        border: `1px solid ${props.theme.palette.primary.light}`,
        background: props.theme.palette.primary.light
    },
    fontFamily: [
        '"PoppinsBold"'
    ].join(',')
}));

const style = {
    display: 'flex',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    bgcolor: 'transparent !important',
    boxShadow: '0px 4px 11px 5px rgba(0, 79, 159, 0.04)',
    borderRadius: '7px',
    p: 4
};


const buildId = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
};

const AddSignDoc = ({ loading, textLoading, docToSign, restUpDoc }) => {
    const styles = ContractDetailReviewStyles();
    const {
        contract: {
            contractCreator: {
                contractCreatedDetails
            }
        },
        session: {
            login: {
                user
            }
        }
    } = useSelector((state) => state);
    // eslint-disable-next-line
    const { state } = useLocation();
    const [open, setOpen] = useState(false);
    const refPdfViewer = useRef();
    const refCanvas = useRef();
    const refInputControl = useRef();
    const [docPdf, setDocPdf] = useState(null);
    // eslint-disable-next-line
    const [fileBase64, setFileBase64] = useState(undefined);
    // eslint-disable-next-line
    const [zoom, setZoom] = useState(1);
    const [detailContract, setDetailContract] = useState(undefined);
    const [firms, setFirms] = useState({});
    // eslint-disable-next-line
    const [layerX, setLayerX] = useState(216.5);
    // eslint-disable-next-line
    const [layerY, setLayerY] = useState(517);
    const [participant, setParticipant] = useState([]);
    const [snackbarOpen, setSnackbar] = useState(false);
    const [imgStatus, setImgStatus] = useState(<SusscesChange />);

    const { watch, setValue, control, handleSubmit, trigger, reset } = useForm({
        defaultValues: {
            currentPage: 1,
            addParticipan: ''
        }
    });

    let currentPage = watch('currentPage');

    const handleClickOpenSnack = () => {
        setSnackbar(true);
    }

    const handleCloseSnack = () => {
        setSnackbar(false);
    }

    // eslint-disable-next-line
    const pdfForSignrequest = async () => {
        try {

            if ([null, undefined, ''].includes(fileBase64)) return;

            const dataFirms = { ...firms };

            const pdfDoc = await PDFDocument.load(fileBase64, {ignoreEncryption: true});

            // Embed the Helvetica font
            const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

            // Get the first page of the document
            const pages = pdfDoc.getPages();

            Object.keys(dataFirms).forEach((item) => {

                const signedPage = pages[(dataFirms[item]['page']) - 1];

                // Draw a string of text diagonally across the first page
                signedPage.drawText(dataFirms[item]['text'], Object.assign({ ...dataFirms[item] }, { font: helveticaFont }));

            });

            const pdfBytes = await pdfDoc.save();

            const file = new File([pdfBytes], 'tramite', { type: 'application/pdf' });

            return file;

        } catch (error) {
            console.error(error);
        }
    }

    const onSubmit = (data) => {
        trigger();

        const parseSigner = JSON.parse(data['addParticipan']);

        setFirms((itemList) => Object.assign({
            ...itemList,
            [buildId(4)]: {
                numbSigned: parseSigner['sign'] + 1,
                text: `[[s|${parseSigner['sign']}]]`,
                textTootip: parseSigner['full_name'],
                rut: parseSigner['RUT'],
                x: layerX,
                y: layerY,
                size: 20,
                textColor: parseSigner['textColor'],
                page: currentPage,
                color: rgb(1, 1, 1),
                rotate: degrees(0)
            }
        }));

    }

    const boxDelete = (id) => {

        const objectSignet = { ...firms };

        delete objectSignet[id];

        setFirms(objectSignet);
    }

    const uploadDocs = async () => {

        try {

            loading(true);
            textLoading('Subiendo documento');

            const randomID = Math.floor(Math.random() * (999999999 - 0));
            const { token } = user;
            const arrayFirms = Object.keys(firms);
            const formData = new FormData();

            if (arrayFirms.length === 0) {
                setImgStatus(<MissedFirms />);
                handleClickOpenSnack();
                loading(false);
                return;
            }

            const fileSigned = await pdfForSignrequest();

            formData.append(
                "fileUp",
                fileSigned,
                `${detailContract['contrato'][0]['sContractID']}_${randomID}.pdf`
            );

            await contractStoreFilesRequest(formData, token);

            await mergeDocs(detailContract['contrato'][0]['sContractID'], token);

            await sendContractPriotity(detailContract['contrato'][0]['sContractID'], token);

            setImgStatus(<SusscesChange />);
            handleClose();
            loading(false);
            restUpDoc();

        } catch (error) {
            setImgStatus(<ErrorRequest />);
            loading(false);
            console.log(error);
        }

    };

    const goPrevious = () => {
        if (docPdf === null || currentPage === 1) return;
        setValue('currentPage', (currentPage) -= 1);
    }

    const goNext = () => {
        if (docPdf == null || currentPage >= docPdf._pdfInfo.numPages) return;
        setValue('currentPage', (currentPage) += 1);
    }

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setFirms({});
        reset();
    };

    useEffect(() => {

        (async () => {

            if (!verifiVariable(docToSign)) {
                handleOpen();
                setFileBase64('data:application/pdf;base64,' + docToSign['file']);

                const loadPdfDocumentTask = PdfjsLib.getDocument('data:application/pdf;base64,' + docToSign['file']);

                const pdf = await loadPdfDocumentTask.promise;

                setDocPdf(pdf);

            }

        })();

    }, [docToSign]);


    useEffect(() => {

        (async () => {

            const parsetCurrentPage = parseInt(currentPage);

            if ([null, undefined, ''].includes(docPdf) || [0, null, undefined].includes(parsetCurrentPage) || parsetCurrentPage > docPdf._pdfInfo.numPages) return;

            let page = await docPdf.getPage(parsetCurrentPage);

            let canvas = refCanvas.current;
            let ctx = canvas.getContext('2d');
            let viewport = page.getViewport({ scale: zoom });

            canvas.width = viewport.width;
            canvas.height = viewport.height;
            page.render({
                canvasContext: ctx,
                viewport: viewport
            });

            viewport = page.getViewport({ scale: zoom });

            window['jQuery'](refPdfViewer.current).width(viewport.width).height(viewport.height + 28);

            window['jQuery'](refInputControl.current).width(viewport.width - (151.5 * 2));

        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [docPdf, currentPage]);

    useEffect(() => {
        if (!verifiVariable(contractCreatedDetails)) {
            setDetailContract(contractCreatedDetails);
            setParticipant([...contractCreatedDetails['firmantes']].filter((item) => item["iRole"] === 0).map((item, index) => { return { ...item, sign: index, textColor: randomRgba() } }));
        };
    }, [contractCreatedDetails]);

    return (

        <Fragment>

            <CustomSnackBar
                style={{
                    '& .MuiSnackbarContent-root': {
                        all: 'initial',
                        padding: '10px'
                    }
                }}
                anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
                open={snackbarOpen}
                onClose={handleCloseSnack}
                message={imgStatus}
            />

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500
                    }
                }}
            >
                <Fade in={open}>

                    <Box sx={style}>

                        <div className={styles.mainContainerPdf}>

                            <div>

                                <Typography id="transition-modal-title" variant="h6" color={['black.main']} textAlign={'center'}>
                                    Haz click en el documento para agregar los firmantes
                                </Typography>

                            </div>

                            <div id="my_pdf_viewer" className={styles.pdfViewer} ref={refPdfViewer}>

                                <div id="canvas_container" className={styles.canvasContainer} /* onClick={(e) => openModal(e)} */>

                                    {Object.keys(firms).map((item) => {

                                        return firms[item]['page'] === currentPage && (<DraggableBox
                                            key={item}
                                            positions={(element) => setFirms((itemList) => Object.assign({ ...itemList }, { [item]: element }))}
                                            deleteBox={() => boxDelete(item)}
                                            refPdfViewer={refPdfViewer}
                                            signatory={firms[item]}
                                        />)

                                    })}

                                    <canvas id="pdf_renderer" ref={refCanvas}></canvas>

                                </div>

                                <div id="navigation_controls" className={styles.navigationControls}>

                                    <ControlsCustomButtom
                                        disabled={currentPage === 1}
                                        onClick={() => goPrevious()}
                                        variant="contained"
                                        disableElevation
                                    >
                                        Página Anterior
                                    </ControlsCustomButtom>

                                    <CustomTextControls variant="h6" gutterBottom>
                                        {currentPage}-{docPdf ? docPdf._pdfInfo.numPages : 0}
                                    </CustomTextControls>

                                    <ControlsCustomButtom
                                        disabled={[null, undefined, ''].includes(docPdf) ? false : (currentPage === docPdf._pdfInfo.numPages)}
                                        onClick={() => goNext()}
                                        variant="contained"
                                        disableElevation
                                    >
                                        Página Siguiente
                                    </ControlsCustomButtom>

                                </div>

                            </div>

                        </div>


                        <div className={styles.actionsContainer}>

                            <div className={styles.actionControlContainer}>

                                <Typography id="transition-modal-title" variant="h6" color={['black.main']} textAlign={'left'}>
                                    Asignación de firmas
                                </Typography>

                                <Typography id="transition-modal-title" variant="subtitle1" color={['gray.main']} textAlign={'left'}>
                                    Asigna las firmas en el documento
                                </Typography>

                                <br />

                                <Typography id="transition-modal-title" variant="subtitle1" color={['black.main']} textAlign={'left'}>
                                    Recuerde asignar la firma de los participantes en cada cédula además del espacio en donde tiene que firmar.
                                </Typography>

                                <div className={styles.actionButtom}>

                                    <CancelActionCustomButtom
                                        onClick={handleClose}
                                        variant="outlined"
                                        color="primary"
                                    >
                                        Cancelar
                                    </CancelActionCustomButtom>

                                    <CustomActionButtom variant="contained" onClick={uploadDocs}>
                                        Guardar
                                    </CustomActionButtom>

                                </div>

                            </div>

                            <div className={styles.actionControlContainer}>

                                <Typography id="transition-modal-title" variant="h6" color={['black.main']} textAlign={'center'}>
                                    ¿Quién debe firmar aquí?
                                </Typography>

                                <Typography id="transition-modal-title" variant="subtitle1" color={['gray.main']} textAlign={'center'}>
                                    Selecciona el firmante que quieres <br /> agregar al documento.
                                </Typography>

                                <form onSubmit={handleSubmit(onSubmit)} className={styles.formParticipant}>

                                    <Controller
                                        name={`addParticipan`}
                                        control={control}
                                        rules={{
                                            required: 'Debe seleccionar una opción'
                                        }}
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                            fieldState,
                                        }) => {
                                            return (
                                                <FormControl
                                                    error={fieldState.error ? true : false}
                                                >

                                                    {!verifiVariable(detailContract) && (<RadioGroup>

                                                        {participant.map((item, index) => {
                                                            return (<FormControlLabel
                                                                key={'sfdgfdg' + index}
                                                                control={
                                                                    <Radio
                                                                        value={JSON.stringify(item)}
                                                                        onChange={onChange}
                                                                    />
                                                                }
                                                                label={item["full_name"]}
                                                            />)
                                                        })}

                                                    </RadioGroup>)}

                                                    {/* <FormHelperText hidden={fieldState.error ? false : true}>{fieldState.error ? fieldState.error.message : null}</FormHelperText> */}

                                                </FormControl>
                                            );
                                        }}
                                    />

                                    <div className="addParticipant">

                                        <CustomActionButtom variant="contained" type="submit">
                                            Agregar
                                        </CustomActionButtom>

                                    </div>

                                </form>

                            </div>

                        </div>

                    </Box>

                </Fade>

            </Modal>

        </Fragment>

    );

}

export default AddSignDoc;