import {
    GridToolbarContainer,
    GridToolbarFilterButton,
} from "@mui/x-data-grid";
import React from "react";
import { AiOutlineReload } from "react-icons/ai";
import { Btn, Text } from "../../../..";
import { styles } from "../../../../../helpers";
import ModalAddUser from "../modalAddUser";

const CustomToolBar = ({ genKey, updateData }) => {
    return (
        <div className="contracts-list__top-menu">
            <div className="title-menu__status">
                <Text sx={styles.titleStyles} variant="h6">
                    Administración de Usuarios
                </Text>
                <Text sx={styles.subTitleStyles}>
                    Resumen de administración de usuarios
                </Text>
            </div>
            <GridToolbarContainer className="buttons-menu__status">
                <GridToolbarFilterButton
                    title="Filtros"
                    translate={true}
                    lang="es"
                    arrow={false}
                    className="grid-toolbar"
                    sx={styles.buttonGridStatusMenu}
                />
                <Btn
                    variant="contained"
                    text="Actualizar"
                    sx={styles.buttonStatusMenu}
                    onClick={updateData}
                    startIcon={
                        <AiOutlineReload className="icon--xs icon--blue-light" />
                    }
                />
                <ModalAddUser />
                {/* <Btn
                    variant="contained"
                    text="Añadir nuevo usuario"
                    sx={styles.buttonGoToCreatorMenu}
                    onClick={genKey}
                /> */}
            </GridToolbarContainer>
        </div>
    );
};

export default CustomToolBar;
