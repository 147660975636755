const stepsRegister = [
    {
        intro: "Bienvenido a la sección de registro de nuestra plataforma firmaVirtual"
    },
    {
        element: ".show-password",
        intro: "Opción para mostrar o ocultar la contraseña"
    },
    {
        element: ".buttom-register",
        intro: "Una vez suministrada toda la información correctamente, presione registrarse para culminar con el proceso"
    },
    {
        element: ".back-option",
        intro: "Opción para regregar a la sección de inicio de sesión"
    }
];

export default stepsRegister;