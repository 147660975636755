import { useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { DataGrid } from "@mui/x-data-grid";

import { styles } from "../../../helpers";
import { gridColumns, localizedTextsMap } from "./molecules/gridColumns";
import CustomToolbar from "./molecules/CustomToolbar";
import PopUp from "../../floats/PopUp";
import Text from "../../heading/Text";
import Btn from "../../buttons/Button";
import Spinner from "../../spinners/Spinner";
import { bodyTextStyles } from "../../../utils/validations";
import { getProvidersStorage } from "../../../store/providers/providers";

const Providers = () => {
    const {
        session: {
            login: { user },
        },
        providers: {
            providers,
            loading,
            message,
            status
        }
    } = useSelector((state) => state);
    const dispatch = useDispatch();
    const genKey = () =>
        setKey(
            Math.random()
                .toString()
                .split(".")
                .reduce((a, b) => a + b)
        );
    const [key, setKey] = useState("");
    const [dataGrid, setDataGrid] = useState([]);
    const [showModal, setShowModal] = useState(false);
    // eslint-disable-next-line
    const [modalIcon, setModalIcon] = useState();
    const [modalTitle, setModalTitle] = useState("");
    const [modalMsg, setModalMsg] = useState("");
    const [textSpinner, setTextSpinner] = useState("");
    const [modalButtonTitle, setModalButtonTitle] = useState("");

    const handleModal = () => {
        setModalTitle("");
        setModalMsg("");
        setModalButtonTitle("");
        setShowModal(false);
    };

    const getProviders = () => {

        const { token } = user;

        dispatch(getProvidersStorage(token));

    }


    useEffect(() => {

        if (![null, undefined, 'success', ''].includes(status)) {
            setModalTitle("¡Ups!, Algo ha pasado");
            setModalMsg(message);
            setModalButtonTitle("Cerrar");
            setShowModal(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status]);

    useEffect(() => {
        setTextSpinner("Cargando lista de proveedores...");
        setDataGrid(providers);
    }, [providers]);

    useEffect(() => {
        getProviders();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderList = useCallback(() => {
        return (
            <div
                className="container--relative contracts-status-list__container"
                style={{ height: "auto", width: "100%" }}
            >
                <div style={{ display: "flex", height: "100%" }}>
                    <div style={{ flexGrow: 1 }}>
                        <DataGrid
                            rows={dataGrid}
                            columns={gridColumns}
                            pageSize={8}
                            rowsPerPageOptions={[8]}
                            autoHeight={true}
                            autoPageSize={true}
                            getRowId={(row) => row.rut}
                            getCellClassName={null}
                            rowHeight={56}
                            loading={loading}
                            sx={styles.gridContainerStyles}
                            localeText={localizedTextsMap}
                            disableSelectionOnClick={true}
                            components={{
                                Toolbar: () => (
                                    <CustomToolbar
                                        genKey={genKey}
                                        updateData={() => {
                                            setTextSpinner(
                                                "Actualizando lista de proveedores..."
                                            );
                                            getProviders();
                                        }}
                                    />
                                ),
                                NoRowsOverlay: () => (
                                    <div className="data-grid__no-rows">
                                        No hay registros
                                    </div>
                                ),
                            }}
                            sortingOrder={["desc", "asc"]}
                            initialState={{
                                sorting: {
                                    sortModel: [
                                        { field: "notaryName", sort: "desc" },
                                    ],
                                },
                            }}
                        />
                    </div>
                </div>
            </div>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataGrid, loading]);

    return (
        <div className="content-detail__container" key={key}>
            {loading && (
                <Spinner
                    loading={true}
                    type="bar"
                    text={textSpinner}
                    opacity={true}
                />
            )}

            <PopUp
                open={showModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                title={modalTitle}
                icon={modalIcon}
                onBackdropClick={() => setShowModal(!showModal)}
            >
                <Text
                    sx={bodyTextStyles}
                    align="center"
                    variant="body1"
                    paragraph
                >
                    {modalMsg}
                </Text>
                <Btn
                    variant="contained"
                    text={modalButtonTitle}
                    size="lg"
                    color="primary"
                    type="submit"
                    buttonClass="button-submit"
                    onClick={handleModal}
                    fullWidth
                />
            </PopUp>

            {renderList()}
        </div>
    );
};

export default Providers;
