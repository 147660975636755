import { forwardRef, Fragment, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import FormHelperText from '@mui/material/FormHelperText';
import { default as AutocompleteMui } from '@mui/material/Autocomplete';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import DisabledByDefaultRoundedIcon from '@mui/icons-material/DisabledByDefaultRounded';
import Snackbar from '@mui/material/Snackbar';


/* import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel'; */

import { ReactComponent as PencilIcon } from "../../img/pencil-icon.svg";
import { ReactComponent as SusscesChange } from "../../img/sussces-change.svg";
import { ReactComponent as CloseIcon } from "../../img/close-icon.svg";

import Text from "../../../../heading/Text";
import { checkRut, validatePhoneNumber, validation } from "../../../../../utils/validations";
import { clearEditSigner } from "../../../../../store/formTramit";
import { EditParticipantStyles } from "./index.css";
import { updateAllSignerByEmail, updateAllSignerByRUT } from "../../services";


const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const CustompDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiGrid-root': {
        marginLeft: '0px'
    }
}));

const ActionsButton = styled(Button)(({ theme }) => ({
    fontStyle: 'normal',
    boxShadow: 'none',
    fontWeight: 'bolder',
    textTransform: 'none',
    fontSize: 16,
    padding: '11px 28px',
    border: '1px solid',
    borderRadius: '7px',
    lineHeight: 1.5,
    backgroundColor: theme.palette.primary.light,
    // backgroundColor: '#0063cc',
    borderColor: theme.palette.primary.light,
    fontFamily: [
        '"PoppinsBold"'
    ].join(','),
    '&:hover': {
        // backgroundColor: '#0069d9',
        backgroundColor: theme.palette.primary.xlight,
        // borderColor: '#0062cc',
        borderColor: theme.palette.primary.xlight,
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#0062cc',
        borderColor: '#005cbf',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
}));

const CustomSnackBar = styled(Snackbar)(({ theme }) => ({
    '& .MuiSnackbarContent-root': {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        zoom: '1.6'
    }
}));

const CustomDialogTitle = ({ children, onClose, ...other }) => {

    return (
        <DialogTitle sx={{ marginLeft: '16px', marginTop: '20px', color: (theme) => theme.palette.primary.light }} {...other}>
            {children}
            {onClose && (
                <DisabledByDefaultRoundedIcon
                    aria-label="close"
                    onClick={onClose}
                    className="icon--red icon--lg"
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        zIndex: 9999,
                        cursor: 'pointer'
                    }}
                >
                    <CloseIcon />
                </DisabledByDefaultRoundedIcon>
            )}
        </DialogTitle>
    );
}
CustomDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const EditParticipant = ({ member, contractMembers, numParticipant, idContract, loading, textLoading }) => {
    const styles = EditParticipantStyles();
    const {
        session: {
            login: {
                user,
                userInfo
            }
        },
        contract: {
            signatories: {
                previousSignatoriesList
            }
        },
        formTramit: {
            secondStep
        }
    } = useSelector((state) => state);
    const dispatch = useDispatch();
    // eslint-disable-next-line
    const [prevSigners, setPrevSigner] = useState(previousSignatoriesList);
    const [open, setOpen] = useState(false);
    const [snackbarOpen, setSnackbar] = useState(false);
    const [editSigner, setEditSigner] = useState(undefined);
    // eslint-disable-next-line
    const { control, trigger, setValue, handleSubmit, register, formState: { errors }, watch } = useForm({
        defaultValues: {
            full_name: "",
            email: "",
            rutId: "",
            phone: "",
            rol: [],
            pay: "0"
        }
    });
    // eslint-disable-next-line
    const storeSigners = [contractMembers.map((item) => {

        let rol;

        if ([0, 1].includes(item['iRole'])) {
            rol = ['Firmante'];
        }

        if ([3].includes(item['iRole'])) {
            rol = ['Pagador', 'Firmante'];
        }

        if ([4].includes(item['iRole'])) {
            rol = ['Pre Aprobador', 'Pagador'];
        }

        if ([2].includes(item['iRole'])) {
            rol = ['Pre Aprobador'];
        }

        return {
            full_name: item['full_name'],
            email: item['email'],
            rutId: item['RUT'],
            phone: item['phone'],
            rol: rol,
            pay: item['portion'].replace('.00', '')
        }
    })];
    const previewData = { ...member };
    const watchEmail = watch('email');
    const watchRutId = watch('rutId');
    // const watchRol = watch('rol');


    const handleClickOpenSnack = () => {
        setSnackbar(true);
    }

    const handleCloseSnack = () => {
        setSnackbar(false);
    }

    const handleClickOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
        dispatch(clearEditSigner());
    }

    // eslint-disable-next-line
    const verifyRol = (rol, owner) => {

        const { sRUT } = userInfo;

        if (rol.length === 1) {

            if (rol.includes('Firmante')) {
                return sRUT === owner ? 1 : 0;
            } else if (rol.includes('Pagador')) {
                return 3;
            } else if (rol.includes('Pre Aprobador')) {
                return 2;
            }

        } else {

            if (rol.includes('Firmante') && rol.includes('Pagador')) {
                return 0;
            } else if (rol.includes('Pagador') && rol.includes('Pre Aprobador')) {
                return 4;
            }

        }

    }

    const onSubmit = async (formData) => {

        try {
            textLoading('Guardando información');
            loading(true);
            trigger();
            const { token } = user;

            const data = {};

            Object.assign(data, {
                full_name: formData['full_name'],
                email: formData['email'],
                rutId: formData['rutId'],
                phone: formData['phone'],
                type: "NATURAL",
                portion: formData['pay'],
                payment: "0.00",
                // rol: verifyRol(formData['rol'], formData['rutId']),
                rol: formData['rol'],
                order: 1
            });

            if (previewData['email'] !== watchEmail) {

                await updateAllSignerByRUT(token, idContract, data);

            } else {
                await updateAllSignerByEmail(token, idContract, data);
            }


            handleClose();
            handleClickOpenSnack();

            setTimeout(() => {
                handleCloseSnack();
            }, 2500);

            loading(false);

        } catch (error) {
            loading(false);
            console.error(error);
        }

    }

    useEffect(() => {
        if (member) {

            /* let rol;

            if ([0, 1].includes(member['iRole'])) {
                rol = ['Firmante'];
            }

            if ([3].includes(member['iRole'])) {
                rol = ['Pagador', 'Firmante'];
            }

            if ([4].includes(member['iRole'])) {
                rol = ['Pre Aprobador', 'Pagador'];
            }

            if ([2].includes(member['iRole'])) {
                rol = ['Pre Aprobador'];
            } */

            setEditSigner({
                full_name: member['full_name'],
                email: member['email'],
                rutId: member['RUT'],
                phone: member['phone'],
                rol: member['iRole'],
                pay: member['portion'].replace('.00', '')
            });

            setValue('full_name', [member['full_name']]);
            setValue('email', member['email']);
            setValue('rutId', member['RUT']);
            setValue('phone', member['phone']);
            setValue('rol', member['iRole']);
            setValue('pay', member['portion'].replace('.00', ''));
            trigger();

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [member]);

    return (
        <Fragment>

            <PencilIcon className='icon--lg' onClick={handleClickOpen} />

            <CustomSnackBar
                style={{
                    '& .MuiSnackbarContent-root': {
                        all: 'initial',
                        padding: '10px'
                    }
                }}
                anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
                open={snackbarOpen}
                onClose={handleCloseSnack}
                message={<SusscesChange />}
            />


            <CustompDialog
                fullWidth={true}
                TransitionComponent={Transition}
                open={open}
                onClose={handleClose}
                maxWidth='md'
                className={styles.mainContainerDialog}
            >
                <form onSubmit={handleSubmit(onSubmit)} className={styles.formParticipant}>

                    <CustomDialogTitle onClose={handleClose}>
                        Participante {numParticipant}
                    </CustomDialogTitle>

                    <DialogContent>

                        <Grid container xs={12} spacing={2}>

                            <Grid item xs={12} md={6}>

                                <Controller
                                    name={`full_name`}
                                    control={control}
                                    rules={{
                                        required: "Este campo es requerido",
                                        maxLength: {
                                            message: 'Solo estan permitidos 120 caracteres',
                                            value: 120
                                        },
                                        pattern: validation.nombre
                                    }}
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                        fieldState,
                                    }) => {
                                        return (
                                            <FormControl
                                                fullWidth
                                                error={fieldState.error ? true : false}
                                            >
                                                <label>
                                                    <Text
                                                        color={fieldState.error ? 'error.main' : undefined}
                                                        className={styles.labelStyles}
                                                        align="left"
                                                        mgButton
                                                        variant="h6"
                                                    >
                                                        Nombre completo
                                                    </Text>
                                                </label>

                                                <AutocompleteMui
                                                    defaultValue={() => {
                                                        if (editSigner) {
                                                            const resultValue = prevSigners.find((prevSigner) => prevSigner['RUT'] === editSigner['rutId']);
                                                            return resultValue ? resultValue : null;
                                                        } else {
                                                            return null;
                                                        }
                                                    }}
                                                    freeSolo={true}
                                                    handleHomeEndKeys
                                                    multiple={false}
                                                    options={prevSigners}
                                                    getOptionLabel={(option) => {
                                                        return option.full_name
                                                    }}
                                                    onChange={(e, options, reason, details) => {
                                                        onChange((![null, undefined, ''].includes(options) ? options.full_name : ''));
                                                        setValue(`email`, (![null, undefined, ''].includes(options) ? options.email : ''));
                                                        setValue(`rutId`, (![null, undefined, ''].includes(options) ? options.RUT : ''));
                                                        setValue(`phone`, (![null, undefined, ''].includes(options) ? options.phone : ''));
                                                    }}
                                                    onInputChange={(event, data) => onChange(data)}
                                                    renderInput={(params) => <TextField
                                                        {...params}
                                                        placeholder='Nombre completo'
                                                        className={styles.autocompleteInput}
                                                    />}
                                                />

                                                <FormHelperText hidden={fieldState.error ? false : true}>{fieldState.error ? fieldState.error.message : null}</FormHelperText>

                                                <br />

                                            </FormControl>
                                        );
                                    }}
                                />

                            </Grid>

                            <Grid item xs={12} md={6}>

                                <Controller
                                    name={`email`}
                                    control={control}
                                    rules={{
                                        required: "Este campo es requerido",
                                        pattern: {
                                            message: 'Formato de correo invalido',
                                            value: validation.email
                                        },
                                        validate: {
                                            uniqueEmail: (value) => {

                                                const list = [...secondStep.filter((item) => ![null, undefined, ''].includes(item['email']))];

                                                const emailList = [];

                                                for (let index = 0; index < list.length; index++) {
                                                    const element = list[index];

                                                    if (editSigner) {// verifica si va a aeditar
                                                        if (editSigner['email'] !== element['email']) emailList.push(element['email']);
                                                    } else {
                                                        emailList.push(element['email']);
                                                    }
                                                }

                                                return emailList.includes(value) ? 'Email duplicado' : true;
                                            }
                                        }
                                    }}
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                        fieldState,
                                    }) => {
                                        return (
                                            <FormControl
                                                fullWidth
                                                error={fieldState.error ? true : false}
                                            >
                                                <label>
                                                    <Text
                                                        color={fieldState.error ? 'error.main' : undefined}
                                                        className={styles.labelStyles}
                                                        align="left"
                                                        mgButton
                                                        variant="h6"
                                                    >
                                                        Correo electrónico
                                                    </Text>
                                                </label>

                                                <TextField
                                                    disabled={previewData['RUT'] !== watchRutId}
                                                    type="text"
                                                    error={fieldState.error ? true : false}
                                                    ref={ref}
                                                    onBlur={onBlur}
                                                    className={styles.autocompleteInput}
                                                    onChange={onChange}
                                                    value={value}
                                                    placeholder={"tucorreo@mail.com"}
                                                    color={
                                                        fieldState.error
                                                            ? "error"
                                                            : "success"
                                                    }
                                                    focused={
                                                        fieldState.isTouched ||
                                                        fieldState.isDirty
                                                    }
                                                    fullWidth
                                                />

                                                <FormHelperText hidden={fieldState.error ? false : true}>{fieldState.error ? fieldState.error.message : null}</FormHelperText>

                                                <br />

                                            </FormControl>
                                        );
                                    }}
                                />

                            </Grid>

                            <Grid item xs={12} md={6}>

                                <Controller
                                    name={`rutId`}
                                    control={control}
                                    rules={{
                                        required: "Este campo es requerido",
                                        validate: {

                                            isPassPortOrRut: (value) => {

                                                if ([checkRut(value), validation.passport.test(value)].includes(true)) {
                                                    return true
                                                } else {
                                                    return 'RUT o Pasaporte inválido'
                                                }
                                            },

                                            uniqueRut: (value) => {

                                                const list = [...secondStep.filter((item) => ![null, undefined, ''].includes(item['rutId']))];

                                                const rutList = [];

                                                for (let index = 0; index < list.length; index++) {
                                                    const element = list[index];

                                                    if (editSigner) {// Verifica si va a editar
                                                        if (editSigner['rutId'] !== element['rutId']) rutList.push(element['rutId']);
                                                    } else {
                                                        rutList.push(element['rutId']);
                                                    }

                                                }

                                                return rutList.includes(value) ? 'RUT o Pasaporte duplicado' : true;
                                            }
                                        }
                                    }}
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                        fieldState,
                                    }) => {
                                        return (
                                            <FormControl
                                                fullWidth
                                                error={fieldState.error ? true : false}
                                            >
                                                <label>
                                                    <Text
                                                        color={fieldState.error ? 'error.main' : undefined}
                                                        className={styles.labelStyles}
                                                        align="left"
                                                        mgButton
                                                        variant="h6"
                                                    >
                                                        RUT  o Pasaporte
                                                    </Text>
                                                </label>

                                                <TextField
                                                    disabled={previewData['email'] !== watchEmail}
                                                    type="text"
                                                    error={fieldState.error ? true : false}
                                                    ref={ref}
                                                    onBlur={onBlur}
                                                    className={styles.autocompleteInput}
                                                    onChange={onChange}
                                                    value={value}
                                                    placeholder={"11111111-1 ó 111111111"}
                                                    color={
                                                        fieldState.error
                                                            ? "error"
                                                            : "success"
                                                    }
                                                    focused={
                                                        fieldState.isTouched ||
                                                        fieldState.isDirty
                                                    }
                                                    fullWidth
                                                />

                                                <FormHelperText hidden={fieldState.error ? false : true}>{fieldState.error ? fieldState.error.message : null}</FormHelperText>

                                                <br />

                                            </FormControl>
                                        );
                                    }}
                                />

                            </Grid>

                            <Grid item xs={12} md={6}>

                                <Controller
                                    name={`phone`}
                                    control={control}
                                    rules={{
                                        required: "Este campo es requerido",
                                        validate: {
                                            validPhoneNumber: (value) => validatePhoneNumber(value) || 'Formato de numero telefonico invalido'
                                        }
                                    }}
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                        fieldState,
                                    }) => {
                                        return (
                                            <FormControl
                                                fullWidth
                                                error={fieldState.error ? true : false}
                                            >
                                                <label>
                                                    <Text
                                                        color={fieldState.error ? 'error.main' : undefined}
                                                        className={styles.labelStyles}
                                                        align="left"
                                                        mgButton
                                                        variant="h6"
                                                    >
                                                        Número de celular
                                                    </Text>
                                                </label>

                                                <TextField
                                                    type="text"
                                                    error={fieldState.error ? true : false}
                                                    ref={ref}
                                                    onBlur={onBlur}
                                                    className={styles.autocompleteInput}
                                                    onChange={onChange}
                                                    value={value}
                                                    placeholder={"+56912345478"}
                                                    color={
                                                        fieldState.error
                                                            ? "error"
                                                            : "success"
                                                    }
                                                    focused={
                                                        fieldState.isTouched ||
                                                        fieldState.isDirty
                                                    }
                                                    fullWidth
                                                />

                                                <FormHelperText hidden={fieldState.error ? false : true}>{fieldState.error ? fieldState.error.message : null}</FormHelperText>

                                                <br />

                                            </FormControl>
                                        );
                                    }}
                                />

                            </Grid>

                            {/* <Grid item xs={12}>

                                <FormControl
                                    fullWidth
                                    error={errors.rol ? true : false}
                                >

                                    <label>
                                        <Text
                                            color={errors.rol ? 'error.main' : undefined}
                                            className={styles.labelStyles}
                                            align="left"
                                            mgButton
                                            variant="h6"
                                        >
                                            ¿Qué rol desempeñará el participante?
                                        </Text>
                                    </label>

                                    <FormGroup>

                                        <FormControlLabel
                                            disabled={(watchRol.includes('Firmante') || (watchRol.includes('Pagador') && watchRol.includes('Firmante'))) ? true : false}
                                            control={
                                                <Checkbox
                                                    defaultChecked={editSigner ? (editSigner['rol'].includes('Pre Aprobador') ? true : false) : false}
                                                    {...register("rol", { required: 'Este campo es requerido' })}
                                                    value={'Pre Aprobador'}
                                                />
                                            }
                                            label="Pre Aprobador"
                                        />

                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    defaultChecked={editSigner ? (editSigner['rol'].includes('Pagador') ? true : false) : false}
                                                    {...register("rol", { required: 'Este campo es requerido' })}
                                                    value={'Pagador'}
                                                />
                                            }
                                            label="Pagador"
                                        />

                                        <FormControlLabel
                                            disabled={(watchRol.includes('Pre Aprobador') && watchRol.includes('Pagador')) || watchRol.includes('Pre Aprobador') ? true : false}
                                            control={
                                                <Checkbox
                                                    defaultChecked={editSigner ? (editSigner['rol'].includes('Firmante') ? true : false) : false}
                                                    {...register("rol", { required: 'Este campo es requerido' })}
                                                    value={'Firmante'}
                                                />
                                            }
                                            label="Firmante"
                                        />

                                    </FormGroup>

                                    <FormHelperText hidden={errors.rol ? false : true}>{errors.rol ? errors.rol?.message : ''}</FormHelperText>

                                </FormControl>

                            </Grid> */}

                            {/* <Grid item xs={12}>

                                <Controller
                                    name={`pay`}
                                    control={control}
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                        fieldState,
                                    }) => {
                                        return (
                                            <FormControl
                                                fullWidth
                                                error={fieldState.error ? true : false}
                                            >
                                                <label>
                                                    <Text
                                                        color={fieldState.error ? 'error.main' : undefined}
                                                        className={styles.labelStyles}
                                                        align="left"
                                                        mgButton
                                                        variant="h6"
                                                    >
                                                        ¿Este participante realizará el pago del 100% del trámite?
                                                    </Text>
                                                </label>

                                                <RadioGroup
                                                    defaultValue={editSigner ? editSigner['pay'] : '0'}
                                                    row
                                                >

                                                    <FormControlLabel
                                                        disabled={[...storeSigners.map((signatories) => signatories['pay'])].includes('100') || [...storeSigners.filter((signatories) => signatories['pay'] === '50')].length === 2}
                                                        control={
                                                            <Radio
                                                                value="50"
                                                                onChange={onChange}
                                                            />
                                                        }
                                                        label="50%"
                                                    />

                                                    <FormControlLabel
                                                        disabled={([...storeSigners.map((signatories) => signatories['pay'])].includes('50') || [...storeSigners.map((signatories) => signatories['pay'])].includes('100'))}
                                                        control={
                                                            <Radio
                                                                value="100"
                                                                onChange={onChange}
                                                            />
                                                        }
                                                        label="100%"
                                                    />

                                                </RadioGroup>

                                                <FormHelperText hidden={fieldState.error ? false : true}>{fieldState.error ? fieldState.error.message : null}</FormHelperText>

                                                <Typography variant="caption" display="block" gutterBottom color={"gray.dark"}>
                                                    Selecciona al menos 1 de los firmantes como responsable del pago. <br />
                                                    Puedes dividir el pago hasta 2 firmantes.
                                                </Typography>

                                            </FormControl>
                                        );
                                    }}
                                />

                            </Grid> */}

                        </Grid>

                    </DialogContent>

                    <DialogActions style={{ justifyContent: 'flex-end', paddingBottom: '20px', marginRight: '16px' }}>

                        <ActionsButton variant="contained" color="primary" type="submit">
                            Guardar cambios
                        </ActionsButton>

                    </DialogActions>

                </form>

            </CustompDialog>

        </Fragment>
    );
}

export default EditParticipant;