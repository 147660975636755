import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';


export const ContractDetailReviewStyles = makeStyles((theme) => {

    return createStyles({
        userInfo: {
            '& h6': {
                color: theme.palette.gray.dark
            }
        },
        userTitleInfo: {
            marginRight: '55px',
            '& h6': {
                color: theme.palette.black.main,
                fontWeight: '700'
            }
        },
        infoDocsContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end'
        },
        userIcon: {
            marginRight: '22px'
        },
        userInfoContent: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginTop: '50px'
        },
        optionsContent: {
            display: 'flex',
            justifyContent: 'flex-end'
        },
        titleNumber: {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.white.main,
            width: '38px',
            height: '38px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '7px',
            marginRight: '15px'
        },
        contentTitle: {
            width: '100%',
            height: 'auto',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center'
        },
        infoDocs: {
            maxWidth: '722px',
            width: '100%',
            // maxHeight: '146px',
            height: 'auto',
            marginRight: '0px',
            paddingTop: '40px',
            paddingLeft: '29px',
            paddingBottom: '40px',
            background: '#FFFFFF',
            boxShadow: '0px 4px 22px 1px rgba(0, 79, 159, 0.03)',
            borderRadius: '6px'
        },
        fileIcon: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'baseline',
            '& svg': {
                marginRight: '7px'
            }
        },
        optionDocs: {
            display: 'flex',
            justifyContent: 'flex-end',
            maxWidth: '722px',
            width: '100%',
            maxHeight: '146px',
            height: 'auto',
            marginTop: '18px',
            '& div': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '42.94px',
                height: '40.82px',
                background: '#FFFFFF',
                borderRadius: '10px',
                cursor: 'pointer',
                borderStyle: 'solid',
                borderColor: theme.palette.primary.light,
                padding: '7px'
            },
            '& div:nth-child(1), & div:nth-child(2)': {
                marginRight: '31px'
            }
        },
        fourtSectionContent: {
            display: 'flex',
            justifyContent: 'center'
        },
        payTitleInfo: {
            marginTop: '60px',
            marginBottom: '20px',
            width: '100%'
        },
        payInfoMainContent: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            padding: '20px',
            backgroundColor: '#EEFBFF',
            borderRadius: '6px',
            border: '1px dashed #AAD1DE',
            '& div:nth-child(2)': {
                '& h6': {
                    textAlign: 'right',
                }
            }
        },
        payInfoContent: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        payTotalInfo: {
            display: 'flex',
            justifyContent: 'flex-end',
            '& :first-child': {
                marginRight: '60px'
            }
        },
        folderIconContainer: {
            width: '158px',
            height: '229px',
            background: '#EAF7FB',
            border: '1px dashed #00ADEA',
            borderRadius: '7px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            marginTop: '20px'
        },
        FilePreview: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '244px',
            height: 'auto',
            background: '#EAF7FB',
            border: '1px dashed #00ADEA',
            borderRadius: '7px',
            marginRight: '30px',
            marginTop: '20px',

            '& div:nth-child(3)': {
                width: '244px !important',
                height: '354px !important',

                '& object:first-child, img:first-child': {
                    height: '332px !important'
                }
            },

            '& embed': {
                width: '244px !important',
                height: '332px !important'
            }

        },
        uploadButtomContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        fileButomsContainer: {
            width: '100% !important',
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap'
        },
        fileMergePreview: {
            width: '158px',
            height: '229px',
            backgroundColor: '#EAF7FB',
            border: '1px dashed #00ADEA',
            borderRadius: '7px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginRight: '30px',
            marginTop: '20px',

            '& div:nth-child(3)': {
                width: '158px !important',
                height: '229px !important',
                backgroundColor: '#EAF7FB !important',

                '& object:first-child, img:first-child': {
                    height: '135px !important'
                }
            }

        },
        conatinerCloseButtomPreview: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between'
        },
        buttomClose: {
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '35px'
        },
        pdfViewer: {
            background: '#ccc',
            position: 'relative',
            margin: '2em auto'
        },
        canvasContainer: {
            width: '100%',
            height: 'auto',
            overflow: 'auto',
            background: '#333',
            textAlign: 'center',
            border: 'solid 3px'
        },
        mainContainerPdf: {
            overflowY: 'auto',
            width: 'auto',
            background: theme.palette.white.main,
            boxShadow: '0px 4px 11px 5px rgba(0, 79, 159, 0.04)',
            borderRadius: '7px',
            padding: '30px'
        },
        navigationControls: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            alignItems: 'center'
        },
        actionControlContainer: {
            background: theme.palette.white.main,
            height: 'auto',
            width: '357px',
            borderRadius: '7px',
            marginLeft: '20px',
            padding: '20px'
        },
        actionsContainer: {
            '& > div:first-child': {
                marginBottom: '50px'
            }
        },
        actionButtom: {
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '15px'
        },
        formParticipant: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',

            '& label': {
                justifyContent: 'center'
            },

            '& div.addParticipant': {
                display: 'flex',
                justifyContent: 'center',
                '& button': {
                    marginTop: '25px'
                }
            }
        },
        dragableBox: ({ layerX, layerY }) => ({
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'end',
            alignItems: 'baseline',
            position: 'absolute',
            left: `${layerX}px`,
            bottom: `${layerY}px`,
            background: 'transparent',
            cursor: 'move',
            '& p': {
                fontSize: '2rem'
            }
        }),
        boxIcon: {
            position: 'absolute',
            bottom: '43px',
            fill: 'red',
            left: '60px',
            zIndex: 999,
            cursor: 'pointer'
        },
        textIcon: ({ iconColor }) => ({
            fill: iconColor
        }),
        contentParticipant: {
            display: 'flex',
            justifyContent: 'space-around',
            flexWrap: 'wrap',
            marginTop: '85px'
        },
        userInfoContentModal: {
            flexDirection: 'column',
            marginTop: '0px',
            marginRight: '15px'
        },
        userIconModal: {
            marginRight: '0px'
        },
        userTitleInfoModal: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            marginRight: '0px',
            marginTop: '15px',
            '& :first-child': {
                color: theme.palette.primary.light,
                lineHeight: '0'
            }
        },
        bodyContentModal: {
            display: 'flex',
            alignItems: 'center'
        },
        mainCommentContent: {
            display: 'flex',
            flexDirection: 'column',
            '& div:last-child': {
                marginBottom: '0px !important',
            }
        },
        CommentContent: {
            borderRadius: '15px',
            padding: '20px',
            minWidth: '350px',
            marginBottom: '15px',
            backgroundColor: theme.palette.gray.light,
            '& :first-child': {
                fontSize: '1.1rem'
            }
        },
        buttomCloseCommentModal: {
            marginBottom: '20px'
        },
        mainContainerDialog: {
            '& div.MuiPaper-root': {
                borderRadius: '10px'
            }
        },
    });

});