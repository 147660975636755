import React from "react";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { FaBell, FaCircle, FaRegCalendarAlt } from "react-icons/fa";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { Button, Grid, Popover } from "@mui/material";
import { Btn } from "../..";

const PopupStateNotifications = ({ notifications }) => {

    const alerIcon = {
        border: '2px solid #fff',
        borderRadius: '999rem',
        height: '12px',
        position: 'absolute',
        right: '20px',
        top: '5px',
        width: '12px'
    };

    const buttonxs = {
        fontSize: "0.9rem",
        textTransform: "none",
        textDecoration: "underline",
        fontWeight: "500",
        color: "primary.light",
        marginLeft: "20px",
        "&:hover": {
            backgroundColor: "transparent",
            textDecoration: "underline",
        },
    };

    return (
        <PopupState variant="popover" popupId="popup-notification">
            {(popupState) => (
                <div>
                    <Button
                        variant="text"
                        color="primary"
                        {...bindTrigger(popupState)}
                    >
                        <FaCircle className="icon--xxs icon--red new-msg-alert" style={alerIcon} />
                        <FaBell className="icon--xs icon--grey-dark icon-pointer" />
                    </Button>
                    <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        {notifications && notifications.map((notification, idx) => {
                            return (
                                <Box p={2} key={idx} sx={{ backgroundColor: !notification.read ? '#F6F8FE' : '' }}>
                                    <Grid container spacing={2}>
                                        <Grid
                                            item
                                            xs={1}
                                            sx={{ textAlign: "right" }}
                                        >
                                            <FaRegCalendarAlt />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={8}
                                            sx={{
                                                textAlign: "left",
                                                paddingLeft: "8px !important",
                                            }}
                                        >
                                            <Typography className="title-notification">
                                                {notification.title}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography className="date-notification">
                                                {notification.date}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Box
                                        component="div"
                                        className="description-notification"
                                    >
                                        {notification.description}
                                    </Box>
                                    <Btn
                                        variant="text"
                                        onClick={() => console.log("click")}
                                        text="Learn more"
                                        size="xs"
                                        sx={buttonxs}
                                    />
                                </Box>
                            );
                        })}

                    </Popover>
                </div>
            )}
        </PopupState>
    );
};

export default PopupStateNotifications;
