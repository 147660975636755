import { createRef, useEffect, useState } from "react";
import { FaTrashAlt } from "react-icons/fa";
import { AiFillCheckCircle } from "react-icons/ai";

import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { PositionFirmStyles } from "../../index.css";


const DraggableBox = ({ positions, refPdfViewer, signatory, deleteBox }) => {

    const [width, setWidth] = useState(185);
    const [height, setHeight] = useState(52);
    const styles = PositionFirmStyles({
        width,
        height,
        layerX: signatory['x'],
        layerY: signatory['y'],
        iconColor: signatory['textColor']
    });
    const [textBox, setTextBox] = useState('');
    const boxRef = createRef();


    const coordinates = (element) => {

        // const top = element.position().top;
        const left = element.position().left;

        const bottom = window['jQuery'](refPdfViewer.current).height() - element.position().top;

        const CoordenadaXSuperiorDerecha = (left - 2.5);
        const CoordenadaYSuperiorDerecha = (bottom - 33);

        positions(Object.assign({ ...signatory }, {
            x: CoordenadaXSuperiorDerecha,
            y: CoordenadaYSuperiorDerecha
        }))

    }

    // On top layout
    // eslint-disable-next-line
    const onResize = (event, { element, size, handle }) => {
        console.log('onResize: ', element, size, handle);
        setWidth(size.width);
        setHeight(size.height);
    };

    useEffect(() => {

        const splitText = signatory['textTootip'].split(' ');

        const countWords = splitText.length;

        if (countWords === 1) {
            setTextBox(splitText[0]);
        } else {

            let newText = "";

            splitText.forEach((texto, index) => {

                if (index === 0) {
                    newText += texto
                } else {
                    newText += `${index > 1 ? '' : ' '}${texto.substring(0, 1).toUpperCase()}`;
                }

            });

            setTextBox(newText);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signatory['textTootip']]);

    useEffect(() => {

        const element = window['jQuery'](boxRef.current);

        element.draggable({
            start: () => {

            },
            stop: () => {
                coordinates(element);
            }
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (

        <Tooltip title={`Firmante ${signatory['numbSigned']}`} arrow placement="bottom-start">

            <div id="box" className={styles.dragableBox} ref={boxRef} style={{ height: `${height}px`, width: `${width}px` }}>

                <FaTrashAlt className={styles.boxIcon} onClick={() => deleteBox()} />

                <Typography variant="h6" color={[signatory['textColor']]}>
                    {textBox} <AiFillCheckCircle size={20} className={styles.textIcon} />
                </Typography>

            </div>

        </Tooltip>
    );
}

export default DraggableBox;