import axios from "axios";
import { api } from "../utils/enums";

const uri = api.BASE_URI;
const scheduleUri = api.BASE_SCHEDULE_URI;

export const getAllNotaries = async (userToken) => {
    try {
        const response = await axios.get(
            `${scheduleUri}${api.GET_ALL_NOTARIES}`,
            {
                headers: {
                    Authorization: userToken,
                },
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};

export const getAllNotarys = async (userToken) => {
    try {
        const response = await axios.get(
            `${scheduleUri}${api.GET_ALL_NOTARYS}`,
            {
                headers: {
                    Authorization: userToken,
                },
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};

export const getAllJuridiction = async (userToken) => {
    try {
        const response = await axios.get(
            `${scheduleUri}${api.GET_ALL_JURIDICTIONS}`,
            {
                headers: {
                    Authorization: userToken,
                },
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};

export const getAllCommune = async (userToken) => {
    try {
        const response = await axios.get(
            `${scheduleUri}${api.GET_ALL_COMMUNE}`,
            {
                headers: {
                    Authorization: userToken,
                },
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};

export const contractsType = async () => {
    try {
        const response = await axios.post(
            `${uri}${api.CONTRACT_TYPES}`,
            {},
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};

export const contractStoreFilesRequest = async (files, userToken) => {
    try {
        const response = await axios.post(`${uri}${api.UPLOAD_FILE}`, files, {
            headers: {
                Authorization: userToken,
            },
        });

        return response.data;
    } catch (error) {
        return error;
    }
};

export const contractCreationRequest = async (contract, userToken) => {
    try {
        const response = await axios.post(
            `${uri}${api.CONTRACT_CREATION}`,
            contract,
            {
                headers: {
                    Authorization: userToken,
                },
            }
        );

        return response;
    } catch (error) {
        return error;
    }
};

export const resetPassword = async (user) => {
    try {
        const response = await axios.post(
            `${uri}${api.RESET_PASS}/${user}`,
            {},
            {
                headers: {
                    contentType: "application/json",
                },
            }
        );
        return response.data;
    } catch (error) {
        return error;
    }
};

export const setNewPassword = async (user, code, password) => {
    try {
        const response = await axios.post(
            `${uri}${api.NEW_PASS}`,
            {
                login: user,
                password: code,
                new_password: password,
            },
            {
                headers: {
                    contentType: "application/json",
                },
            }
        );
        return response.data;
    } catch (error) {
        return error;
    }
};

export const setNewDocsOrder = async (contract, files, userToken) => {
    try {
        const response = await axios.post(
            `${uri}${api.SORT_CONTRACT_FILES}`,
            {
                contractID: contract,
                file_order: files,
            },
            {
                headers: {
                    Authorization: userToken,
                    contentType: "application/json",
                },
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};

export const deleteDocs = async (contract, files, userToken) => {
    try {
        const response = await axios.post(
            `${uri}${api.DELETE_CONTRACT_FILES}`,
            {
                contractID: contract,
                file_delete: files,
            },
            {
                headers: {
                    Authorization: userToken,
                    contentType: "application/json",
                },
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};

export const mergeDocs = async (contract, userToken) => {
    try {
        const response = await axios.post(
            `${uri}${api.MERGE_CONTRACT_FILES}/${contract}`,
            null,
            {
                headers: {
                    Authorization: userToken,
                },
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};

export const upaloadContractNotaryDoc = async (data, userToken) => {
    return axios.post(`${uri}${api.UP_NOTARY_DOC}`, data, {
        headers: {
            Authorization: userToken,
        },
    });
};

export const deleteContractDoc = async (userToken, idContrac) => {
    return axios.delete(`${uri}${api.DELETE_CONTARCT_DOC}/${idContrac}`, {
        headers: {
            Authorization: userToken
        },
    });
};

export const downloadContractDoc = async (file, userToken) => {
    try {
        const response = await axios.get(
            `${uri}${api.DOWNLOAD_CONTRACT_FILE}/${file}`,
            {
                responseType: "blob",
                headers: {
                    Authorization: userToken,
                },
            }
        );

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file.includes('.pdf') ? file : `${file}.pdf`);
        document.body.appendChild(link);
        link.click();

        return response.data;
    } catch (error) {
        return error;
    }
};

export const downloadCompiledContractDoc = async (contract, userToken, view) => {
    try {
        const response = await axios.get(
            `${uri}${api.DOWNLOAD_COMPILED_CONTRACT_FILE}/${contract}`,
            {
                responseType: "blob",
                headers: {
                    Authorization: userToken,
                },
            }
        );

        if (view) {
            const resultFile = new File([response.data], 'tramit', { type: "application/pdf" });
            return resultFile;
        } else {
            const url = window.URL.createObjectURL(new Blob([response.data]));

            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `contrato_${contract}.pdf`);
            document.body.appendChild(link);
            link.click();

            return response.data;
        }


    } catch (error) {
        return error;
    }
};


export const sendUserContractEmail = async (rutId, contract, body, userToken) => {
    try {
        const response = await axios.post(
            `${uri}${api.SEND_CONTRACT_EMAIL}/${contract}/${rutId}`,
            body,
            {
                headers: {
                    Authorization: userToken,
                },
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};

export const sendEmailSign = async (contract, userToken) => {
    try {
        const response = await axios.post(
            `${uri}${api.SEND_EMAIL_SIGN(contract)}`,
            null,
            {
                headers: {
                    Authorization: userToken,
                },
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};

export const resetContractApi = async (contract, userToken) => {
    try {
        const response = await axios.post(
            `${uri}${api.RESET_CONTRACT(contract)}`,
            null,
            {
                headers: {
                    Authorization: userToken,
                },
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};

export const sendContractPriotity = async (contract, userToken) => {
    try {
        const response = await axios.post(
            `${uri}${api.SEND_PRIORITY(contract)}`,
            null,
            {
                headers: {
                    Authorization: userToken,
                },
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};

export const sendContractEmail = async (contract, userToken) => {
    try {
        const response = await axios.post(
            `${uri}${api.SEND_CONTRACT_EMAIL}/${contract}`,
            null,
            {
                headers: {
                    Authorization: userToken,
                },
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};

export const deleteContract = async (contract, userToken) => {
    try {
        const response = await axios.post(
            `${uri}${api.DELETE_CONTRACT}/${contract}`,
            null,
            {
                headers: {
                    Authorization: userToken,
                },
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};

export const updateMemberInfoApiCall = async (
    contract,
    rutId,
    userToken,
    info
) => {
    try {
        const response = await axios.post(
            `${uri}${api.UPDATE_MEMBER_INFO}`,
            {
                contractID: contract,
                rutId: rutId,
                ...info,
            },
            {
                headers: {
                    Authorization: userToken,
                },
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};

export const deleteMemberApiCall = async (contract, rutId, userToken) => {
    try {
        const response = await axios.post(
            `${uri}${api.DELETE_MEMBER}`,
            {
                contractID: contract,
                rutId: rutId,
            },
            {
                headers: {
                    Authorization: userToken,
                },
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};

export const allContracts = async (userToken) => {
    try {
        const response = await axios.get(`${uri}${api.GET_ALL_CONTRACT}`, {
            headers: {
                Authorization: userToken,
            },
        });

        return response.data;
    } catch (error) {
        return error;
    }
};

export const allContractType = async (userToken) => {
    try {
        const response = await axios.get(`${uri}${api.GET_ALL_CONTRACT_TYPE}`, {
            headers: {
                Authorization: userToken,
            },
        });

        return response.data;
    } catch (error) {
        return error;
    }
};

export const createAgreement = async (agreement, userToken) => {
    try {
        const response = await axios.post(
            `${uri}${api.AGREEMENT_CREATION}`,
            agreement,
            {
                headers: {
                    Authorization: userToken,
                    contentType: "application/json",
                },
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};

export const getOrganizations = async (userToken) => {
    try {
        const response = await axios.post(
            `${uri}${api.GET_ALL_ORGANIZATIONS}`,
            {},
            {
                headers: {
                    Authorization: userToken,
                },
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};

export const getDetailAgreement = async (organization, userToken) => {
    try {
        const response = await axios.post(
            `${uri}${api.GET_AGREEMENT_DETAIL}`,
            { organization },
            {
                headers: {
                    Authorization: userToken,
                },
            }
        );

        return response;
    } catch (error) {
        return error;
    }
};

export const deleteAgreement = async (organization, userToken) => {
    try {
        const response = await axios.post(
            `${uri}${api.DELETE_AGREEMENT}`,
            { organization },
            {
                headers: {
                    Authorization: userToken,
                },
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};