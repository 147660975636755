import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { default as AutocompleteMui } from '@mui/material/Autocomplete';

import Text from "../../heading/Text";
import { PaperworkStyles } from "./index.css";
import { contractType, signaturesType } from "../../../placeholder/contracts";
import { to, typeUser, verifiVariable } from "../../../utils/enums";
import { Context } from "../../../context/utilsContext";
import { saveSchedulingInfo } from "../../../store/scheduling";


const SchedulePaperwork = () => {
    const { nextPage, setNextPage, toResumen, setToResumen } = useContext(Context);
    const styles = PaperworkStyles();
    const navigate = useNavigate();
    const {
        session: {
            login: {
                userInfo
            }
        },
        organizations: {
            agreements
        },
        agreement: {
            agreementList
        },
        scheduling: {
            firstStep: {
                procedureid,
                iSignedCount,
                proceduresignature,
                typeAgrement
            }
        },
        contract: {
            tramites: {
                providersTramList
            }
        }
    } = useSelector((state) => state);
    const refInputControl = useRef();
    const [listAgreements, setlistAgreements] = useState([]);
    const dispatch = useDispatch();
    const { control, watch, trigger, setValue, getValues } = useForm({
        mode: "onBlur",
        reValidateMode: "onChange",
        defaultValues: {
            procedureid: '',
            proceduresignature: '',
            iSignedCount: '',
            typeAgrement: ''
        }
    });
    // eslint-disable-next-line
    const [necessaryProc, setNecessaryProc] = useState(contractType);
    const [signChoiceOptions, setSignChoiceOptions] = useState([]);
    // eslint-disable-next-line
    const [signaturesQuery, setSignaturesQuery] = useState([]);
    // const [contractTypeFee, setContractTypeFee] = useState({});

    const nameInputTypeAgrement = 'typeAgrement';

    const nameInputProcId = 'procedureid';
    const watchProcId = watch(nameInputProcId);

    const nameInputProcSig = 'proceduresignature';

    const nameInputISignedCount = 'iSignedCount';
    const watchNameInputISignedCount = watch(nameInputISignedCount);

    const saveData = (url) => {

        trigger();

        let validData = true;

        let contractTypeFee = undefined;

        const formData = { ...getValues() };

        if (!(!verifiVariable(userInfo) && userInfo['iProfileID'] === typeUser.ADMIN)) delete formData[nameInputTypeAgrement]

        const data = Object.entries(formData).map((item) => item['1']);

        data.forEach((item) => {
            if ([null, undefined, ''].includes(item)) validData = false;
        });

        contractTypeFee = signChoiceOptions.find(element => parseInt(formData.proceduresignature) === element['iContractTypeFeeID']);

        if (validData) {
            dispatch(saveSchedulingInfo({ indexe: 'firstStep', value: Object.assign({ ...formData }, { 'contractTypeFee': contractTypeFee }) }));
            navigate(url);
        }

    }


    useEffect(() => {
        if (nextPage !== 0) saveData(to.SCHEDULE_CREATION_SIGNATORIES);

        return () => {
            setNextPage(0);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nextPage]);

    useEffect(() => {
        if (!verifiVariable(toResumen)) saveData(toResumen);

        return () => {
            setToResumen(undefined);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toResumen]);

    useEffect(() => {
        if (!verifiVariable(procedureid)) setValue(nameInputProcId, procedureid);
        if (!verifiVariable(iSignedCount)) setValue(nameInputISignedCount, iSignedCount);
        if (!verifiVariable(proceduresignature)) setValue(nameInputProcSig, proceduresignature);
        if (!verifiVariable(typeAgrement)) setValue(nameInputTypeAgrement, typeAgrement);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

        const results = [];

        if (providersTramList.length !== 0) {

            const realStateContractsTypes = providersTramList.filter(
                (contract) => {
                    let ret;

                    if (contract.sContractTypeName === (watchProcId ? watchProcId.value : '')) {
                        ret = contract;
                    }

                    return ret;
                }
            );

            const getContractsByCounter = realStateContractsTypes.filter(
                (contract) => {

                    if (Number(watchNameInputISignedCount) > 1) {
                        return contract.iSignCount === 2;
                    } else {
                        return contract.iSignCount === 1;
                    }
                }
            );

            setSignaturesQuery(getContractsByCounter);

            signaturesType.forEach((signature) => {
                getContractsByCounter.forEach((option, index) => {

                    if (option.sModal === signature.value) {

                        let data = undefined;

                        const findAgreement = listAgreements.find(element => (element['is_default'] === 1 && option['iContractTypeFeeID'] === element['iContractTypeFeeID']));

                        if (findAgreement) {
                            data = Object.assign({ ...option }, { ...findAgreement, fAmount: (parseFloat(findAgreement['notaryPrice']) + parseFloat(findAgreement['price'])) })
                        } else {
                            data = { ...option };
                        }

                        results.push({ ...data, label: signature.name, value: signature.name });
                    }

                });
            });

            setSignChoiceOptions(results);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchNameInputISignedCount, watchProcId]);

    useEffect(() => {

        const listArray = [];

        if ([null, undefined].includes(agreementList['error'])) {

            agreementList.forEach((item) => {
                return item['agreementList'].forEach((agrement) => listArray.push(Object.assign({ ...agrement }, item['agreement'])));
            });

            if (listArray.length) setValue(nameInputISignedCount, 2);

            setlistAgreements(listArray);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [agreementList]);

    return (

        <div className="procedure-creation__form-container">

            <div className="form__container">

                <form>

                    <Grid container xs={12} spacing={2}>

                        {(!verifiVariable(userInfo) && userInfo['iProfileID'] === typeUser.ADMIN) && (<Grid container item xs={12}>

                            <Controller
                                name={nameInputTypeAgrement}
                                control={control}
                                rules={{
                                    required: {
                                        value: (!verifiVariable(userInfo) && userInfo['iProfileID'] === typeUser.ADMIN) ? true : false,
                                        message: "Este campo es requerido"
                                    }
                                }}
                                render={({
                                    field: { onChange, onBlur, value, ref },
                                    fieldState
                                }) => {
                                    return (
                                        <FormControl
                                            fullWidth
                                            error={fieldState.error ? true : false}
                                        >

                                            <label>
                                                <Text
                                                    className={styles.labelStyles}
                                                    color={fieldState.error ? 'error.main' : undefined}
                                                    align="left"
                                                    mgButton
                                                    variant="h6"
                                                >
                                                    Seleccione tipo de convenio
                                                </Text>
                                            </label>

                                            <AutocompleteMui
                                                defaultValue={typeAgrement}
                                                isOptionEqualToValue={(option, value) => option.agreementId === value.agreementId}
                                                multiple={false}
                                                options={agreements}
                                                getOptionLabel={(option) => option.agreement}
                                                onChange={(e, options) => { onChange(options) }}
                                                renderInput={(params) => <TextField
                                                    ref={refInputControl}
                                                    {...params}
                                                    placeholder='Seleccione tipo de convenio'
                                                    className={styles.autocompleteInput}
                                                />}

                                            />

                                            <FormHelperText hidden={fieldState.error ? false : true}>{fieldState.error ? fieldState.error.message : null}</FormHelperText>

                                            <br />

                                        </FormControl>
                                    );
                                }}
                            />

                        </Grid>)}


                        <Grid container item xs={12}>

                            <Controller
                                name={nameInputProcId}
                                control={control}
                                rules={{
                                    required: "Este campo es requerido",
                                    validate: {
                                        optionVlidate: (value) => necessaryProc.includes(value) || 'Debe seleccionar una opción valida'
                                    }
                                }}
                                render={({
                                    field: { onChange, onBlur, value, ref },
                                    fieldState
                                }) => {
                                    return (
                                        <FormControl
                                            fullWidth
                                            error={fieldState.error ? true : false}
                                        >

                                            <label>
                                                <Text
                                                    className={styles.labelStyles}
                                                    color={fieldState.error ? 'error.main' : undefined}
                                                    align="left"
                                                    mgButton
                                                    variant="h6"
                                                >
                                                    ¿Qué trámite necesitas gestionar?
                                                </Text>
                                            </label>

                                            <AutocompleteMui
                                                defaultValue={procedureid}
                                                isOptionEqualToValue={(option, value) => option.name === value.name}
                                                multiple={false}
                                                options={necessaryProc}
                                                getOptionLabel={(option) => option.name}
                                                onChange={(e, options) => { onChange(options); setValue(nameInputProcSig, '') }}
                                                renderInput={(params) => <TextField
                                                    ref={refInputControl}
                                                    {...params}
                                                    placeholder='¿Qué trámite necesitas gestionar?'
                                                    className={styles.autocompleteInput}
                                                />}

                                            />

                                            <FormHelperText hidden={fieldState.error ? false : true}>{fieldState.error ? fieldState.error.message : null}</FormHelperText>

                                        </FormControl>
                                    );
                                }}
                            />

                        </Grid>

                        {!listAgreements.length && (<Grid item xs={12}>

                            <br />

                            <Controller
                                name={nameInputISignedCount}
                                control={control}
                                rules={{
                                    required: 'Debe elegir una opción'
                                }}
                                render={({
                                    field: { onChange, onBlur, value, ref },
                                    fieldState
                                }) => {
                                    return (
                                        <FormControl
                                            fullWidth
                                            error={fieldState.error ? true : false}
                                        >
                                            <label>
                                                <Text
                                                    color={fieldState.error ? 'error.main' : undefined}
                                                    className={styles.labelStyles}
                                                    align="left"
                                                    mgButton
                                                    variant="h6"
                                                >
                                                    ¿Mas de una persona firmará este documento?
                                                </Text>
                                            </label>

                                            <RadioGroup
                                                row
                                                onBlur={onBlur}
                                                ref={ref}
                                                value={value}
                                                onChange={(e, options) => { onChange(options); setValue(nameInputProcSig, '') }}
                                            >
                                                <FormControlLabel key={'SI'} value={2} control={<Radio />} label={'SI'} />

                                                <FormControlLabel key={'NO'} value={1} control={<Radio />} label={'NO'} />

                                            </RadioGroup>

                                            <FormHelperText hidden={fieldState.error ? false : true}>{fieldState.error ? fieldState.error.message : null}</FormHelperText>

                                        </FormControl>
                                    );
                                }}
                            />

                        </Grid>)}

                        <Grid item xs={12}>

                            {listAgreements.length > 0 && (<Fragment>
                                <br />
                                <br />
                            </Fragment>)}

                            <Controller
                                name={nameInputProcSig}
                                control={control}
                                rules={{
                                    required: 'Debe elegir una opción',
                                    validate: {
                                        optionVlidate: (value) => {
                                            const arrayOptions = [...signChoiceOptions].map((item) => item['iContractTypeFeeID']);
                                            return arrayOptions.includes(parseInt(value)) || 'Debe seleccionar una opción valida'
                                        }
                                    }
                                }}
                                render={({
                                    field: { onChange, onBlur, value, ref },
                                    fieldState,
                                }) => {

                                    return (
                                        <FormControl
                                            fullWidth
                                            error={fieldState.error ? true : false}
                                        >
                                            <label>
                                                <Text
                                                    color={fieldState.error ? 'error.main' : undefined}
                                                    className={styles.labelStyles}
                                                    align="left"
                                                    mgButton
                                                    variant="h6"
                                                >
                                                    ¿Qué tipo de firma necesitas?
                                                </Text>
                                            </label>

                                            <RadioGroup
                                                onBlur={onBlur}
                                                ref={ref}
                                                value={value}
                                                onChange={onChange}
                                                color="success"
                                            >
                                                {signChoiceOptions.map((item) => {
                                                    return (
                                                        <FormControlLabel key={item['label']} value={item['iContractTypeFeeID']} control={<Radio />} label={item['label']} />
                                                    );
                                                })}

                                            </RadioGroup>

                                            <FormHelperText hidden={fieldState.error ? false : true}>{fieldState.error ? fieldState.error.message : null}</FormHelperText>

                                        </FormControl>
                                    );
                                }}
                            />

                        </Grid>

                    </Grid>

                </form>

            </div>

        </div>

    );
}

export default SchedulePaperwork;