import React from "react";
import { useDispatch } from "react-redux";

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import { endSession } from "../../../../store/auth/userLogin";
import { purgeSigners } from "../../../../store/contract/signatoriesList";
import { clearFormSolicitud } from "../../../../store/formSolicitud/formSolicitud";
import { ReactComponent as Logo } from "../../../../assets/logo/logo.svg";
import { FormularioSolicitudStyles } from "../../index.css";

const ConfirmPage = () => {
  const styles = FormularioSolicitudStyles();
  const dispatch = useDispatch();
  
  React.useEffect(() => {

    setTimeout(() => {

      endSession();
      dispatch(purgeSigners());
      dispatch(clearFormSolicitud());

      window.location.replace("https://firmavirtual.legal/gracias-por-tu-solicitud");

    }, 1000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column', alignContent: 'flex-start', alignItems: 'center', justifyContent: 'center' }}>

      <Logo className={styles.logo} />

      <h1 style={{ fontSize: 'calc(25px + 1vw)' }}>Su solicitud a sido enviada con exito </h1>

      <br />

      <CircularProgress size={200} />

      <h1 style={{ fontSize: 'calc(25px + 1vw)' }}>Redirigiendo...</h1>

    </Box>

  );
}

export default ConfirmPage;