import React, { createContext, useState, useRef } from 'react';
import PropTypes from 'prop-types';
export const Context = createContext();

const Provider = ({ children }) => {
    const Ref = useRef(null);

    const [reload, setReload] = useState(false);

    const [timer, setTimer] = useState('00:00:00');

    const [nextPage, setNextPage] = useState(0);

    const [toResumen, setToResumen] = useState(undefined);

    const [loading, setLoading] = useState(false);

    const [addParticipant, setAddParticipant] = useState(0);

    const [deleteParticipant, setDeleteParticipant] = useState(0);

    const [currentDocumentPage, setCurrentDocumentPage] = useState(() => {
        if (typeof window !== 'undefined') return JSON.parse(window.sessionStorage.getItem('currentDocumentPage'));
    });

    const [numberOfDocumentPages, setNumberOfDocumentPages] = useState(() => {
        if (typeof window !== 'undefined') return JSON.parse(window.sessionStorage.getItem('numberOfDocumentPages'));
    });

    const [currentDocumentScale, setCurrentDocumentScale] = useState(() => {
        if (typeof window !== 'undefined') return JSON.parse(window.sessionStorage.getItem('currentDocumentScale'));
    });

    const [controlsBlocked, setControlsBlocked] = useState(() => {
        if (typeof window !== 'undefined') return JSON.parse(window.sessionStorage.getItem('controlsBlocked'));
    });

    const getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / 1000 / 60 / 60) % 24);
        return {
            total, hours, minutes, seconds
        };
    }

    const startTimer = (e) => {
        let { total, hours, minutes, seconds } = getTimeRemaining(e);
        if (total >= 0) {

            // update the timer
            // check if less than 10 then we need to 
            // add '0' at the beginning of the variable
            setTimer(
                (hours > 9 ? hours : '0' + hours) + ':' +
                (minutes > 9 ? minutes : '0' + minutes) + ':'
                + (seconds > 9 ? seconds : '0' + seconds)
            )
        }
    }

    const clearTimer = (e) => {

        // If you adjust it you should also need to
        // adjust the Endtime formula we are about
        // to code next    
        setTimer('00:00:45');

        // If you try to remove this line the 
        // updating of timer Variable will be
        // after 1000ms or 1sec
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000)
        Ref.current = id;
    }

    const getDeadTime = () => {
        let deadline = new Date();

        // This is where you need to adjust if 
        // you entend to add more time
        deadline.setSeconds(deadline.getSeconds() + 45);
        return deadline;
    }

    const value = {
        toResumen,
        loading,
        controlsBlocked,
        currentDocumentPage,
        currentDocumentScale,
        numberOfDocumentPages,
        nextPage,
        addParticipant,
        deleteParticipant,
        timer,
        reload,
        setReload: (reload) => {
            setReload(reload);
        },
        setTimer: () => {
            clearTimer(getDeadTime());
        },
        setToResumen: (url) => {
            setToResumen(url);
        },
        setLoading: (event) => {
            setLoading(event);
        },
        setDeleteParticipant: (remove) => {
            setDeleteParticipant(remove);
        },
        setAddParticipant: (add) => {
            setAddParticipant(add);
        },
        setNextPage: (next) => {
            setNextPage(next);
        },
        setCurrentDocumentPage: (documentPageToSet) => {
            setCurrentDocumentPage(documentPageToSet);
            if (typeof window !== 'undefined') window.sessionStorage.setItem('currentDocumentPage', JSON.stringify(documentPageToSet));
        },
        setCurrentDocumentScale: (documentScaleToSet) => {
            setCurrentDocumentScale(documentScaleToSet);
            if (typeof window !== 'undefined') window.sessionStorage.setItem('currentDocumentScale', JSON.stringify(documentScaleToSet));
        },
        setNumberOfDocumentPages: (numberOfTotalPages) => {
            setNumberOfDocumentPages(numberOfTotalPages);
            if (typeof window !== 'undefined') window.sessionStorage.setItem('numberOfDocumentPages', JSON.stringify(numberOfTotalPages));
        },
        setControlsBlocked: (controlsBlockedState) => {
            setControlsBlocked(controlsBlockedState);
            if (typeof window !== 'undefined') window.sessionStorage.setItem('controlsBlocked', JSON.stringify(controlsBlockedState));
        }
    };

    return <Context.Provider value={value}>{children}</Context.Provider>;
};

Provider.propTypes = {
    children: PropTypes.element
};
// eslint-disable-next-line
export default {
    Provider,
    Consumer: Context.Consumer
};
