import React from "react";
import { useNavigate } from "react-router-dom";

import { Box } from "@mui/material";
import { BsEye } from "react-icons/bs";

import { to, typeUser } from "../../../../../utils/enums";
import ModalUploadNotaryDoc from "../../../../modalUploadNotaryDoc/ModalUploadNotaryDoc";
import ScreenSizeHook from "../../../../../hooks/ScreenSizeHook";
import {
    getUserInfo,
    getUserToken,
} from "../../../../storeSelector/storeSelector";

const RenderActions = ({ id, row }) => {
    const navigate = useNavigate();
    const userToken = getUserToken();
    const userInfo = getUserInfo();

    const button = {
        marginTop: `${["xs"].includes(ScreenSizeHook) ? "15px" : "0px"}`,
        fontSize: "0.8rem",
        textTransform: "none",
        backgroundColor: "quinary.light",
        color: "primary.light",
        "&:hover": {
            backgroundColor: "primary.xlight",
        },
    };

    return (
        <Box
            sx={{
                position: "relative",
                display: "flex",
                alignContent: "center",
            }}
            className="flex--1 flex--center"
        >
            {userInfo.profileId === typeUser.ADMIN && (
                <ModalUploadNotaryDoc
                    styledButton={button}
                    contract={id}
                    userToken={userToken}
                    inList={true}
                />
            )}

            <Box
                sx={{ padding: "6px" }}
                onClick={() => navigate(to.CONTRACT_DETAIL, { state: id })}
            >
                <BsEye className="icon--xs icon-pointer icon--blue-light" />
            </Box>

            {/* <ModalContractDelete contract={row} /> */}
        </Box>
    );
};

export default RenderActions;
