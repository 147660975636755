import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
    name: "formSolicitud",
    initialState: {
        sOwnerType: "NATURAL",
        sPaymentStatus: "PENDING",
        iPaymentService: 0,
        iContractTypeFeeID: null,
        iContractTypeID: null,
        iSignedCount: 0,
        callback: "",
        signers: [],
        document: null,
        signerPay: null,
        knowServices: null,
        question: null,
        proceduretype: null,
        procedureid: null,
        datosPersonales: {
            sNamelInvited: '',
            sEmailInvited: ''
        },
        errorStep: false
    },
    reducers: {

        FORM_SOLICITUD_SAVE_INFO_DATOS_PERSONALES: (state, action) => {
            state.datosPersonales[action.payload['indexe']] = action.payload['value'];
        },

        FORM_SOLICITUD_SAVE_INFO: (state, action) => {
            state[action.payload['indexe']] = action.payload['value'];
        },

        FORM_SOLICITUD_SAVE_INFO_SIGNERS: (state, action) => {
            state.signers = action.payload;
        },

        FORM_SOLICITUD_PURGE_STATE: (state, action) => {
            state.sOwnerType = "NATURAL"
            state.sPaymentStatus = "PENDING"
            state.iPaymentService = 0
            state.iContractTypeFeeID = null
            state.iContractTypeID = null
            state.iSignedCount = 0
            state.callback = ""
            state.signers = []
            state.document = null
            state.signerPay = null
            state.knowServices = null
            state.question = null
            state.proceduretype = null
            state.procedureid = null
            state.datosPersonales = {
                sNamelInvited: '',
                sEmailInvited: ''
            }
            state.errorStep = false
        },

        FORM_SOLICITUD_ERROR_STEP: (state, action) => {
            state.errorStep = action.payload;
        }

    }
});

export const {
    FORM_SOLICITUD_SAVE_INFO_DATOS_PERSONALES,
    FORM_SOLICITUD_ERROR_STEP,
    FORM_SOLICITUD_SAVE_INFO,
    FORM_SOLICITUD_SAVE_INFO_SIGNERS,
    FORM_SOLICITUD_PURGE_STATE
} = slice.actions;


export const saveInfoDatosPersonalesFormSolicitud = (data) => {
    return { type: FORM_SOLICITUD_SAVE_INFO_DATOS_PERSONALES.type, payload: data };
};

export const errorStepFormSolicitud = (data) => {
    return { type: FORM_SOLICITUD_ERROR_STEP.type, payload: data };
};

export const saveSignersFormSolicitud = (data) => {
    return { type: FORM_SOLICITUD_SAVE_INFO_SIGNERS.type, payload: data };
};

export const saveInfoFormSolicitud = (data) => {
    return { type: FORM_SOLICITUD_SAVE_INFO.type, payload: data };
};

export const clearFormSolicitud = () => {
    return { type: FORM_SOLICITUD_PURGE_STATE.type, payload: null };
};

export default slice.reducer;
