import { forwardRef, Fragment, useState } from "react";
import { useSelector } from "react-redux";

import { Box, ListItem, List, Link } from "@mui/material";
import DialogContentText from '@mui/material/DialogContentText';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

import Text from "../../heading/Text";
import { DetailContracFileUploadStyles } from "./index.css";
import ScreenSizeHook from "../../../hooks/ScreenSizeHook";
import Btn from "../../buttons/Button";
import { externalLink } from "../../../utils/enums";
import { ReactComponent as FileUploadIcon } from "../../../assets/icons/fileupload.svg";
import Spinner from "../../spinners/Spinner";
import PositionFirm from "../../forms/positionFirm";
import { useNavigate } from "react-router-dom";
import { contractStoreFilesRequest, mergeDocs, sendContractEmail } from "../../../utils/api";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DetailContracFileUpload = () => {
    const navigation = useNavigate();
    const styles = DetailContracFileUploadStyles();
    const {
        session: {
            login: {
                user
            }
        },
        contract: {
            contractCreator: {
                contractCreatedDetails: {
                    contrato
                }
            }
        },
    } = useSelector((state) => state);
    const screenSizeHook = ScreenSizeHook();
    const [displayForm, setDisplayForm] = useState(false);
    const [filePdf, setFilePdf] = useState(null);
    const [fileSigned, setFileSigned] = useState(null);
    const [runSpinner, setRunSpinner] = useState(false);
    const [open, setOpen] = useState(false);


    const handleClickConfir = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const fileFormContainer = {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: ["xs", "sm"].includes(screenSizeHook) ? "column" : "row",
    };

    const hintContainer = {
        py: "15px",
        px: "25px",
        marginRight: "10px",
        paddingBottom: "35px",
        backgroundColor: "primary.xlight",
        borderRadius: "5px",
        flex: "1"
    };

    const hintBodyButtonText = {
        fontWeight: "500",
        textTransform: "none",
        float: "left",
        marginTop: "10px",
        "&:hover": {
            color: "black.main",
            backgroundColor: "transparent"
        }
    };

    const hintBodyButton = {
        backgroundColor: "primary.light",
        px: "10px",
        fontWeight: "500",
        textTransform: "none",
        float: "left",
        marginTop: "10px"
    };

    const hintBackButton = { ...hintBodyButton, marginRight: '13px' };

    delete hintBackButton['backgroundColor'];

    const resetFile = () => {
        setFilePdf(null);
    }

    const handleDisplayForm = () => {
        setDisplayForm(!displayForm);
    }

    const fileupload = async () => {

        try {

            setRunSpinner(true);

            const { token } = user;
            const formData = new FormData();

            const randomID = Math.floor(Math.random() * (999999999 - 0));

            formData.append(
                "fileUp",
                fileSigned,
                `${contrato[0]['sContractID']}_${randomID}.pdf`
            );

            await contractStoreFilesRequest(formData, token);

            await mergeDocs(contrato[0]['sContractID'], token);

            await sendContractEmail(contrato[0]['sContractID'], token);

            navigation(-1);

            setRunSpinner(false);

        } catch (error) {
            setRunSpinner(false);
            console.error(error);
        }

    }

    const ResendUserContract = () => {

        return (
            <Fragment>

                <Dialog
                    fullWidth={true}
                    TransitionComponent={Transition}
                    open={open}
                    onClose={handleClose}
                    maxWidth='xs'
                    className={styles.mainContainerDialog}
                >

                    <DialogTitle sx={{ textAlign: 'center' }}>
                        ¿Estás seguro que deseas realizar esta acción?
                    </DialogTitle>

                    <DialogContent>

                        <DialogContentText>
                            Una vez confirmado el procedimiento sera redirigido al detalle del tramite.
                        </DialogContentText>

                    </DialogContent>

                    <DialogActions style={{ justifyContent: 'center', paddingBottom: '20px' }}>

                        <Btn
                            variant="outlined"
                            text="Cancelar"
                            color="error"
                            onClick={() => handleClose()}
                            sx={{
                                height: '39px',
                                borderRadius: '20px'
                            }}
                        />

                        <Btn
                            variant="outlined"
                            text="Aceptar"
                            color="primary"
                            onClick={() => { fileupload(); handleClose(); }}
                            sx={{
                                height: '39px',
                                borderRadius: '20px',
                                marginLeft: '30px !important'
                            }}
                        />

                    </DialogActions>

                </Dialog>

            </Fragment>
        );

    }

    return (

        <Fragment>

            {runSpinner && (<Spinner loading={true} type="bar" opacity={true} />)}

            <div className={styles.mainContent}>

                <ResendUserContract />

                <div className={styles.formContainer}>

                    {[null, undefined, ''].includes(filePdf) ? (<Box sx={fileFormContainer}>
                        <Box sx={hintContainer}>
                            <Text classes={styles.titleStyles} align="left">
                                Antes de continuar, por favor sigue estas
                                instruciones, una vez adjunto, esta acción es
                                irreversible. Debes adjuntar el documento de la
                                siguiente manera:
                            </Text>
                            <List className={styles.hintListContainer}>
                                <ListItem
                                    className={styles.hintListItem}
                                    disableGutters
                                    divider
                                >
                                    Todos los documentos solicitados (cédula de
                                    identidad, pasaporte, contrato(s),
                                    documento(s) de texto, etc) deben estar
                                    ordenados y ser parte de un (1) archivo PDF
                                </ListItem>
                                <ListItem
                                    className={styles.hintListItem}
                                    disableGutters
                                    divider
                                >
                                    Si todos tus archivos están separados,
                                    puedes usar{" "}
                                    <Link
                                        className={styles.hintBodyLinkText}
                                        href={externalLink.FV_APP_PDF}
                                        target="_blank"
                                    >
                                        esta herramienta
                                    </Link>{" "}
                                    para ordenarlos y crear un PDF
                                </ListItem>
                                <ListItem
                                    className={styles.hintListItem}
                                    disableGutters
                                    divider
                                >
                                    Solo puedes adjuntar un (1) solo archivo PDF
                                </ListItem>
                                <ListItem className={styles.hintListItem} disableGutters>

                                    <Btn
                                        variant="contained"
                                        color="error"
                                        text="Regresar"
                                        size="md"
                                        onClick={() => navigation(-1)}
                                        sx={hintBackButton}
                                    />

                                    <Link
                                        href={externalLink.FV_APP_PDF}
                                        target="_blank"
                                        className="tool"
                                    >
                                        <Btn
                                            variant="contained"
                                            text="Generar PDF con mis archivos"
                                            size="md"
                                            sx={hintBodyButton}
                                        />
                                    </Link>

                                    <Btn
                                        buttonClass="option-load"
                                        variant="text"
                                        text="Ya cuento con un archivo PDF"
                                        size="md"
                                        sx={hintBodyButtonText}
                                        onClick={handleDisplayForm}
                                    />

                                </ListItem>
                            </List>
                        </Box>

                        {displayForm && (
                            <Box className={styles.fileForm}>

                                <div className="input-container">

                                    <div className="input-file__container input-load-doc">
                                        <input
                                            type="file"
                                            multiple={false}
                                            accept=".pdf"
                                            onChange={(event) => {
                                                setFilePdf(event.target.files[0]);
                                            }}
                                        />
                                        <div className="input-file-hint__container">
                                            <FileUploadIcon />
                                            <Text
                                                sx={styles.titleHintStyle}
                                                align="center"
                                            >
                                                Haz click o arrastra a esta
                                                área para subir tu PDF
                                            </Text>
                                        </div>
                                    </div>

                                </div>

                            </Box>
                        )}

                    </Box>) : <PositionFirm
                        custonSigner={undefined}
                        filePdf={filePdf}
                        resetFile={() => resetFile()}
                        editedFile={(file) => {
                            handleClickConfir();
                            setFileSigned(file);
                        }}
                    />}

                </div>
            </div>

        </Fragment>
    );
}

export default DetailContracFileUpload;