const stepsLogin = [
    {
        intro: "Bienvenido a la sección de inicio de sesión de nuestra plataforma firmaVirtual"
    },
    {
        element: ".show-password",
        intro: "Opción para mostrar o ocultar la contraseña"
    },
    {
        element: ".buttom-login",
        intro: "Una vez coloque las credenciales correctamente, presione ingresar para entrar al sistema"
    },    
    {
        element: ".option-password-recovery",
        intro: "Opción para recuperar su contraseña en caso de haberla perdido o querer cambiarlar"
    },
    {
        element: ".option-register",
        intro: "Opción para crear una cuenta"
    }
];

export default stepsLogin;