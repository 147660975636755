import axios from "axios";
// import { api as Api } from "../utils/enums";

const baseUrl = 'https://api.firmavirtual.legal';

const version = 'v1';

const api = 'api';

const urlProviders = {
    list: `${baseUrl}/${api}/${version}/notary/find-list`,
    create: `${baseUrl}/${api}/${version}/notary/add`,
    del: `${baseUrl}/${api}/${version}/notary/del`,
    edit: `${baseUrl}/${api}/${version}/notary/upd`,
    assoc: `${baseUrl}/${api}/${version}/notary/contract-type`,
    listTram: `${baseUrl}/${api}/${version}/contract-type/ALL/0`
}

export const getTramites = async (token) => {
    try {

        const response = await axios.get(urlProviders.listTram, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `${token}`,
            }
        });

        return response.data;
    } catch (error) {
        return error;
    }
}

export const assocContractToProvider = async (data, token) => {
    try {

        const response = await axios.post(urlProviders.assoc, data, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `${token}`,
            }
        });

        return response.data;
    } catch (error) {
        return error;
    }
}

export const editProvider = async (data, token) => {
    try {

        const response = await axios.post(urlProviders.edit, data, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `${token}`,
            }
        });

        return response.data;
    } catch (error) {
        return error;
    }
}

export const delProvider = async (data, token) => {
    try {

        const response = await axios.post(urlProviders.del, data, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `${token}`,
            }
        });

        return response.data;
    } catch (error) {
        return error;
    }
}

export const createProvider = async (data, token) => {
    try {

        const response = await axios.post(urlProviders.create, data, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `${token}`,
            }
        });

        return response.data;
    } catch (error) {
        return error;
    }
}

export const getProviderList = async (token) => {
    try {

        const response = await axios.post(urlProviders.list, {}, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `${token}`,
            }
        });

        return response.data;
    } catch (error) {
        return error;
    }
}