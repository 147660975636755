import axios from "axios";
import { api } from "../../../../utils/enums";

const uri = api.BASE_URI;


export const updateAllSignerByRUT = async (userToken, idContract, body) => {
    try {
        const response = await axios.post(
            `${uri}${api.UPDATE_ALL_SIGNER_BY_RUT(idContract)}`,
            body,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: userToken
                }
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};

export const updateAllSignerByEmail = async (userToken, idContract, body) => {
    try {
        const response = await axios.post(
            `${uri}${api.UPDATE_ALL_SIGNER_BY_EMAIL(idContract)}`,
            body,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: userToken
                }
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};