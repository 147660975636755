import { createSlice } from "@reduxjs/toolkit";
import { apiCallStart } from "../actions/api";

const slice = createSlice({
    name: "users",
    initialState: {
        loading: false,
        listUsers: [],
        message: null,
        code: null,
        status: null
    },
    reducers: {

        CLEAR_ALL: (state, action) => {
            state.listUsers = [];
        },

        SAVE_LIST_USERS: (state, action) => {
            state.listUsers = action.payload;
        },

        USERS_LIST_REQUEST: (state, action) => {
            state.loading = true;
            state.status = null;
            state.code = null;
            state.message = null;
        },

        USERS_LIST_SUCCESS: (state, action) => {
            state.listUsers = action.payload['message'];
            state.status = action.payload['status'];
            state.code = action.payload['code'];
            state.loading = false;
            state.message = null;
        },
        
        USERS_LIST_FAIL: (state, action) => {
            state.message = action.payload['message'];
            state.status = action.payload['status'];
            state.code = action.payload['code'];
            state.loading = false;
        }
    },
});

export const {
    CLEAR_ALL,
    SAVE_LIST_USERS,
    USERS_LIST_REQUEST,
    USERS_LIST_SUCCESS,
    USERS_LIST_FAIL
} = slice.actions;

export const clearAll = () => {
    return { type: CLEAR_ALL.type, payload: null };
};

export const addUsers = (rest) => {
    return { type: SAVE_LIST_USERS.type, payload: rest };
};

export const getUsersStorage = (token) => {
    return apiCallStart({
        url: `/api/v1/userapp`,
        method: "GET",
        onStart: USERS_LIST_REQUEST.type,
        onSuccess: USERS_LIST_SUCCESS.type,
        onError: USERS_LIST_FAIL.type,
        authorization: token
    });
};

export default slice.reducer;