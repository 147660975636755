import React from "react";
import { useNavigate } from "react-router-dom";

import { Box } from "@mui/material";
import { to, verifiVariable } from "../../../../../utils/enums";
import { styles } from "../../../../../helpers";
import { BsEye } from "react-icons/bs";
import ModalContractDelete from "../../molecules/modalContractDelete/ModalContractDelete";

const RenderActions = ({ id, status }) => {
    const navigate = useNavigate();

    console.log('id :', id);
    console.log('status: ', status);
    return (
        <Box
            sx={styles.actionButtonsContainerStyles}
            className="flex--1 flex--center"
        >
            <Box
                sx={{ padding: "6px" }}
                onClick={() => navigate(to.CONTRACT_DETAIL, { state: id })}
            >
                <BsEye className="icon--xs icon-pointer icon--blue-light" />
            </Box>
            {
                (!verifiVariable(status) && ['CREATED', 'REVIEW', 'REJECTED'].includes(status)) &&
                (
                    <Box sx={styles.actionButtonsStyles}>
                        <ModalContractDelete contract={id} />
                    </Box>
                )
            }
        </Box>
    );
};

export default RenderActions;
