import { Box } from '@mui/material';
import React from 'react';
import { styles } from '../../../../../helpers';
import Text from '../../../../heading/Text';

const RenderCell = ({ item }) => {

    return (

        <Box sx={{
            position: "relative",
            display: "flex",
            alignContent: 'center',
            padding: "6px",
            whiteSpace: 'normal'
        }} className="flex--1 flex--center">

            <Text sx={styles.userNameStyles} variant="h6">{item}</Text>

        </Box>
    );
};

export default RenderCell;