import { createSlice } from "@reduxjs/toolkit";
import { procedureType } from "../../placeholder/contracts";
import { apiCallStart } from "../actions/api";


const groupByTramits = (list) => {

    const items = list.map((item) => {
        const find = procedureType.find(
            (o) => o.value === item["sContractType"]
        );

        return Object.assign(item, { groupBy: find.name });
    });

    return items;

}


const slice = createSlice({
    name: "tramites",
    initialState: {
        tramList: [],
        allTramitAgreements: [],
        providersTramList: [],
        typeTramList: [],
        loadingTramits: false,
        loadingTypeTramits: false,
        message: null,
        code: null,
        status: null
    },
    reducers: {

        ALL_TRAMITES: (state, action) => {
            state.tramList = action.payload;
        },

        ALL_CONTRACT_TYPE: (state, action) => {
            state.typeTramList = action.payload;
        },

        CLEAR_ALL: (state, action) => {
            state.tramList = [];
            state.typeTramList = [];
        },

        TYPE_TRAMITS_LIST_REQUEST: (state, action) => {
            state.loadingTypeTramits = true;
            state.status = null;
            state.code = null;
            state.message = null;
        },

        ALL_TRAMITS_LIST_AGREEMENTS_SUCCESS: (state, action) => {
            state.allTramitAgreements = groupByTramits(action.payload['message']);
            state.providersTramList = groupByTramits(action.payload['message']);
            state.status = action.payload['status'];
            state.code = action.payload['code'];
            state.loadingTypeTramits = false;
            state.message = null;
        },

        ALL_TRAMITS_LIST_SUCCESS: (state, action) => {
            state.tramList = action.payload['message'];
            state.status = action.payload['status'];
            state.code = action.payload['code'];
            state.loadingTypeTramits = false;
            state.message = null;
        },

        TYPE_TRAMITS_LIST_SUCCESS: (state, action) => {
            state.typeTramList = action.payload['message'];
            state.status = action.payload['status'];
            state.code = action.payload['code'];
            state.loadingTypeTramits = false;
            state.message = null;
        },

        TYPE_TRAMITS_LIST_FAIL: (state, action) => {
            state.message = action.payload['message'];
            state.status = action.payload['status'];
            state.code = action.payload['code'];
            state.loadingTypeTramits = false;
        },

        TRAMITS_LIST_REQUEST: (state, action) => {
            state.loadingTramits = true;
            state.status = null;
            state.code = null;
            state.message = null;
        },

        TRAMITS_LIST_SUCCESS: (state, action) => {
            state.tramList = action.payload['message'];
            state.status = action.payload['status'];
            state.code = action.payload['code'];
            state.loadingTramits = false;
            state.message = null;
        },

        TRAMITS_LIST_FAIL: (state, action) => {
            state.message = action.payload['message'];
            state.status = action.payload['status'];
            state.code = action.payload['code'];
            state.loadingTramits = false;
        }

    },
});

export const {
    ALL_TRAMITES,
    ALL_CONTRACT_TYPE,
    CLEAR_ALL,
    TRAMITS_LIST_REQUEST,
    TRAMITS_LIST_SUCCESS,
    ALL_TRAMITS_LIST_SUCCESS,
    TRAMITS_LIST_FAIL,
    TYPE_TRAMITS_LIST_REQUEST,
    TYPE_TRAMITS_LIST_SUCCESS,
    ALL_TRAMITS_LIST_AGREEMENTS_SUCCESS,
    TYPE_TRAMITS_LIST_FAIL
} = slice.actions;

export const clearAllTramits = () => {
    console.log('entrando aca')
    return { type: CLEAR_ALL.type, payload: null };
};

export const getAllTramitStorage = (token) => {
    return apiCallStart({
        url: `/api/v1/contract-type/ALL/0`,
        method: "GET",
        onStart: TYPE_TRAMITS_LIST_REQUEST.type,
        onSuccess: ALL_TRAMITS_LIST_AGREEMENTS_SUCCESS.type,
        onError: TYPE_TRAMITS_LIST_FAIL.type,
        authorization: token
    });
};

export const allTramit = (list) => {
    return { type: ALL_TRAMITES.type, payload: list };
};

export const allTypeTramit = (list) => {
    return { type: ALL_CONTRACT_TYPE.type, payload: list };
};

export const getTypeTramitStorage = (token) => {
    return apiCallStart({
        url: `/api/v1/contract-type`,
        method: "GET",
        onStart: TYPE_TRAMITS_LIST_REQUEST.type,
        onSuccess: TYPE_TRAMITS_LIST_SUCCESS.type,
        onError: TYPE_TRAMITS_LIST_FAIL.type,
        authorization: token
    });
};

export const getTramitsStorage = (token) => {
    return apiCallStart({
        url: `/api/v1/contract`,
        method: "GET",
        onStart: TRAMITS_LIST_REQUEST.type,
        onSuccess: TRAMITS_LIST_SUCCESS.type,
        onError: TRAMITS_LIST_FAIL.type,
        authorization: token
    });
};

export default slice.reducer;