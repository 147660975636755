import React, { useState, useEffect } from "react";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import CustomToolbar from "./molecules/CustomToolbar";
import { styles } from "../../../helpers";
import { gridColumns, localizedTextsMap } from "./constants";
import { useDispatch, useSelector } from "react-redux";
import { bodyTextStyles } from "../../../utils/validations";
import { getUsersStorage } from "../../../store/users/userList";
import { getTramitsStorage, getTypeTramitStorage } from "../../../store/contract/tramites";
import Spinner from "../../spinners/Spinner";
import PopUp from "../../floats/PopUp";
import { Btn, Text } from "../..";

const ManageProcedures = () => {
    const {
        session: {
            login: { user },
        },
        users: {
            listUsers,
            loading,
            status: requestStatusUsersList,
            message: resquestUserListMsg
        },
        contract: {
            tramites: {
                tramList,
                typeTramList,
                loadingTramits,
                loadingTypeTramits,
                status,
                message
            }
        }
    } = useSelector((state) => state);

    const dispatch = useDispatch();

    const [key, setKey] = useState("");
    const [textSpinner, setTextSpinner] = useState("");
    const [dataGrid, setDataGrid] = useState([]);
    const [showModal, setShowModal] = useState(false);
    // eslint-disable-next-line
    const [modalIcon, setModalIcon] = useState();
    const [modalTitle, setModalTitle] = useState("");
    const [modalMsg, setModalMsg] = useState("");
    const [modalButtonTitle, setModalButtonTitle] = useState("");

    const getContracts = () => {

        const { token } = user;

        dispatch(getUsersStorage(token));

        dispatch(getTramitsStorage(token));

        dispatch(getTypeTramitStorage(token));

    }

    React.useEffect(() => {

        if (![null, undefined, 'success', ''].includes(status)) {
            setModalTitle("¡Ups!, Algo ha pasado");
            setModalMsg(message);
            setModalButtonTitle("Cerrar");
            setShowModal(true);
        } else if (![null, undefined, 'success', ''].includes(requestStatusUsersList)) {
            setModalTitle("¡Ups!, Algo ha pasado");
            setModalMsg(resquestUserListMsg);
            setModalButtonTitle("Cerrar");
            setShowModal(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, requestStatusUsersList]);

    useEffect(() => {
        getContracts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const genKey = () =>
        setKey(
            Math.random()
                .toString()
                .split(".")
                .reduce((a, b) => a + b)
        );

    const handleModal = () => {
        setModalTitle("");
        setModalMsg("");
        setModalButtonTitle("");
        setShowModal(false);
    };

    useEffect(() => {
        setTextSpinner("Cargando listado de trámites...");
        const tramites = tramList.map((tramite) => {
            listUsers.forEach((user) => {
                if (tramite.iContractOwner === user.iUserID) {
                    tramite = { ...tramite, user };
                }
            });

            typeTramList.forEach((type) => {
                if (tramite.iContractTypeFeeID === type.iContractTypeID) {
                    tramite = {
                        ...tramite,
                        type,
                    };
                }
            });

            return tramite;
        });

        setDataGrid(tramites.filter((item) => item['sPaymentStatus'] === 'PENDING' || item['sPaymentStatus'] === 'PAIDOUT' || item['sPaymentStatus'] === 'PARTIALLY_PAID'));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [typeTramList, tramList]);

    const renderList = React.useCallback(() => {
        return (
            <div
                className="container--relative contracts-status-list__container"
                style={{ height: "auto", width: "100%" }}
            >
                <div style={{ display: "flex", height: "100%" }}>
                    <div style={{ flexGrow: 1 }}>
                        <DataGrid
                            rows={dataGrid}
                            columns={gridColumns}
                            pageSize={8}
                            rowsPerPageOptions={[8]}
                            autoHeight={true}
                            autoPageSize={true}
                            getRowId={(row) => row.sContractID}
                            getCellClassName={null}
                            rowHeight={56}
                            loading={loading || loadingTramits || loadingTypeTramits}
                            sx={[
                                styles.gridContainerStyles,
                                {
                                    [`& .${gridClasses.cell}`]: {
                                        py: 2,
                                        fontSize: "8px",
                                    },
                                },
                            ]}
                            localeText={localizedTextsMap}
                            disableSelectionOnClick={true}
                            components={{
                                Toolbar: () => (
                                    <CustomToolbar
                                        genKey={genKey}
                                        updateData={() => {
                                            setTextSpinner(
                                                "Actualizando listado de trámites..."
                                            );
                                            getContracts();
                                        }}
                                    />
                                ),
                                NoRowsOverlay: () => (
                                    <div className="data-grid__no-rows">
                                        No hay registros
                                    </div>
                                ),
                            }}
                            sortingOrder={["desc", "asc"]}
                            initialState={{
                                sorting: {
                                    sortModel: [
                                        { field: "sNumberID", sort: "desc" },
                                    ],
                                },
                            }}
                        />
                    </div>
                </div>
            </div>
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataGrid, loading, loadingTramits, loadingTypeTramits, gridColumns]);

    return (
        <div className="content-detail__container" key={key}>
            {(loading || loadingTramits || loadingTypeTramits) && (
                <Spinner
                    loading={true}
                    type="bar"
                    text={textSpinner}
                    opacity={true}
                />
            )}

            <PopUp
                open={showModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                title={modalTitle}
                icon={modalIcon}
                onBackdropClick={() => setShowModal(!showModal)}
            >
                <Text
                    sx={bodyTextStyles}
                    align="center"
                    variant="body1"
                    paragraph
                >
                    {modalMsg}
                </Text>
                <Btn
                    variant="contained"
                    text={modalButtonTitle}
                    size="lg"
                    color="primary"
                    type="submit"
                    buttonClass="button-submit"
                    onClick={handleModal}
                    fullWidth
                />
            </PopUp>

            {renderList()}
        </div>
    );
};

export default ManageProcedures;