import RenderActions from "../atoms/renderActions";
import RenderCell from "../atoms/renderCell";

const localizedTextsMap = {
    columnMenuUnsort: "Sin ordenar",
    columnMenuSortAsc: "De menor a mayor",
    columnMenuSortDesc: "De mayor a menor",
    columnMenuFilter: "Filtro",
    columnMenuHideColumn: "Ocultar",
    columnMenuShowColumns: "Mostrar columnas",
    toolbarExport: "Descargar",
    toolbarFilters: "Filtros",
    filterOperatorContains: "Contiene",
    filterOperatorEquals: "Igual a",
    filterOperatorStartsWith: "Empieza con",
    filterOperatorEndsWith: "Termina con",
    filterOperatorIsEmpty: "Esta vacio",
    filterOperatorIsNotEmpty: "No esta vacio",
    filterPanelInputLabel: "Valor",
    filterPanelInputPlaceholder: "Filtrar por valor",
};

const methodPayment = (value) => {

    if (['DEFAULT_PAYMENT', 'AFTER_PAYMENT'].includes(value)) {
        return value === 'DEFAULT_PAYMENT' ? 'Pago por contrato' : 'Pago mensual';
    } else {
        return value;
    }

}

const gridColumns = [
    {
        field: "rut",
        headerAlign: "center",
        headerName: "Ruc",
        headerClassName: "grid__header-item",
        cellClassName: "grid__row-item text--uppercase-all text--center",
        width: 180
    },
    {
        field: "agreementId",
        headerAlign: "center",
        headerName: "Id",
        headerClassName: "grid__header-item",
        cellClassName: "grid__row-item text--uppercase-all text--center",
        width: 160,
        renderCell: (params) => <RenderCell item={methodPayment(params.value.toString())} />
    },
    {
        field: "agreement",
        headerAlign: "center",
        headerName: "Nombre del Convenio",
        headerClassName: "grid__header-item",
        cellClassName: "grid__row-item text--uppercase-all text--center",
        width: 350,
        renderCell: (params) => <RenderCell item={methodPayment(params.value)} />
    },
    {
        field: "ModalPayment",
        headerAlign: "center",
        headerName: "Modalidad",
        headerClassName: "grid__header-item",
        cellClassName: "grid__row-item text--uppercase-all text--center",
        width: 160,
        renderCell: (params) => <RenderCell item={methodPayment(params.value)} />
    },
    {
        field: "",
        headerAlign: "center",
        headerName: "Ver",
        headerClassName: "grid__header-item",
        rowClassName: "grid__row-item",
        width: 100,
        disableColumnMenu: true,
        renderCell: (params) => <RenderActions row={methodPayment(params.row)} />
    }
];

export {
    gridColumns,
    localizedTextsMap
};