import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { BiRightArrowAlt } from "react-icons/bi";

import { motion } from "framer-motion";

import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import FormHelperText from '@mui/material/FormHelperText';

import { errorStepFormSolicitud } from "../../../../store/formSolicitud/formSolicitud";
import { FormularioSolicitudStyles } from "../../index.css";

const StepTen = ({ setValue, getValues }) => {
    const getSigners = getValues('signers');
    const styles = FormularioSolicitudStyles();
    const {
        formSolicitud: {
            signerPay
        }
    } = useSelector((state) => state);
    const dispatch = useDispatch();

    const [state, setState] = React.useState(getSigners.filter((item) => !['', null, undefined].includes(item['nombres'])));

    // eslint-disable-next-line
    const error = state.filter((v) => v.pay).length;

    const handleChange = (event) => {

        const arrayList = [...state];

        arrayList[event.target.name]['pay'] = event.target.checked;

        setState(arrayList);
    };

    React.useEffect(() => {
        if (![1, 2].includes(error)) {
            dispatch(errorStepFormSolicitud(true));
        } else {
            dispatch(errorStepFormSolicitud(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    React.useEffect(() => {
        setValue("signerPay", signerPay);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signerPay]);

    return (
        <div className={styles.stepBox}>

            <motion.div
                initial={{ y: "50vh" }}
                animate={{ y: 0 }}
                transition={{ stiffness: 150 }}
            >

                <Grid container xs={12}>

                    <Grid item xs={1} className={styles.boxIndetify}>

                        10<BiRightArrowAlt />

                    </Grid>

                    <Grid item xs={11}>

                        <Typography variant="h4" className={styles.cssTexto1} gutterBottom>
                            ¿Quién paga por el trámite? *
                        </Typography>

                        <FormControl
                            fullWidth
                            error={true}
                        >

                            <FormGroup>

                                {state.map((item, index) => {
                                    return (

                                        <FormControlLabel
                                            key={item['rut']}
                                            control={<Checkbox checked={item['pay']} onChange={handleChange} name={index} />}
                                            label={item['nombres']}
                                        />

                                    );
                                })}

                            </FormGroup>

                            <FormHelperText hidden={[1, 2].includes(error)}>Debe elegir 1 o 2 firmantes</FormHelperText>

                        </FormControl>

                    </Grid>

                </Grid>

            </motion.div>

        </div>
    );
}

export default StepTen;