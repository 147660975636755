import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FaRegGrinWink, FaRegSadCry } from "react-icons/fa";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";

import Btn from "../../../../buttons/Button";
import { ModalAddUserStyles } from "./ModalAddUser.css";
import Spinner from "../../../../spinners/Spinner";
import { styles as stylesHelpers } from "../../../../../helpers";
import ScreenSizeHook from "../../../../../hooks/ScreenSizeHook";
import { validatePhoneNumber } from "../../../../../utils/validations";
import { addUsers } from "../../../../../store/users/userList";
import { createUser } from "../../../../../utils/api-user";

const ModalAddUser = () => {
    const {
        session: {
            login: { user },
        },
        users: { listUsers },
    } = useSelector((state) => state);
    const dispatch = useDispatch();
    const screenSizeHook = ScreenSizeHook();
    const styles = ModalAddUserStyles();

    const { control, handleSubmit, reset } = useForm({
        defaultValues: {
            iProfileID: 1,
            sRUT: "",
            sFirstName: "",
            sLastName: "",
            sEmail: "",
            sPhone: "",
            sPassword: ""
        },
    });

    const [open, setOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [description, setDescription] = useState("");
    const [texButtonGoback, setTexButtonGoback] = useState("");
    const [restStatus, setRestStatus] = useState(undefined);
    const [spinner, setSpinner] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleClickOpen = () => {
        setModalTitle("Registrar usuario");
        setDescription("Formulario de registro de usuarios.");
        setTexButtonGoback("Volver");
        setRestStatus(undefined);
        setSpinner(false);
        setOpen(true);
    };

    const handleClose = () => {
        if (spinner) return;
        reset();
        setOpen(false);
    };

    const onSubmit = async (data) => {
        const { token } = user;

        try {
            setLoading(true);

            const arrayList = [...listUsers];

            const hardCodeData = {
                iAgreement: 1,
                sUserType: 'NATURAL',
                sComments: 'Usuario creado desde frontEnd'
            }

            const restAddUser = await createUser(Object.assign({ ...data }, hardCodeData), token);

            if (restAddUser["status"] === "success") {
                arrayList.push(restAddUser["message"][0]['userApp']);
                setTexButtonGoback("Cerrar");
                setModalTitle("Registro Exitoso");
                setDescription("Sus datos fueron guardados correctamente");
                dispatch(addUsers(arrayList));
                reset();
                setRestStatus(true);
            } else {
                setTexButtonGoback("Cerrar");
                setModalTitle("Registro Fallido");
                setDescription(restAddUser["message"]["msg"]);
                setRestStatus(false);
            }

            setLoading(false);
        } catch (error) {
            setTexButtonGoback("Cerrar");
            setModalTitle("Registro Fallido");
            setDescription(
                "Hubo un problema al guardar sus datos, intentelo nuevamente o comuniquese con un administrador del sistema"
            );
            setRestStatus(false);
            setLoading(false);
        }
    };

    return (
        <>
            <Btn
                variant="contained"
                text="Registrar Usuario"
                sx={stylesHelpers.buttonGoToCreatorMenu}
                onClick={() => handleClickOpen()}
            />

            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth={["xs"].includes(screenSizeHook) ? "xs" : "md"}
            >
                {restStatus === undefined && (
                    <Stack className={styles.buttomsTopContent}>
                        <IconButton
                            aria-label="delete"
                            size="small"
                            onClick={handleClose}
                        >
                            <AiOutlineCloseCircle
                                fontSize="inherit"
                                size={20}
                            />
                        </IconButton>
                    </Stack>
                )}

                {restStatus === true && (
                    <Stack className={styles.buttomsTopSuccessContent}>
                        <IconButton
                            aria-label="delete"
                            size="small"
                            onClick={handleClose}
                        >
                            <FaRegGrinWink
                                fontSize="inherit"
                                size={150}
                                className="icon--red"
                            />
                        </IconButton>
                    </Stack>
                )}

                {restStatus === false && (
                    <Stack className={styles.buttomsTopSuccessContent}>
                        <IconButton
                            aria-label="delete"
                            size="small"
                            onClick={handleClose}
                        >
                            <FaRegSadCry
                                fontSize="inherit"
                                size={150}
                                className="icon--red"
                            />
                        </IconButton>
                    </Stack>
                )}

                <form
                    onSubmit={handleSubmit(onSubmit)}
                    encType="multipart/form-data"
                >
                    <DialogTitle className={styles.constentDialogTitle}>
                        {modalTitle}
                    </DialogTitle>

                    <DialogContent>
                        {loading && (
                            <Spinner loading={true} type="bar" opacity={true} />
                        )}

                        <DialogContentText align="center">
                            {description}
                        </DialogContentText>

                        <br />

                        {restStatus === undefined && (
                            <Grid
                                container
                                spacing={1}
                                className={styles.gridContainer}
                            >
                                <Grid item xs={12}>
                                    <Grid item xs={12}>
                                        <Typography variant={""}>
                                            RUC
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Controller
                                            name="sRUT"
                                            control={control}
                                            rules={{
                                                required: "El RUC es requerido",
                                                /* pattern: {
                                                    value: validation.rut,
                                                    message: "RUT invalido",
                                                }, */
                                            }}
                                            render={({
                                                field: {
                                                    onChange,
                                                    onBlur,
                                                    value,
                                                    ref,
                                                },
                                                fieldState,
                                            }) => {
                                                return (
                                                    <TextField
                                                        error={
                                                            fieldState.error
                                                                ? true
                                                                : false
                                                        }
                                                        className={
                                                            styles.inputs
                                                        }
                                                        ref={ref}
                                                        onBlur={onBlur}
                                                        onChange={onChange}
                                                        value={value}
                                                        helperText={
                                                            fieldState.error
                                                                ? fieldState
                                                                    .error
                                                                    .message
                                                                : null
                                                        }
                                                        placeholder={"RUT"}
                                                        color={
                                                            fieldState.error
                                                                ? "error"
                                                                : "success"
                                                        }
                                                        focused={
                                                            fieldState.isTouched ||
                                                            fieldState.isDirty
                                                        }
                                                        fullWidth
                                                    />
                                                );
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid item xs={12}>
                                        <Typography variant={""}>
                                            Nombres
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Controller
                                            name="sFirstName"
                                            control={control}
                                            rules={{
                                                required:
                                                    "Los nombres son requerido",
                                            }}
                                            render={({
                                                field: {
                                                    onChange,
                                                    onBlur,
                                                    value,
                                                    ref,
                                                },
                                                fieldState,
                                            }) => {
                                                return (
                                                    <TextField
                                                        error={
                                                            fieldState.error
                                                                ? true
                                                                : false
                                                        }
                                                        className={
                                                            styles.inputs
                                                        }
                                                        ref={ref}
                                                        onBlur={onBlur}
                                                        onChange={onChange}
                                                        value={value}
                                                        helperText={
                                                            fieldState.error
                                                                ? fieldState
                                                                    .error
                                                                    .message
                                                                : null
                                                        }
                                                        placeholder={
                                                            "Ingrese Nombres"
                                                        }
                                                        color={
                                                            fieldState.error
                                                                ? "error"
                                                                : "success"
                                                        }
                                                        focused={
                                                            fieldState.isTouched ||
                                                            fieldState.isDirty
                                                        }
                                                        fullWidth
                                                    />
                                                );
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid item xs={12}>
                                        <Typography variant={""}>
                                            Apellidos
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Controller
                                            name="sLastName"
                                            control={control}
                                            rules={{
                                                required:
                                                    "Los apellidos son requerido",
                                            }}
                                            render={({
                                                field: {
                                                    onChange,
                                                    onBlur,
                                                    value,
                                                    ref,
                                                },
                                                fieldState,
                                            }) => {
                                                return (
                                                    <TextField
                                                        error={
                                                            fieldState.error
                                                                ? true
                                                                : false
                                                        }
                                                        className={
                                                            styles.inputs
                                                        }
                                                        ref={ref}
                                                        onBlur={onBlur}
                                                        onChange={onChange}
                                                        value={value}
                                                        helperText={
                                                            fieldState.error
                                                                ? fieldState
                                                                    .error
                                                                    .message
                                                                : null
                                                        }
                                                        placeholder={
                                                            "Ingrese Apellidos"
                                                        }
                                                        color={
                                                            fieldState.error
                                                                ? "error"
                                                                : "success"
                                                        }
                                                        focused={
                                                            fieldState.isTouched ||
                                                            fieldState.isDirty
                                                        }
                                                        fullWidth
                                                    />
                                                );
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid item xs={12}>
                                        <Typography variant={""}>
                                            Correo electrónico
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Controller
                                            name="sEmail"
                                            control={control}
                                            rules={{
                                                required:
                                                    "El Correo es requerido",
                                            }}
                                            render={({
                                                field: {
                                                    onChange,
                                                    onBlur,
                                                    value,
                                                    ref,
                                                },
                                                fieldState,
                                            }) => {
                                                return (
                                                    <TextField
                                                        error={
                                                            fieldState.error
                                                                ? true
                                                                : false
                                                        }
                                                        className={
                                                            styles.inputs
                                                        }
                                                        ref={ref}
                                                        onBlur={onBlur}
                                                        onChange={onChange}
                                                        value={value}
                                                        helperText={
                                                            fieldState.error
                                                                ? fieldState
                                                                    .error
                                                                    .message
                                                                : null
                                                        }
                                                        placeholder={
                                                            "Ingrese correo electrónico"
                                                        }
                                                        color={
                                                            fieldState.error
                                                                ? "error"
                                                                : "success"
                                                        }
                                                        focused={
                                                            fieldState.isTouched ||
                                                            fieldState.isDirty
                                                        }
                                                        fullWidth
                                                    />
                                                );
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid item xs={12}>
                                        <Typography variant={""}>
                                            Número de celular
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Controller
                                            name="sPhone"
                                            control={control}
                                            rules={{
                                                required: "El Número celular es requerido",
                                                validate: {
                                                    validPhoneNumber: (value) => validatePhoneNumber(value) || 'Formato de numero telefonico invalido',
                                                    validateBlanks: (value) => !/\s/g.test(value) || 'No estan permitidos los espacios en blanco'
                                                }
                                                /* pattern: {
                                                    value: validation.phone,
                                                    message:
                                                        "Formato de Número celular invalido",
                                                }, */
                                            }}
                                            render={({
                                                field: {
                                                    onChange,
                                                    onBlur,
                                                    value,
                                                    ref,
                                                },
                                                fieldState,
                                            }) => {
                                                return (
                                                    <TextField
                                                        error={
                                                            fieldState.error
                                                                ? true
                                                                : false
                                                        }
                                                        className={
                                                            styles.inputs
                                                        }
                                                        ref={ref}
                                                        onBlur={onBlur}
                                                        onChange={onChange}
                                                        value={value}
                                                        helperText={
                                                            fieldState.error
                                                                ? fieldState
                                                                    .error
                                                                    .message
                                                                : null
                                                        }
                                                        placeholder={
                                                            "Ingrese número celular"
                                                        }
                                                        color={
                                                            fieldState.error
                                                                ? "error"
                                                                : "success"
                                                        }
                                                        focused={
                                                            fieldState.isTouched ||
                                                            fieldState.isDirty
                                                        }
                                                        fullWidth
                                                    />
                                                );
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography variant={""}>Login</Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <Controller
                                        name="sLogin"
                                        control={control}
                                        rules={{
                                            required: "El Login es requerido",
                                        }}
                                        render={({
                                            field: {
                                                onChange,
                                                onBlur,
                                                value,
                                                ref,
                                            },
                                            fieldState,
                                        }) => {
                                            return (
                                                <TextField
                                                    error={
                                                        fieldState.error
                                                            ? true
                                                            : false
                                                    }
                                                    className={styles.inputs}
                                                    ref={ref}
                                                    onBlur={onBlur}
                                                    onChange={onChange}
                                                    value={value}
                                                    helperText={
                                                        fieldState.error
                                                            ? fieldState.error
                                                                .message
                                                            : null
                                                    }
                                                    placeholder={
                                                        "Ingrese el login"
                                                    }
                                                    color={
                                                        fieldState.error
                                                            ? "error"
                                                            : "success"
                                                    }
                                                    focused={
                                                        fieldState.isTouched ||
                                                        fieldState.isDirty
                                                    }
                                                    fullWidth
                                                />
                                            );
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography variant={""}>
                                        Password
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        name="sPassword"
                                        control={control}
                                        rules={{
                                            required:
                                                "El password es requerido",
                                        }}
                                        render={({
                                            field: {
                                                onChange,
                                                onBlur,
                                                value,
                                                ref,
                                            },
                                            fieldState,
                                        }) => {
                                            return (
                                                <TextField
                                                    error={
                                                        fieldState.error
                                                            ? true
                                                            : false
                                                    }
                                                    className={styles.inputs}
                                                    ref={ref}
                                                    onBlur={onBlur}
                                                    onChange={onChange}
                                                    value={value}
                                                    helperText={
                                                        fieldState.error
                                                            ? fieldState.error
                                                                .message
                                                            : null
                                                    }
                                                    placeholder={
                                                        "Ingrese el password"
                                                    }
                                                    color={
                                                        fieldState.error
                                                            ? "error"
                                                            : "success"
                                                    }
                                                    focused={
                                                        fieldState.isTouched ||
                                                        fieldState.isDirty
                                                    }
                                                    fullWidth
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </DialogContent>

                    <DialogActions style={{ justifyContent: "center" }}>
                        {restStatus === undefined && (
                            <Stack className={styles.constentDialogActionSend}>
                                <Button
                                    onClick={handleClose}
                                    variant="contained"
                                    className={styles.cancelButton}
                                >
                                    Cancelar
                                </Button>
                            </Stack>
                        )}

                        {restStatus === undefined && (
                            <Stack className={styles.constentDialogActionSend}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    className={styles.goBackButton}
                                >
                                    Registrar
                                </Button>
                            </Stack>
                        )}

                        {restStatus !== undefined && (
                            <Stack
                                className={styles.constentDialogActionGoBack}
                            >
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        handleClose();
                                    }}
                                    className={styles.goBackButton}
                                >
                                    {texButtonGoback}
                                </Button>
                            </Stack>
                        )}
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};

export default ModalAddUser;
