import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import FilePreview from "react-file-preview-latest";

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { ReactComponent as EyeIcon } from "../../img/eye-icon.svg";

import { verifiVariable } from "../../../../../utils/enums";
import { downloadCompiledContractDoc } from "../../../../../utils/api";



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    bgcolor: 'background.paper',
    boxShadow: '0px 4px 11px 5px rgba(0, 79, 159, 0.04)',
    borderRadius: '7px',
    p: 4
};

const ViewModalDocs = ({ loading, textLoading }) => {
    const {
        contract: {
            contractCreator: {
                contractCreatedDetails
            }
        },
        session: {
            login: {
                user
            }
        }
    } = useSelector((state) => state);
    const { state } = useLocation();
    const [detailContract, setDetailContract] = useState(undefined);
    const [filePreview, setFilePreview] = useState(undefined);
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
        downloadContract();
    };
    const handleClose = () => setOpen(false);


    const downloadContract = async () => {
        try {
            const { token } = user;
            loading(true);
            textLoading('Cargando documento');

            const response = await downloadCompiledContractDoc(
                state,
                token,
                true
            );

            setFilePreview(response);

            loading(false);
        } catch (error) {
            loading(false);
            console.log(error);
        }
    };


    useEffect(() => {
        if (!verifiVariable(contractCreatedDetails)) setDetailContract(contractCreatedDetails);
    }, [contractCreatedDetails]);

    return (

        <Fragment>

            {(!verifiVariable(detailContract) && detailContract['contrato'][0]['withDocument'] === 1) && (
                <Tooltip
                    title={
                        <Typography variant="subtitle1" color={['white.main']} gutterBottom>
                            Ver contrato
                        </Typography>
                    }
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 600 }}
                    arrow
                >
                    <div>
                        <EyeIcon onClick={() => handleOpen()} />
                    </div>
                </Tooltip>)}

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>

                    <Box sx={style}>

                        {filePreview && (<FilePreview
                            type={"file"}
                            file={filePreview}
                            onError={() => console.log('error')}
                        />)}

                    </Box>

                </Fade>

            </Modal>

        </Fragment>

    );

}

export default ViewModalDocs;