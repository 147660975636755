import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';
import { BsEye } from 'react-icons/bs';

import { to } from '../../../../../utils/enums';


const RenderActions = ({ row, id }) => {
    const navigate = useNavigate();


    return (

        <Box sx={{
            position: "relative",
            display: "flex",
            alignContent: 'center'
        }} className="flex--1 flex--center">

            <Box sx={{padding: "6px", marginTop: "6px"}} onClick={()=>navigate(to.CONTRACT_DETAIL, {state: id})}>
                <BsEye className="icon--xs icon-pointer icon--blue-light" />
            </Box>

            {/* <ModalContractDelete contract={row}  /> */}

        </Box>
    );

};

export default RenderActions;