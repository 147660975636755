import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import { FaSearch } from "react-icons/fa";

import { ReactComponent as Logo } from "../../../assets/logo/logo.svg";
import { getUserInfo } from "../../storeSelector/storeSelector";
import { AppBarMenuStyles } from "./AppBarMenu.css";
import PopupStateNotifications from "../../common/notifications";
import ScreenSizeHook from "../../../hooks/ScreenSizeHook";
import WhatsappIconSpeedDial from "../../whatsappIconSpeedDial";

const notifications = [
    {
        title: "Titulo notificacion",
        date: "10/10/2022",
        description: "Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500..",
        read: false
    },
    {
        title: "Titulo notificacion",
        date: "10/10/2022",
        description: "Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500..",
        read: false
    },
    {
        title: "Titulo notificacion",
        date: "10/10/2022",
        description: "Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500..",
        read: true
    },
    {
        title: "Titulo notificacion",
        date: "10/10/2022",
        description: "Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500..",
        read: false
    },
    {
        title: "Titulo notificacion",
        date: "10/10/2022",
        description: "Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500..",
        read: true
    }
];

const AppBarMenu = ({ appBarHeight }) => {
    const styles = AppBarMenuStyles();
    const screenSizeHook = ScreenSizeHook();
    const userInfo = getUserInfo();

    return (
        <Box sx={{ height: appBarHeight }} className={styles.mainContent}>

            <Logo className="logo-size--xs" />

            <Box className={styles.rightBlock}>

                {!['xs'].includes(screenSizeHook) && (<Box className={styles.iconsBlock}>

                    <FaSearch style={{ marginRight: 10 }} className={"search-icon icon--xs icon--grey-dark"} />

                    <PopupStateNotifications notifications={notifications} />

                </Box>)}

                <Box className={styles.userInfoContent}>

                    <WhatsappIconSpeedDial screen={screenSizeHook} />

                    {!['xs'].includes(screenSizeHook) && (<Box className={styles.personInfoContent}>
                        <strong className={styles.userName}>{`${userInfo.firstName} ${userInfo.lastName}`}</strong>
                        <span className={styles.userRol}>Administrator</span>
                    </Box>)}

                    <Avatar alt="User name" />
                </Box>

            </Box>

        </Box>
    );
}

export default AppBarMenu;