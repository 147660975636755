import { combineReducers } from "redux";
import contractDetailReducer from "./contractDetail";
import signatoriesReducer from "./signatoriesList";
import tramitesReducer from "./tramites";

export default combineReducers({
    contractCreator: contractDetailReducer,
    signatories: signatoriesReducer,
    tramites: tramitesReducer
});
