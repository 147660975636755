import RenderActions from "../../atoms/renderActions";
import RenderCell from "../../atoms/renderCell";

const localizedTextsMap = {
    columnMenuUnsort: "Sin ordenar",
    columnMenuSortAsc: "De menor a mayor",
    columnMenuSortDesc: "De mayor a menor",
    columnMenuFilter: "Filtro",
    columnMenuHideColumn: "Ocultar",
    columnMenuShowColumns: "Mostrar columnas",
    toolbarExport: "Descargar",
    toolbarFilters: "Filtros",
    filterOperatorContains: "Contiene",
    filterOperatorEquals: "Igual a",
    filterOperatorStartsWith: "Empieza con",
    filterOperatorEndsWith: "Termina con",
    filterOperatorIsEmpty: "Esta vacio",
    filterOperatorIsNotEmpty: "No esta vacio",
    filterPanelInputLabel: "Valor",
    filterPanelInputPlaceholder: "Filtrar por valor",
};

const gridColumns = [
    {
        field: "rut",
        headerAlign: "center",
        headerName: "RUC",
        headerClassName: "grid__header-item",
        cellClassName: "grid__row-item text--uppercase-all text--center",
        width: 130,
        renderCell: (params) => <RenderCell item={params.value} />
    },
    {
        field: "notaryName",
        headerAlign: "center",
        headerName: "Nombre de la notaría",
        headerClassName: "grid__header-item",
        cellClassName: "grid__row-item text--uppercase text--center",
        width: 210,
        renderCell: (params) => <RenderCell item={params.value} />
    },
    {
        field: "ownerName",
        headerAlign: "center",
        headerName: "Responsable",
        headerClassName: "grid__header-item",
        cellClassName: "grid__row-item text--uppercase text--center",
        width: 210,
        renderCell: (params) => <RenderCell item={params.value} />
    },
    {
        field: "phone",
        headerAlign: "center",
        headerName: "Teléfono",
        headerClassName: "grid__header-item",
        cellClassName: "grid__row-item",
        width: 120,
        renderCell: (params) => <RenderCell item={params.value} />
    },
    {
        field: "email",
        headerAlign: "center",
        headerName: "Correo",
        headerClassName: "grid__header-item",
        cellClassName: "grid__row-item text--center",
        width: 270,
        renderCell: (params) => <RenderCell item={params.value} />
    },
    {
        field: "rut as actions",
        headerAlign: "center",
        headerName: "Acciones",
        headerClassName: "grid__header-item",
        cellClassName: "grid__row-item text--center",
        width: 140,
        disableColumnMenu: true,
        renderCell: (params) => <RenderActions row={params.row} />
    }
];

export {
    gridColumns,
    localizedTextsMap
};