import { forwardRef, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';

import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import * as PdfjsLib from 'pdfjs-dist';

import Text from "../../heading/Text";
import { AddSignaturesStyles } from "./index.css";
import { asyncFileToBase64, randomRgba, to, verifiVariable } from "../../../utils/enums";
import AddSign from "./molecules/addSign";
import DraggableBox from "./molecules/draglableBox";
import SignatoryAlert from "./molecules/signatoryAlert";
import { saveFormInfo } from "../../../store/formTramit";
import { Context } from "../../../context/utilsContext";

import { ReactComponent as MissingSignatories } from "./img/aun_no_se_han_agregado_firmantes.svg";
import ScreenSizeHook from "../../../hooks/ScreenSizeHook";
import ErrorAlert from "./molecules/errorAlert";

const CustomTextControls = styled(Typography)((props) => ({
    color: props.theme.palette.black.main,
    fontWeight: 100,
    marginLeft: '25px',
    marginRight: '25px'
}));

const CustomSnackBar = styled(Snackbar)(({ theme }) => ({
    '& .MuiSnackbarContent-root': {
        backgroundColor: 'transparent',
        boxShadow: 'none'
    }
}));

// eslint-disable-next-line
const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const buildId = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
};

const AddSignatures = () => {
    const screenSizeHook = ScreenSizeHook();
    const { nextPage, setNextPage, toResumen, setToResumen } = useContext(Context);
    const [snackbarOpen, setSnackbar] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // eslint-disable-next-line
    const [loading, setLoading] = useState(false);
    const styles = AddSignaturesStyles();
    const {
        formTramit: {
            thirdStep: {
                fileName,
                docPdfBase64
            },
            secondStep,
            firms: firmStaore
        }
    } = useSelector((state) => state);
    // eslint-disable-next-line
    const [fileBase64, setFileBase64] = useState('data:application/pdf;base64,' + docPdfBase64);
    const [docPdf, setDocPdf] = useState(null);
    const [firms, setFirms] = useState(firmStaore);
    // eslint-disable-next-line
    const [zoom, setZoom] = useState(1);
    const refCanvas = useRef();
    const refPdfViewer = useRef();
    const refInputControl = useRef();
    const [openSigModal, setOpenSigModal] = useState(false);
    const [layerX, setLayerX] = useState(0);
    const [layerY, setLayerY] = useState(0);
    const [documentFail, setDocumentFail] = useState(0);
    const [missingSigners, setMissingSigners] = useState([]);
    // eslint-disable-next-line
    const [list, setList] = useState(secondStep.map((item) => ({ ...item, textColor: randomRgba() })).filter((item) => item['rol'].includes('Firmante')));

    const { watch, setValue } = useForm({
        defaultValues: {
            currentPage: 1
        }
    });

    let currentPage = watch('currentPage');

    const handleClickOpenSnack = () => {
        setSnackbar(true);

        setTimeout(() => {
            setSnackbar(false);
        }, 2500);
    }

    const handleCloseSnack = () => {
        setSnackbar(false);
    }

    const goPrevious = () => {
        if (docPdf === null || currentPage === 1) return;
        setValue('currentPage', (currentPage) -= 1);
    }

    const goNext = () => {
        if (docPdf == null || currentPage >= docPdf._pdfInfo.numPages) return;
        setValue('currentPage', (currentPage) += 1);
    }

    const ControlsCustomButtom = styled(Button)((props) => ({
        borderRadius: '7px',
        background: props.theme.palette.primary.light,
        marginTop: '11px',
        marginBottom: '20px',
        padding: '14px 22px',
        '&:hover': {
            background: props.theme.palette.primary.light
        },
        '&:disabled': {
            background: props.theme.palette.gray.dark,
            color: props.theme.palette.white.main
        },
        fontFamily: [
            '"PoppinsBold"'
        ].join(',')
    }));

    const asyncPdfForSignrequest = () => {

        return new Promise(async (resolve, reject) => {

            try {

                if ([null, undefined, ''].includes(fileBase64)) return;

                const dataFirms = { ...firms };

                const pdfDoc = await PDFDocument.load(fileBase64, { ignoreEncryption: true });

                // Embed the Helvetica font
                const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

                // Get the first page of the document
                const pages = pdfDoc.getPages();

                Object.keys(dataFirms).forEach((item) => {

                    const signedPage = pages[(dataFirms[item]['page']) - 1];

                    // Draw a string of text diagonally across the first page
                    signedPage.drawText(dataFirms[item]['text'], Object.assign({ ...dataFirms[item] }, { font: helveticaFont, color: rgb(1, 1, 1) }));

                });

                const pdfBytes = await pdfDoc.save();

                const file = new File([pdfBytes], fileName, { type: 'application/pdf' });

                resolve(file);

            } catch (error) {
                reject(error);
            }

        });

    }

    const asyncPdfForPreview = () => {

        return new Promise(async (resolve, reject) => {

            try {

                if ([null, undefined, ''].includes(fileBase64)) return;

                const dataFirms = { ...firms };

                const pdfDoc = await PDFDocument.load(fileBase64, { ignoreEncryption: true });

                // Embed the Helvetica font
                const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

                // Get the first page of the document
                const pages = pdfDoc.getPages();

                Object.keys(dataFirms).forEach((item) => {

                    const signedPage = pages[(dataFirms[item]['page']) - 1];

                    // Draw a string of text diagonally across the first page
                    signedPage.drawText(dataFirms[item]['textTootip'], Object.assign({ ...dataFirms[item] }, { font: helveticaFont }));

                });

                const pdfBytes = await pdfDoc.save();

                const file = new File([pdfBytes], fileName + '-preview-firmas', { type: 'application/pdf' });

                resolve(file);

            } catch (error) {
                reject(error);
            }

        });

    }

    const goPreviewData = async (url) => {

        try {

            setLoading(true);

            const reqPdfForSignrequest = await asyncPdfForSignrequest();

            const reqPdfForPreview = await asyncPdfForPreview();

            const restBase64ForSignrequest = await asyncFileToBase64(reqPdfForSignrequest);

            dispatch(saveFormInfo({ indexe: 'fourthStep', value: restBase64ForSignrequest }));

            const restBase64ForPreview = await asyncFileToBase64(reqPdfForPreview);

            dispatch(saveFormInfo({ indexe: 'previewPdfSigned', value: restBase64ForPreview }));

            dispatch(saveFormInfo({ indexe: 'firms', value: firms }));

            navigate(to.CONTRACT_CREATION_DATA_PREVIEW);

            setLoading(false);

        } catch (error) {
            setLoading(false);
            setDocumentFail((1 + documentFail));
            console.error(error);
        }

    }

    const verifySigners = (url) => {

        const addedSignatures = Object.values(firms);
        const missing = [];

        list.forEach(element => {

            const findItem = addedSignatures.find(firm => firm['rut'] === element['rutId']);

            if (!findItem) missing.push(element['full_name']);

        });

        if (addedSignatures.length === 0) {
            handleClickOpenSnack();
        } else if (missing.length !== 0) {
            setMissingSigners(missing);
        } else {
            goPreviewData(url);
        }

    }

    const boxDelete = (id) => {

        const objectSignet = { ...firms };

        delete objectSignet[id];

        setFirms(objectSignet);
    }

    const addFirmante = (signer) => {

        if ([null, undefined, ''].includes(signer)) return;

        const parseSigner = JSON.parse(signer);

        setFirms((itemList) => Object.assign({
            ...itemList,
            [buildId(4)]: {
                numbSigned: parseSigner['sign'] + 1,
                text: `[[s|${parseSigner['sign']}]]`,
                textTootip: parseSigner['full_name'],
                rut: parseSigner['rutId'],
                x: layerX,
                y: layerY,
                size: 20,
                textColor: parseSigner['textColor'],
                page: currentPage,
                rotate: degrees(0)
            }
        }));

    }

    const openModal = (e) => {
        const element = e;

        const nodeName = element['target']['nodeName'];

        if (!['CANVAS'].includes(nodeName)) return;

        setLayerX(element['nativeEvent']['layerX']);

        setLayerY(element['nativeEvent']['layerY']);

        setOpenSigModal(true);
    }

    useEffect(() => {
        console.log('screenSizeHook: ', screenSizeHook);//TODO bloquear asignacion de firma en monitores con dimenciones de md hacia abajo
    }, [screenSizeHook]);

    useEffect(() => {

        if (nextPage !== 0) verifySigners(to.CONTRACT_CREATION_DATA_PREVIEW);

        return () => {
            setNextPage(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nextPage]);

    useEffect(() => {

        if (!verifiVariable(toResumen)) verifySigners(toResumen);

        return () => {
            setToResumen(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toResumen]);

    useEffect(() => {

        (async () => {

            if (![null, undefined, ''].includes(docPdfBase64)) {

                const loadPdfDocumentTask = PdfjsLib.getDocument('data:application/pdf;base64,' + docPdfBase64);

                const pdf = await loadPdfDocumentTask.promise;

                setDocPdf(pdf);

            }

        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [docPdfBase64]);

    useEffect(() => {

        (async () => {

            const parsetCurrentPage = parseInt(currentPage);

            if ([null, undefined, ''].includes(docPdf) || [0, null, undefined].includes(parsetCurrentPage) || parsetCurrentPage > docPdf._pdfInfo.numPages) return;

            let page = await docPdf.getPage(parsetCurrentPage);

            let canvas = refCanvas.current;
            let ctx = canvas.getContext('2d');
            let viewport = page.getViewport({ scale: zoom });

            canvas.width = viewport.width;
            canvas.height = viewport.height;
            page.render({
                canvasContext: ctx,
                viewport: viewport
            });

            viewport = page.getViewport({ scale: zoom });

            window['jQuery'](refPdfViewer.current).width(viewport.width).height(viewport.height);

            window['jQuery'](refInputControl.current).width(viewport.width - (151.5 * 2));

        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [docPdf, currentPage]);

    return (

        <div className={styles.stepBox}>

            <CustomSnackBar
                style={{
                    '& .MuiSnackbarContent-root': {
                        all: 'initial',
                        padding: '10px'
                    }
                }}
                anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
                open={snackbarOpen}
                onClose={handleCloseSnack}
                message={<MissingSignatories />}
            />

            <div className={styles.titleContainer}>

                <br />

                <Text className={styles.titleStyles} align="center">
                    Haz click en el documento para agregar los firmantes
                </Text>

            </div>

            <Grid container xs={12}>

                <Grid item={true} xs={11}>

                    <div className={styles.mainContainer}>

                        <SignatoryAlert
                            agree={() => goPreviewData(to.CONTRACT_CREATION_DATA_PREVIEW)}
                            missingSigners={missingSigners}
                        />

                        <ErrorAlert errors={documentFail} />

                        <AddSign
                            key={'flkafkje'}
                            openSigModal={openSigModal}
                            setOpenSigModal={(e) => setOpenSigModal(e)}
                            signatory={(e) => addFirmante(e)}
                            signatories={list}
                        />

                        <div id="my_pdf_viewer" className={styles.pdfViewer} ref={refPdfViewer}>

                            <div id="canvas_container" className={styles.canvasContainer} onClick={(e) => openModal(e)}>

                                {Object.keys(firms).map((item) => {

                                    return firms[item]['page'] === currentPage && (<DraggableBox
                                        key={item}
                                        positions={(element) => setFirms((itemList) => Object.assign({ ...itemList }, { [item]: element }))}
                                        deleteBox={() => boxDelete(item)}
                                        refPdfViewer={refPdfViewer}
                                        signatory={firms[item]}
                                    />)

                                })}

                                <canvas id="pdf_renderer" ref={refCanvas}></canvas>

                            </div>

                            <div id="navigation_controls" className={styles.navigationControls}>

                                <ControlsCustomButtom
                                    disabled={currentPage === 1}
                                    onClick={() => goPrevious()}
                                    variant="contained"
                                    disableElevation
                                >
                                    Página Anterior
                                </ControlsCustomButtom>

                                <CustomTextControls variant="h6" gutterBottom>
                                    {currentPage}-{docPdf ? docPdf._pdfInfo.numPages : 0}
                                </CustomTextControls>

                                <ControlsCustomButtom
                                    disabled={[null, undefined, ''].includes(docPdf) ? false : (currentPage === docPdf._pdfInfo.numPages)}
                                    onClick={() => goNext()}
                                    variant="contained"
                                    disableElevation
                                >
                                    Página Siguiente
                                </ControlsCustomButtom>

                            </div>

                        </div>

                    </div>

                </Grid>

            </Grid>

        </div>
    );
}

export default AddSignatures;