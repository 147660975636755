import React from 'react';
import { FaRegClock, FaCheck } from "react-icons/fa";

const Statustrackeritem = ({ title, focused, onClick, sStatus }) => {

    const displayStatus = () => {

        if (sStatus === 'REJECTED') {
            return "status--notdone";
        } else if (focused === true) {
            return "status--approved";
        } else if (focused === false) {
            return "status--inprogress";
        } else {
            return "status--notdone";
        }

    }

    const displayIcon = () => {

        if (sStatus === 'REJECTED') {
            return <FaRegClock className="icon--xxs" />
        } else if (focused === true) {
            return <FaCheck className="icon--xxs" />
        } else if (focused === false) {
            return <FaRegClock className="icon--xxs" />
        } else {
            return <FaRegClock className="icon--xxs" />
        }

    }

    const displayTitleStyle = () => {

        if (sStatus === 'REJECTED') {
            return "breadcrum-item--unfocus";
        } else if (focused === true) {
            return "breadcrum-item--focus";
        } else if (focused === false) {
            return "breadcrum-item--unfocus";
        } else {
            return "breadcrum-item--unfocus";
        }

    }

    const displayStatusStyle = () => {

        if (sStatus === 'REJECTED') {
            return "status-text--pending";
        } else if (focused === true) {
            return "status-text--approved";
        } else if (focused === false) {
            return "status-text--inprogress";
        } else {
            return "status-text--pending";
        }

    }

    const displayStatusText = () => {

        if (sStatus === 'REJECTED') {
            return 'Rechazado'
        } else if (focused === true) {
            return "Aprobado";
        } else if (focused === false) {
            return "En proceso";
        } else {
            return "Por realizar";
        }

    }

    return (
        <li className="flex--start status-tracker-item__container">
            <div className={`status-tracker__icon-container ${displayStatus()}`}>{displayIcon()}</div>
            <div className="flex--start flex--column flex--align-start">
                <strong className={`font-size--xxs ${displayTitleStyle()}`} onClick={onClick}>{title}</strong>
                <strong className={`font-size--xxs ${displayStatusStyle()}`}>{displayStatusText()}</strong>
            </div>
        </li>
    );
}

export default Statustrackeritem;
