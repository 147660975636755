import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";

import Grid from '@mui/material/Grid';

import { PopUp, Text, RecoverAccountForm } from "../../components";
import { ReactComponent as Logo } from "../../assets/logo/logo.svg";
import { ReactComponent as PaperPlane } from "../../assets/icons/paperplane.svg";
import { bodyTextStyles } from "../../utils/validations";
import ScreenSizeHook from "../../hooks/ScreenSizeHook";
// import WhatsappIconSpeedDial from "../../components/whatsappIconSpeedDial";
// import HelpIconSpeedDial from "../../components/helpIconSpeedDial";

export default function RecoverAccount() {
    const screenSizeHook = ScreenSizeHook();
    const [showModal, setShowModal] = useState(false);

    const displayModal = () => {
        return (
            <PopUp
                open={showModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                title="Mensaje enviado"
                icon={<PaperPlane className="icon--cover" />}
                onBackdropClick={() => setShowModal(!showModal)}
            >
                <Text
                    sx={bodyTextStyles}
                    align="center"
                    variant="body1"
                    paragraph
                >
                    Revisa tu correo con las indicaciones para recuperar tu
                    contraseña.
                </Text>
            </PopUp>
        );
    };

    return (
        <Fragment>

            <div className="login-wrapper">

                <div className="start-session__container">

                    <div className="start-session-content__container flex--center">                        

                        <Grid container>

                            <Grid item xs={12} sx={{ marginRight: 2, marginLeft: 2 }}>

                                <Link to="/">
                                    <Logo className="logo-size--md" style={{ width: '100%' }} />
                                </Link>

                            </Grid>

                        </Grid>

                        <h1 className="title--head text-align--center title">
                            ¿Has olvidado tu contraseña?
                        </h1>

                        <RecoverAccountForm />

                    </div>
                </div>

                {!['xs', 'sm'].includes(screenSizeHook) && (<div className="showcase__container login-bg" />)}

            </div>

            {displayModal()}

            {/* <WhatsappIconSpeedDial /> */}

            {/* <HelpIconSpeedDial /> */}

        </Fragment>
    );
}
