import { Box } from "@mui/system";
import React from "react";
import { Text } from "../../../..";
import { styles } from "../../../../../helpers";

const RenderContractDetail = ({ data: { sContractID, sTipoContrato } }) => {
    
    const customBox = {
        display: "flex",
    }
    
    const customSub = {
        fontSize: "12px",
        color: "quinary.dark"
    }

    return (
        <Box sx={[styles.userAvatarContainerStyles, customBox]}>
            <Box sx={styles.userNameContainerStyles}>
                <Text sx={[styles.subTitleStyles, customSub]} variant="h6">
                    {sContractID}
                </Text>
                <Text sx={styles.userNameStyles} variant="h6">
                    {sTipoContrato}
                </Text>
            </Box>
        </Box>
    );
};

export default RenderContractDetail;
