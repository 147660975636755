import React, { useState, useEffect, Fragment } from "react";
import { Link, useNavigate, Outlet } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import _ from "lodash";

import { PopUp, Text, Btn, SignUpAccountForm } from "../../components";
import { store } from "../../store/storeConfig";
import { ReactComponent as Logo } from "../../assets/logo/logo.svg";
import { ReactComponent as MailNotificAlert } from "../../assets/icons/mail-notific-alert.svg";
import { apiMessage, to, verifiVariable } from "../../utils/enums";
import { resetSignup } from "../../store/auth/userSignUp";
import { bodyTextStyles } from "../../utils/validations";
import ScreenSizeHook from "../../hooks/ScreenSizeHook";
import { FaRegSadCry } from "react-icons/fa";
import { useSelector } from "react-redux";
// import HelpIconSpeedDial from "../../components/helpIconSpeedDial";
// import WhatsappIconSpeedDial from "../../components/whatsappIconSpeedDial";

const CustomButton = styled(Button)((props) => ({
    fontStyle: "normal",
    boxShadow: "none",
    fontWeight: "bolder",
    textTransform: "none",
    fontSize: 16,
    color: "white",
    padding: "11px 28px",
    border: "1px solid",
    borderRadius: "7px",
    lineHeight: 1.5,
    width: "90%",
    alignSelf: "center",
    backgroundColor: props.theme.palette.primary.light,
    // backgroundColor: '#0063cc',
    borderColor: props.theme.palette.primary.light,
    fontFamily: ['"PoppinsBold"'].join(","),
    "&:hover": {
        // backgroundColor: '#0069d9',
        backgroundColor: props.theme.palette.primary.xlight,
        // borderColor: '#0062cc',
        borderColor: props.theme.palette.primary.xlight,
        boxShadow: "none",
    },
    "&:active": {
        boxShadow: "none",
        backgroundColor: "#0062cc",
        borderColor: "#005cbf",
    },
    "&:focus": {
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
}));

const CustomText = styled(Typography)((props) => ({
    fontFamily: ['"PoppinsBold"'].join(","),
}));

export default function SignUpAccount() {
    const screenSizeHook = ScreenSizeHook();
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [errorOnSignUp, setErrorOnSignUp] = useState(false);
    const [modalIcon, setModalIcon] = useState();
    const [modalTitle, setModalTitle] = useState("");
    // eslint-disable-next-line
    const [modalMsg, setModalMsg] = useState("");
    const [modalButtonTitle, setModalButtonTitle] = useState("");
    const [error, setError] = useState(false);
    const [emailDominio, setEmailDominio] = useState(undefined);
    const [urlTab, setUrlTab] = useState("");

    const {
        session: { signup },
    } = useSelector((state) => state);

    const formatUrl = (searchString) => {
        switch (searchString) {
            case "@gmail":
                setUrlTab("https://mail.google.com");
                break;

            case "@proton":
                setUrlTab("https://account.proton.me");
                break;

            case "@outlook":
                setUrlTab("https://login.live.com");
                break;

            case "@hotmail":
                setUrlTab("https://login.live.com");
                break;

            case "@mailbox":
                setUrlTab("https://login.mailbox.org");
                break;

            default:
                setUrlTab(`mailto:${emailDominio}`);

                break;
        }
    };

    useEffect(() => {
        (() => {
            if (!_.isEmpty(signup)) {
                if (signup.status === apiMessage.SUCCESS) {
                    setError(false);
                    setErrorOnSignUp(false);
                    setModalTitle("¡Listo! Revisa tu correo electrónico");
                    setModalMsg("");
                    setModalIcon(<MailNotificAlert />);
                    setModalButtonTitle("Iniciar Sessión");
                    setShowModal(true);

                    setEmailDominio(signup["message"][0]["userApp"]["sEmail"]);

                    [
                        "@gmail",
                        "@proton",
                        "@outlook",
                        "@hotmail",
                        "@mailbox",
                    ].forEach((word) => {
                        const findWord =
                            signup["message"][0]["userApp"]["sEmail"].search(
                                word
                            );

                        if (findWord !== -1) formatUrl(word);
                    });
                }

                /* if (signup.status === apiMessage.FAIL) {
                    setErrorOnSignUp(true);
                    setModalTitle("¡Ups!, Algo ha pasado");
                    setModalMsg(10);
                    setModalButtonTitle("Volver a intentarlo");
                    setShowModal(true);
                } */

                if (signup.error) {
                    setError(true);
                    setErrorOnSignUp(true);
                    setModalTitle("¡Ups!, Algo ha pasado");
                    setModalMsg(signup.error);
                    setModalIcon(
                        <FaRegSadCry
                            fontSize="inherit"
                            size={150}
                            className="icon--red"
                            style={{ marginTop: "10px" }}
                        />
                    );
                    setModalButtonTitle("Volver a intentarlo");
                    setShowModal(true);
                }
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signup]);

    // eslint-disable-next-line
    const messageModalError = (code) => {
        switch (code) {
            case 440:
                return "El correo electrónico no es válido. Por favor, asegúrate de ingresar una dirección de correo válida.";
            case 441:
                return "El RUC no es válido. Asegúrate de ingresar un RUC válido.";
            case 443:
                return "El correo electrónico ya existe. Por favor, utiliza un correo electrónico diferente.";
            case 444:
                return "El RUC ya existe. Por favor, utiliza un RUC diferente.";
            case 446:
                return "El nombre no es válido. Asegúrate de ingresar un nombre válido, con un máximo de 30 caracteres.";
            case 447:
                return "El apellido no es válido. Asegúrate de ingresar un apellido válido, con un máximo de 30 caracteres.";
            case 449:
                return "La contraseña no es válida. Por seguridad, la contraseña debe tener al menos 8 caracteres alfanuméricos. Inténtalo nuevamente.";
            case 451:
                return "El RUC es obligatorio. Por favor, ingresa tu RUC.";
            case 452:
                return "El correo electrónico es obligatorio. Por favor, ingresa tu correo electrónico.";
            case 454:
                return "La contraseña es obligatoria. Por favor, ingresa una contraseña.";
            case 455:
                return "El teléfono es obligatorio. Por favor, ingresa tu número de teléfono.";
            case 456:
                return "El número de teléfono no es válido. Asegúrate de ingresar un número de teléfono válido.";
            case 10:
                return "Se produjo un error al realizar tu registro. Por favor, inténtalo de nuevo más tarde. Contacte con nuestro soporte";
            case 20:
                return "Valida tu registro ingresando el enlace que te enviamos a tu correo electrónico. Si no recibiste el correo, por favor revisa la carpeta de spam o ponte en contacto con nuestro equipo de soporte.";
            case null:
                return "";
            default:
                return "Ha ocurrido un error. Por favor, inténtalo de nuevo más tarde.";
        }
    };

    const displayModal = () => {
        return (
            <PopUp
                open={showModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                // title={modalTitle}
                icon={modalIcon}
                onBackdropClick={() => handleModal}
                // style={{ width: "465px", height: "500px" }}
                height={"370px"}
            >
                <CustomText fontSize={"1rem"}>{modalTitle}</CustomText>

                <Text
                    sx={bodyTextStyles}
                    align="center"
                    variant="body1"
                    paragraph
                >
                    {!verifiVariable(signup)
                        ? messageModalError(signup.code)
                        : ""}
                </Text>

                {!error && (
                    <CustomText
                        fontSize={"0.8rem"}
                        fontStyle={"normal"}
                        color={["gray.main"]}
                        fontWeight={"100"}
                        variant="h6"
                        lineHeight={"1.3"}
                        marginBottom={"45px"}
                    >
                        Para completar el proceso, por favor active su cuenta
                        haciendo clic en el enlace que le enviamos a su correo
                        electrónico. Una vez activada, podrá iniciar sesión en
                        la plataforma. Si no completa este paso, no podrá
                        acceder a su cuenta.
                    </CustomText>
                )}

                {/* {!error && (
                    <CustomText
                        fontSize={"0.8rem"}
                        fontStyle={"normal"}
                        color={["gray.main"]}
                        fontWeight={"100"}
                        variant="h6"
                        lineHeight={"1.3"}
                    >
                        Haz clic aquí para confirmar su correo electrónico
                    </CustomText>
                )} */}

                {error ? (
                    <Btn
                        variant="contained"
                        text={modalButtonTitle}
                        size="lg"
                        color="primary"
                        type="buttom"
                        buttonClass="button-submit"
                        onClick={handleModal}
                        fullWidth
                        sx={{ marginTop: "20px" }}
                    />
                ) : (
                    <CustomButton type="submit" onClick={() => handleModal()}>
                        {modalButtonTitle}
                    </CustomButton>
                )}
            </PopUp>
        );
    };

    const handleModal = () => {
        if (errorOnSignUp) {
            setModalTitle("");
            setModalMsg("");
            setModalButtonTitle("");
            setShowModal(false);
            store.dispatch(resetSignup());
        } else {
            store.dispatch(resetSignup());
            navigate(to.HOME);
        }
    };

    return (
        <Fragment>
            <div className="login-wrapper">
                <div className="start-session__container">
                    <div className="start-session-content__container flex--center flex--column">
                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                sx={{ marginRight: 2, marginLeft: 2 }}
                            >
                                <Link to="/">
                                    <Logo
                                        className="logo-size--md"
                                        style={{ width: "100%" }}
                                    />
                                </Link>
                            </Grid>
                        </Grid>

                        <h1 className="title--head text-align--center title">
                            La aventura comienza aquí
                        </h1>

                        <Outlet />

                        <SignUpAccountForm />
                    </div>
                </div>

                {!["xs", "sm"].includes(screenSizeHook) && (
                    <div className="showcase__container login-bg" />
                )}
            </div>

            {displayModal()}

            {/* <WhatsappIconSpeedDial /> */}

            {/* <HelpIconSpeedDial /> */}
        </Fragment>
    );
}
