import { createSlice } from "@reduxjs/toolkit";
import { apiCallStart } from "../actions/api";

const slice = createSlice({
    name: "agreement",
    initialState: {
        loading: false,
        agreementList: [],
        message: null,
        code: null,
        status: null
    },
    reducers: {

        CLEAR_ALL: (state, action) => {
            state.agreementList = [];
        },

        SAVE_LIST_AGREEMENT: (state, action) => {
            state.agreementList = action.payload;
        },

        AGREEMENT_LIST_REQUEST: (state, action) => {
            state.loading = true;
            state.status = null;
            state.code = null;
            state.message = null;
        },

        AGREEMENT_LIST_SUCCESS: (state, action) => {
            state.agreementList = action.payload['message'];
            state.status = action.payload['status'];
            state.code = action.payload['code'];
            state.loading = false;
            state.message = null;
        },

        AGREEMENT_LIST_FAIL: (state, action) => {
            state.message = action.payload['message'];
            state.status = action.payload['status'];
            state.code = action.payload['code'];
            state.loading = false;
        }
    },
});

export const {
    CLEAR_ALL,
    SAVE_LIST_AGREEMENT,
    AGREEMENT_LIST_REQUEST,
    AGREEMENT_LIST_SUCCESS,
    AGREEMENT_LIST_FAIL
} = slice.actions;

export const clearAll = () => {
    return { type: CLEAR_ALL.type, payload: null };
};

export const addAgreements = (rest) => {
    return { type: SAVE_LIST_AGREEMENT.type, payload: rest };
};

export const getAgreementsStorage = (token, userId) => {
    return apiCallStart({
        url: `/api/v1/agreement/find-user-id/${userId}`,
        method: "POST",
        data: {},
        onStart: AGREEMENT_LIST_REQUEST.type,
        onSuccess: AGREEMENT_LIST_SUCCESS.type,
        onError: AGREEMENT_LIST_FAIL.type,
        authorization: token
    });
};

export default slice.reducer;