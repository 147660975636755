import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { MdKeyboardArrowLeft } from "react-icons/md";

import Grid from '@mui/material/Grid';

import { Btn, PopUp, Text } from "../../components";
import { resetPassword } from "../../utils/api";
import { to } from "../../utils/enums";
import { bodyTextStyles, validation } from "../../utils/validations";

export default function RecoverAccountForm() {
    const navigate = useNavigate();
    const [recoverSuccess, setRecoverSuccess] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalMessage, setModalMessage] = useState("");
    const [modalButtonTitle, setModalButtonTitle] = useState("");

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = async (data) => {
        await resetPassword(data.recover)
            .then((response) => {
                if (response?.status !== undefined) {
                    if (response?.status === "success") {
                        setRecoverSuccess(true);
                        setShowModal(true);
                        setModalTitle("¡Excelente!");
                        setModalMessage(
                            `Hemos enviado un correo a ${data.recover} con las instrucciones para recuperar tu cuenta`,
                        );
                        setModalButtonTitle("Entendido");
                    }
                } else {
                    setRecoverSuccess(false);
                    setShowModal(true);
                    setModalTitle("¡Vaya!, ha ocurrido un error");
                    setModalMessage(
                        `Verifica tu correo electrónico ${data.recover} y vuelve a intentarlo`,
                    );
                    setModalButtonTitle("Volver a intentarlo");
                }
            })
            .catch((error) => {
                setRecoverSuccess(false);
                setShowModal(true);
                setModalTitle("¡Vaya!, ha ocurrido un error");
                setModalMessage(
                    `Verifica tu correo electrónico ${data.recover} y vuelve a intentarlo`,
                );
                setModalButtonTitle("Volver a intentarlo");

                console.log(error);
            });
    };

    const handleModal = () => {
        if (recoverSuccess) {
            setShowModal(false);
            navigate(to.RESET);
        } else {
            setShowModal(false);
        }
    };

    const displayModal = () => {
        return (
            <PopUp
                open={showModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                title={modalTitle}
                onBackdropClick={handleModal}
            >
                <Text
                    sx={bodyTextStyles}
                    align="center"
                    variant="body1"
                    paragraph
                >
                    {modalMessage}
                </Text>
                <Btn
                    variant="contained"
                    text={modalButtonTitle}
                    size="lg"
                    color="primary"
                    type="submit"
                    buttonClass="button-submit"
                    onClick={handleModal}
                    fullWidth
                />
            </PopUp>
        );
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>

            <Grid container justifyContent={'center'}>

                <Grid item xs={12} sm={9} md={8} sx={{ marginRight: 2, marginLeft: 2 }}>

                    <div className="input-container">
                        <label>Correo electrónico</label>
                        <input
                            type="text"
                            className={errors.recover && "input-container--error"}
                            placeholder="tucorreo@mail.com"
                            {...register("recover", {
                                required: true,
                                pattern: validation.email,
                            })}
                        />
                        {errors.recover?.type === "required" && (
                            <span className="input--error">
                                Este campo es obligatorio
                            </span>
                        )}
                        {errors.recover?.type === "pattern" && (
                            <span className="input--error">
                                Ingresa un correo electrónico valido
                            </span>
                        )}
                    </div>

                </Grid>

                <Grid item xs={12} sm={9} md={8} sx={{ marginRight: 2, marginLeft: 2 }}>

                    <Btn
                        variant="contained"
                        text="Enviar"
                        size="lg"
                        color="primary"
                        type="submit"
                        buttonClass="button-submit"
                        fullWidth
                    />

                </Grid>

                <Grid item xs={12} sm={9} md={8} sx={{ marginRight: 2, marginLeft: 2 }}>

                    <div className="flex--center" style={{marginBottom: 30}}>
                        <Btn
                            variant="text"
                            text="Atrás para iniciar sesión"
                            buttonClass="button-link"
                            onClick={() => navigate('/')}
                            startIcon={
                                <MdKeyboardArrowLeft className="icon--md icon--blue" />
                            }
                        />
                    </div>

                    <div className="flex--center signup-link">
                        <span className="whitespace--right-md">
                            ¿Nuevo en nuestra plataforma?
                        </span>
                        <Link to="/signup-account">Crea una cuenta</Link>
                    </div>

                </Grid>

            </Grid>

            {displayModal()}

        </form>

    );
}
