import RenderCreatedAt from "../atoms/renderCreatedAt";
import RenderCell from "../atoms/renderCell";
import WithDocument from "../atoms/withDocument";
import RenderSigners from "../atoms/renderSigners";
import RenderStatus from "../atoms/renderStatus";
import RenderActions from "../atoms/renderActions";
import RenderContractDetail from "../atoms/renderContractDetail";

const localizedTextsMap = {
    columnMenuUnsort: "Sin ordenar",
    columnMenuSortAsc: "De menor a mayor",
    columnMenuSortDesc: "De mayor a menor",
    columnMenuFilter: "Filtro",
    columnMenuHideColumn: "Ocultar",
    columnMenuShowColumns: "Mostrar columnas",
    toolbarExport: "Descargar",
    toolbarFilters: "Filtros",
    filterOperatorContains: "Contiene",
    filterOperatorEquals: "Igual a",
    filterOperatorStartsWith: "Empieza con",
    filterOperatorEndsWith: "Termina con",
    filterOperatorIsEmpty: "Esta vacio",
    filterOperatorIsNotEmpty: "No esta vacio",
    filterPanelInputLabel: "Valor",
    filterPanelInputPlaceholder: "Filtrar por valor",
};

const gridColumns = [
    { 
        field: "autoId",
        headerAlign: "center",
        headerName: "ID",
        headerClassName: "grid__header-item",
        cellClassName: "grid__row-item text--uppercase-all text--center",
        width: 80
    },
    { 
        field: "sTipoContrato",
        headerAlign: "center",
        headerName: "Tipo de trámite",
        headerClassName: "grid__header-item",
        cellClassName: "grid__row-item text--uppercase text--center",
        width: 270,
        renderCell: (params) => <RenderContractDetail data={params.row} />
    },
    { 
        field: "sTipoFirma",
        headerAlign: "center",
        headerName: "Tipo de firma",
        headerClassName: "grid__header-item",
        cellClassName: "grid__row-item text--uppercase text--center",
        width: 270,
        renderCell: (params) => <RenderCell item={params.value} />
    },
    { 
        field: "dContractDT",
        headerAlign: "center",
        headerName: "Fecha de creación",
        headerClassName: "grid__header-item",
        cellClassName: "grid__row-item",
        width: 180,
        renderCell: (params) => <RenderCreatedAt item={params.value} />
    },
    { 
        field: "withDocument",
        headerAlign: "center",
        headerName: "Docs. cargados",
        headerClassName: "grid__header-item",
        cellClassName: "grid__row-item text--center",
        width: 130,
        disableColumnMenu: true,
        renderCell: (params) => <WithDocument item={params.value} />
    },
    { 
        field: "NumeroFirmantes",
        headerAlign: "center",
        headerName: "Participantes",
        headerClassName: "grid__header-item",
        cellClassName: "grid__row-item text--center",
        width: 120,
        renderCell: (params) => <RenderSigners item={params.value} />
    },
    { 
        field: "sPaymentStatus",
        headerAlign: "center",
        headerName: "Estado",
        headerClassName: "grid__header-item",
        rowClassName: "grid__row-item",
        width: 240,
        disableColumnMenu: true,
        renderCell: (params) => <RenderStatus status={params.value} />
    },
    { 
        field: "sContractID",
        headerAlign: "center",
        headerName: "Ver",
        headerClassName: "grid__header-item",
        rowClassName: "grid__row-item",
        width: 140,
        disableColumnMenu: true,
        renderCell: (params) => <RenderActions id={params.value} row={params.row} />
    }
];

export {
    gridColumns,
    localizedTextsMap
 };