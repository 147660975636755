import { Box } from "@mui/system";
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import { styles } from "../../../../../helpers";

const CustomText = styled(Typography)((props) => ({
    color: props.theme.palette.primary.main,
    fontWeight: 900,
    fontSize: '1.1rem',
    textAlign: 'center'
}));

const StepSignature = ({ title, icon, description, step }) => {
    return (
        <div key={step} style={styles.card_item}>

            <Box style={{ padding: "10px" }}>{icon}</Box>

            <CustomText variant="p">
                {description}
            </CustomText>

        </div>
    );
};

export default StepSignature;
