import React from 'react';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';

import { FormularioSolicitudStyles } from '../../index.css';
import StepOne from '../../atoms/stepOne';
import TypeForm from '../../atoms/typeForm';
import StepFour from '../../atoms/stepFour';
import StepSix from '../../atoms/stepSix';
import StepSeven from '../../atoms/stepSeven';
import StepEight from '../../atoms/stepEight';
import StepSignerName from '../../atoms/stepSignerName';
import StepSignerEmail from '../../atoms/stepSignerEmail';
import StepSignerRut from '../../atoms/stepSignerRut';
import StepTen from '../../atoms/stepTen';
import StepSignerPhone from '../../atoms/stepSignerPhone';

const Stepsper = () => {
    const {
        formSolicitud: {
            iSignedCount
        },
    } = useSelector((state) => state);
    const styles = FormularioSolicitudStyles();
    const [firmantes, setFirmantes] = React.useState([]);
    const { control, watch, trigger, setValue, getValues, reset } = useForm({
        defaultValues: {
            sNamelInvited: '',
            email: '',
            proceduretype: null,
            iContractTypeID: null,
            document: null,
            iSignedCount: 1,
            signerPay: null,
            knowServices: null,
            question: null,
            procedureid: null
        }
    });

    const { append, remove } = useFieldArray({
        control,
        name: "signers"
    });

    const getSigners = useWatch({ control, name: 'signers' });

    React.useEffect(() => {
        const arrayIteration = [];
        remove();
        setFirmantes([]);
        if (![null, undefined].includes(iSignedCount)) {

            for (let index = 0; index < iSignedCount; index++) {
                append({ nombres: '', email: '', rutId: '', type: "NATURAL", payment: '0.00', portion: '0', phone: '', pay: false });
                arrayIteration.push(<StepSignerName key={index + "StepSignerName"} index={index} control={control} trigger={trigger} useWatch={useWatch} setValue={setValue} />);
                arrayIteration.push(<StepSignerEmail key={index + "StepSignerEmail"} index={index} control={control} trigger={trigger} useWatch={useWatch} watch={watch} getValues={getValues} setValue={setValue} />);
                arrayIteration.push(<StepSignerRut key={index + "StepSignerRut"} index={index} control={control} trigger={trigger} useWatch={useWatch} setValue={setValue} watch={watch} getValues={getValues} reset={reset} />);
                arrayIteration.push(<StepSignerPhone key={index + "StepSignerPhone"} index={index} control={control} trigger={trigger} useWatch={useWatch} setValue={setValue} watch={watch} getValues={getValues} reset={reset} />);
            }

            setFirmantes(arrayIteration);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iSignedCount]);

    React.useEffect(() => {
        // console.log('getSigners', getSigners);
    }, [getSigners]);

    return (
        <Grid className={styles.stepsperContent} container>

            <TypeForm
                lastPage={(e)=>console.log('e: ', e)}
                watch={watch}
                signers={firmantes}
                otherSteps={[
                    <StepTen control={control} trigger={trigger} watch={watch} setValue={setValue} getValues={getValues} />,
                    <StepEight control={control} trigger={trigger} watch={watch} getValues={getValues} setValue={setValue} />
                ]}
            >

                <StepOne control={control} trigger={trigger} watch={watch} setValue={setValue} />

                <StepFour control={control} trigger={trigger} watch={watch} setValue={setValue} />

                <StepSix control={control} trigger={trigger} watch={watch} setValue={setValue} />

                <StepSeven control={control} trigger={trigger} watch={watch} setValue={setValue} />

            </TypeForm>

        </Grid>
    );
}

export default Stepsper;