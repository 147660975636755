const styles = {
    itemFlex: (size) => {
        return {
            flex: size,
        };
    },
    paddingY: (size) => {
        return {
            py: size,
        };
    },
    buttonBlueStatusMenu: {
        backgroundColor: "primary.light",
        color: "white.main",
        textTransform: "none",
        fontSize: "0.9rem",
        borderRadius: "5px",
        padding: "12px 35px",
        fontWeight: "500",
        "&:hover": {
            backgroundColor: "primary.dark",
        },
    },
    buttonStatusMenu: {
        backgroundColor: "quinary.light",
        color: "primary.light",
        textTransform: "none",
        fontSize: "0.9rem",
        borderRadius: "5px",
        padding: "6px 10px",
        fontWeight: "500",
        "&:hover": {
            color: "white.main",
        },
    },
    buttonGridStatusMenu: {
        backgroundColor: "quinary.light",
        color: "primary.light",
        textTransform: "none",
        fontSize: "0.9rem",
        borderRadius: "5px",
        padding: "6px 10px",
        fontWeight: "500",
        "&:hover": {
            color: "white.main",
            backgroundColor: "primary.dark",
        },
    },
    buttonGoToCreatorMenu: {
        backgroundColor: "quaternary.light",
        color: "white.main",
        textTransform: "none",
        fontWeight: "500",
    },
    userNameStyles: {
        color: "black.main",
        textTransform: "capitalize",
        lineHeight: 1.1,
        fontSize: "15px",
    },
    titleStyles: {
        color: "black.main",
        fontWeight: "500",
        marginBottom: "0",
        marginTop: "24px",
    },
    subTitleStyles: {
        color: "quinary.main",
        fontWeight: "300",
        marginBottom: "0",
    },
    gridContainerStyles: {
        border: "none",
    },
    containerWrapperItemsCenter: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
    boldedTextExtraLarge: {
        fontWeight: "500",
        fontSize: "2.5rem",
        color: "black.main",
    },
    boldedTextLarge: {
        fontWeight: "500",
        fontSize: "2rem",
        color: "black.main",
    },
    boldedTextSmall: {
        fontWeight: "500",
        fontSize: "1.2rem",
        color: "black.main",
    },
    boldedTextSmaller: {
        fontWeight: "500",
        fontSize: "1rem",
        color: "black.main",
    },
    boldedTextSmallest: {
        fontWeight: "500",
        fontSize: "0.9rem",
        color: "black.main",
    },
    fullContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
    },
    userNameContainerStyles: {
        marginLeft: "10px",
        display: "flex",
        flexDirection: "column",
    },
    userNickNameStyles: {
        color: "quinary.main",
        fontWeight: "300",
        marginBottom: "0",
        fontSize: "0.9rem",
    },
    body_welcome: { padding: "48px 64px" },
    title_welcome: {
        fontWeight: "300",
        textAlign: "left",
        fontSize: "1.8rem",
        paddingBottom: "10px",
        paddingTop: "10px",
    },
    subTitle_welcome: {
        fontWeight: "300",
        textAlign: 'justify',
        fontSize: "1.1rem",
        paddingTop: "10px",
        paddingBottom: "10px"/* ,
        '& => span': {
            fontWeight: "900",
        } */
    },
    card_item: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    card_welcome: {
        marginTop: '4px',
        marginBottom: '25px'
    },
    contract_creation_welcome: {
        padding: "15px 0",
        display: "flex",
        justifyContent: "center",
        marginBottom: '34px'
    },
    text_soport_welcome: {
        display: "flex",
        fontSize: "1.3rem",
        fontWeight: "300",
        alignItems: "center"
    },
    text_service_welcome: {
        display: "flex",
        fontSize: "1.4rem",
        fontWeight: "300",
        alignItems: "center",
        paddingTop: "15px",
        justifyContent: "center",
    },
    userAvatarContainerStyles: {
        display: "flex",
        flexDirection: "row",
    },
    userAvatarPictureStyles: {
        overflow: "hidden",
        borderRadius: "10%",
    },
};

export default styles;
