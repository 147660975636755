import React from "react";
import { Avatar } from "@mui/material";
import { getUserInfo } from "../storeSelector/storeSelector";
import { ReactComponent as Logo } from "../../assets/logo/logo.svg";
import WhatsappIconSpeedDial from "../whatsappIconSpeedDial";
//import PopupStateNotifications from "../common/notifications";

const Menutop = () => {
    const userInfo = getUserInfo();

    /*const notifications = [
        {
            title: "Titulo notificacion",
            date: "10/10/2022",
            description: "Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500..",
            read: false
        },
        {
            title: "Titulo notificacion",
            date: "10/10/2022",
            description: "Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500..",
            read: false
        },
        {
            title: "Titulo notificacion",
            date: "10/10/2022",
            description: "Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500..",
            read: true
        },
        {
            title: "Titulo notificacion",
            date: "10/10/2022",
            description: "Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500..",
            read: false
        },
        {
            title: "Titulo notificacion",
            date: "10/10/2022",
            description: "Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500..",
            read: true
        }
    ]*/
    return (
        <div className="top-menu__container">
            <div className="top-menu__logo">
                <Logo className="logo-size--xs" />
            </div>
            <div className="top-menu__user-access">
                <div className="top-menu__searchbar">
                    {/* <FaSearch className="search-icon icon--xs icon--grey-dark" /> */}
                    {/* <input
                        type="text"
                        placeholder="Buscar"
                        className="input--no-border"
                    /> */}
                </div>
                <div className="user-menu-notif">
                    {/*<PopupStateNotifications notifications={notifications} />*/}
                    {/* <FaCircle className="icon--xxs icon--red new-msg-alert" /> */}
                    {/* <FaBell className="icon--xs icon--grey-dark icon-pointer" /> */}
                    <WhatsappIconSpeedDial />
                </div>
                
                <div className="user-data-name">
                    
                    <div className="name__container">
                        <strong className="user-name">{`${userInfo.firstName} ${userInfo.lastName}`}</strong>
                        <span className="user-rol">Administrator</span>
                    </div>
                    <Avatar alt="User name" />
                </div>
            </div>
        </div>
    );
};

export default Menutop;
