import { forwardRef, Fragment, useState } from 'react';
import { FaUndoAlt } from "react-icons/fa";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';

import { ContractDetailReviewStyles } from '../../index.css';
import Btn from '../../../../buttons/Button';


const DeleteAndUpdateCustomButtom = styled(Button)((props) => ({
    fontSize: '0.78rem',
    border: `1.5px solid ${props.theme.palette.primary.light}`
}));

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ResetContractModal = ({ aceptar }) => {
    const styles = ContractDetailReviewStyles();
    const [open, setOpen] = useState(false);


    const handleClickOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const deleteCurrentContract = async () => {
        handleClose();
        aceptar();
    };

    return (

        <Fragment>

            <Tooltip
                title={
                    <Typography variant="subtitle1" color={['white.main']} gutterBottom>
                        Restablecer el contrato permite reiniciar
                        el proceso y devolverlo a su estado inicial.
                        Útil cuando es necesario corregir el documento
                        y volver a enviarlo a los participantes
                        para que lo aprueben.
                    </Typography>
                }
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                arrow
            >
                <DeleteAndUpdateCustomButtom variant="outlined" startIcon={<FaUndoAlt className="icon--blue" />} style={{ marginRight: '20px' }} onClick={handleClickOpen}>
                    Resetear Contrato
                </DeleteAndUpdateCustomButtom>

            </Tooltip>

            <Dialog
                fullWidth={true}
                TransitionComponent={Transition}
                open={open}
                onClose={handleClose}
                maxWidth='xs'
                className={styles.mainContainerDialog}
            >

                <DialogTitle sx={{ textAlign: 'center' }}>
                    ¿Deseas resetear este contrato?
                </DialogTitle>

                <DialogContent>

                    <DialogContentText>

                        <Typography>
                            Estás seguro de que deseas reiniciar el contrato? Esta opción eliminará el documento y tendrás que asignar las firmas y reenviar el contrato a todos los participantes para que puedan aceptarlo o rechazarlo nuevamente.
                        </Typography>

                    </DialogContentText>

                </DialogContent>

                <DialogActions style={{ justifyContent: 'center', paddingBottom: '20px' }}>

                    <Btn
                        variant="outlined"
                        text="Cancelar"
                        color="error"
                        onClick={handleClose}
                        sx={{
                            height: '39px',
                            borderRadius: '20px'
                        }}
                    />

                    <Btn
                        variant="outlined"
                        text="Aceptar"
                        color="primary"
                        // onClick={() => resetContract()}
                        onClick={() => deleteCurrentContract()}
                        sx={{
                            height: '39px',
                            borderRadius: '20px',
                            marginLeft: '30px !important'
                        }}
                    />

                </DialogActions>

            </Dialog>

        </Fragment>

    );
}

export default ResetContractModal;