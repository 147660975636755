import { combineReducers } from "redux";
import authReducers from "./auth/entities";
import dashboardReducers from "./dashboard/entities";
import contractReducers from "./contract/entities";
import fvavanzadaReducers from "./fvavanzada/fvavanzada";
import firmaDocsReducers from "./firmaDocs/firmaDocs";
import providersReducers from "./providers/providers";
import usersReducers from "./users/userList";
import formSolicitudReducers from "./formSolicitud/formSolicitud";
import organizationsReducers from "./organization";
import formAgreementReducer from './formAgreement';
import formUachReducer from './formUach/formUach';
import formTramitReducer from './formTramit';
import schedulingReducer from './scheduling';
import agreementReducer from './agreement';

export default combineReducers({
    agreement: agreementReducer,
    formTramit: formTramitReducer,
    scheduling: schedulingReducer,
    session: authReducers,
    dashboard: dashboardReducers,
    contract: contractReducers,
    fvavanzada: fvavanzadaReducers,
    firmaDocs: firmaDocsReducers,
    providers: providersReducers,
    users: usersReducers,
    formSolicitud: formSolicitudReducers,
    organizations: organizationsReducers,
    formAgreement: formAgreementReducer,
    formUach: formUachReducer
});
