import axios from "axios";
// import { api } from "../../../../utils/enums";

// const uri = api.BASE_URI;


export const mergeDocuments = async (userToken, body) => {
    try {
        const response = await axios.post(
            `https://merge.firmavirtual.com/pdf/sort`,
            body,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: userToken
                }
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};