import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { CounterByStatus } from "..";

/**
 * Component in charge of showing, list of process counters
 * @param  props properties required for the component
 * @returns 
 */

const ContractListHeader = () => {

    const {
        contract: {
            contractCreator: {
                contractListActive
            }
        }
    } = useSelector(state => state);
    const [WidgetsData, setWidgetsData] = useState([]);


    const fields = [
        {
            color: "secondary",
            title: "Trámites en Revisión",
            counter: WidgetsData.filter(contract => contract.sStatus === "CREATED" || contract.sStatus === "REVIEW" || contract.sStatus === "REJECTED").length
        },
        {
            color: "senary",
            title: "Trámites de Pagos",
            counter: WidgetsData.filter(contract => contract.sStatus === "APPROVED" && (contract.sPaymentStatus === "PENDING" || contract.sPaymentStatus === "PARTIALLY_PAID" )).length
        },
        {
            color: "tertiary",
            title: "Trámites por firmar",
            counter: WidgetsData.filter(contract => contract.sStatus === "SIGNED_PENDING" || contract.sStatus === "DELIVERED").length,
        },
        {
            color: "quaternary",
            title: "Notaría",
            counter: WidgetsData.filter(contract => contract.sStatus === "IN_NOTARY").length
        },
        {
            color: "primary",
            title: "Entrega",
            counter: WidgetsData.filter(contract => contract.sStatus === "FINISHED").length
        }
    ];

    useEffect(() => {

        const { status, message } = contractListActive;

        if (["success"].includes(status)) {
            setWidgetsData(message);
        }

    }, [contractListActive]);

    const convertCount = (count) => {
        if (count >= 1000 && count < 1000000) {
            return (count / 1000).toFixed(1) + "K";
        } else if (count >= 1000000) {
            return (count / 1000000).toFixed(1) + "M";
        } else {
            return count;
        }
    }

    return (
        <div className="cards__container">
            {fields && fields.map((field, index) => {
                return (
                    <CounterByStatus
                        key={index}
                        bgColor={field.color}
                        count={convertCount(field.counter)}
                        procedure={field.title}
                        avatar={field.avatar}
                        imageAvatar={field.img}
                    />
                )
            })}
        </div>
    );
}

export default ContractListHeader;
