import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller } from "react-hook-form";
import { BiRightArrowAlt } from "react-icons/bi";

import { motion } from "framer-motion";

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import Autocomplete from '@mui/material/Autocomplete';


import { errorStepFormSolicitud, saveInfoFormSolicitud } from "../../../../store/formSolicitud/formSolicitud";
import { FormularioSolicitudStyles } from "../../index.css";
import { contractType, procedureType } from "../../../../placeholder/contracts";

const StepSix = ({ control, watch, trigger, setValue }) => {
    const styles = FormularioSolicitudStyles();
    const {
        formSolicitud: {
            proceduretype,
            procedureid,
            iSignedCount
        }
    } = useSelector((state) => state);
    const dispatch = useDispatch();
    const refFirstLoad = React.useRef({});
    const [errorProceduretypeInput, setErrorProceduretypeInput] = React.useState(undefined);
    const [errorProcedureidInput, setErrorProcedureidInput] = React.useState(undefined);
    const [errorISignedCountInput, setErrorISignedCountInput] = React.useState(undefined);

    const watchProceduretype = watch("proceduretype");
    const watchProcedureid = watch("procedureid");
    const watchISignedCount = watch("iSignedCount");

    React.useEffect(() => {
        if (![undefined].includes(errorProceduretypeInput) || ![undefined].includes(errorProcedureidInput) || ![undefined].includes(errorISignedCountInput)) {
            dispatch(errorStepFormSolicitud(true));
        } else {
            dispatch(errorStepFormSolicitud(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorProceduretypeInput, errorProcedureidInput, errorISignedCountInput]);

    React.useEffect(() => {
        setValue("proceduretype", proceduretype);
        setValue("procedureid", procedureid);
        setValue("iSignedCount", iSignedCount);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    refFirstLoad.current = false;

    React.useEffect(() => {
        setValue("procedureid", null);
        trigger();

        if (!['', null, undefined].includes(watchProceduretype) && watchProceduretype !== proceduretype) dispatch(saveInfoFormSolicitud({ indexe: 'proceduretype', value: watchProceduretype }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchProceduretype]);

    React.useEffect(() => {
        trigger();

        if (!['', null, undefined].includes(watchProcedureid) && watchProcedureid !== procedureid) dispatch(saveInfoFormSolicitud({ indexe: 'procedureid', value: watchProcedureid }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchProcedureid]);

    React.useEffect(() => {
        trigger();

        if (!['', null, undefined].includes(watchISignedCount) && watchISignedCount !== iSignedCount) dispatch(saveInfoFormSolicitud({ indexe: 'iSignedCount', value: watchISignedCount }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchISignedCount]);

    return (
        <div className={styles.stepBox}>

            <motion.div
                initial={{ y: "50vh" }}
                animate={{ y: 0 }}
                transition={{ stiffness: 150 }}
            >

                <Grid container xs={12}>

                    <Grid item xs={1} className={styles.boxIndetify}>

                        6<BiRightArrowAlt />

                    </Grid>

                    <Grid item xs={11}>

                        <Typography variant="h4" className={styles.cssTexto1} gutterBottom>
                            ¿Qué tipo de trámite necesitas hacer?
                        </Typography>

                        <Controller
                            name="proceduretype"
                            control={control}
                            rules={{
                                required: 'Debe elegir una opción'
                            }}
                            render={({
                                field: { onChange, onBlur, value, ref },
                                fieldState,
                            }) => {
                                setErrorProceduretypeInput(fieldState.error);
                                return (
                                    <FormControl sx={{ m: 1, width: '100%' }} error={fieldState.error ? true : false}>

                                        <Autocomplete
                                            ref={ref}
                                            options={procedureType}
                                            getOptionLabel={(option) => {
                                                return option['name'] ? option['name'] : "";
                                            }}
                                            renderOption={(props, option, { selected }) => (
                                                <li {...props} key={option['name']}>
                                                    {option['name']}
                                                </li>
                                            )}
                                            value={value}
                                            onChange={(event, item) => {
                                                onChange(item);
                                            }}
                                            renderInput={params => <TextField {...params} variant='standard' />}
                                        />

                                        <FormHelperText>{fieldState.error ? fieldState.error.message : null}</FormHelperText>

                                    </FormControl>
                                );
                            }}
                        />

                    </Grid>

                </Grid>

                {![null, undefined, ''].includes(watchProceduretype) && (
                    <>
                        <br />
                        <br />

                        <Grid container xs={12}>

                            <Grid item xs={1} className={styles.boxIndetify}>

                            </Grid>

                            <Grid item xs={11}>

                                <Typography variant="h4" className={styles.cssTexto1} gutterBottom>
                                    ¿Qué trámite necesitas gestionar?
                                </Typography>

                                <Controller
                                    name="procedureid"
                                    control={control}
                                    rules={{
                                        required: 'Debe elegir una opción'
                                    }}
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                        fieldState
                                    }) => {
                                        setErrorProcedureidInput(fieldState.error);
                                        return (
                                            <FormControl sx={{ m: 1, width: '100%' }} error={fieldState.error ? true : false}>

                                                <Autocomplete
                                                    ref={ref}
                                                    options={contractType.filter((item) => item['procedureType'] === watchProceduretype['value'])}
                                                    getOptionLabel={(option) => {
                                                        return option['name'] ? option['name'] : "";
                                                    }}
                                                    renderOption={(props, option, { selected }) => (
                                                        <li {...props} key={option['name']}>
                                                            {option['name']}
                                                        </li>
                                                    )}
                                                    value={value}
                                                    onChange={(event, item) => {
                                                        onChange(item);
                                                    }}
                                                    renderInput={params => <TextField {...params} variant='standard' />}
                                                />

                                                <FormHelperText>{fieldState.error ? fieldState.error.message : null}</FormHelperText>

                                            </FormControl>
                                        );
                                    }}
                                />

                            </Grid>

                        </Grid>
                    </>)}

                <br />
                <br />

                <Grid container xs={12}>

                    <Grid item xs={1} className={styles.boxIndetify}>

                    </Grid>

                    <Grid item xs={11}>

                        <Typography variant="h4" className={styles.cssTexto1} gutterBottom>
                            ¿Cuantas personas firman el documento?
                        </Typography>

                        <Controller
                            name="iSignedCount"
                            control={control}
                            rules={{
                                required: 'Debe especificar un numero de firmantes',
                                min: {
                                    value: 1,
                                    message: 'Mínimo un firmante'
                                },
                                max: {
                                    value: 10,
                                    message: 'Máximo 10 firmantes'
                                }
                            }}
                            render={({
                                field: { onChange, onBlur, value, ref },
                                fieldState,
                            }) => {
                                setErrorISignedCountInput(fieldState.error);
                                return (
                                    <FormControl sx={{ m: 1, width: '100%' }} error={fieldState.error ? true : false}>

                                        <TextField
                                            error={fieldState.error ? true : false}
                                            type={'number'}
                                            variant={'standard'}
                                            ref={ref}
                                            value={value}
                                            onChange={onChange}
                                        />

                                        <FormHelperText>{fieldState.error ? fieldState.error.message : null}</FormHelperText>

                                    </FormControl>
                                );
                            }}
                        />

                    </Grid>

                </Grid>

            </motion.div>

        </div>
    );
}

export default StepSix;