import React from "react";
import { useNavigate } from "react-router-dom";

import { Box } from "@mui/material";
import { BsEye } from "react-icons/bs";

import { to } from "../../../../../utils/enums";
import ModalContractDelete from "../../../../modalContractDelete/ModalContractDelete";

const RenderActions = ({ id, row }) => {
    const navigate = useNavigate();

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: 'center',
                alignContent: "center",
                alignItems: 'center'
            }}
            className="flex--1"
        >

            <Box
                sx={{ padding: "6px", marginTop: '6px' }}
                onClick={() => navigate(to.CONTRACT_DETAIL, { state: id })}
            >
                <BsEye className="icon--xs icon-pointer icon--blue-light" />
            </Box>

            <ModalContractDelete contract={row} />
        </Box>
    );
};

export default RenderActions;
