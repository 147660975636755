
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { Divider, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { styled } from '@mui/material/styles';

import { Btn, Spinner } from "../..";
import { styles } from "../../../helpers";
import { getAgreementsStorage } from "../../../store/agreement";
import { userInfo } from "../../../store/auth/userLogin";
import { getContractsCreated } from "../../../store/contract/contractDetail";
import { getAllTramitStorage } from "../../../store/contract/tramites";
import { icons, to, typeUser, verifiVariable } from "../../../utils/enums";
import { getUserInfo } from "../../storeSelector/storeSelector";
import StepSignature from "./molecules/stepSignature";
import { getAllAgreementsStorage } from "../../../store/organization";
import { getAllCommune, getAllJuridiction, getAllNotaries, getAllNotarys } from "../../../utils/api";
import { saveSchedulingInfo } from "../../../store/scheduling";
import ScreenSizeHook from "../../../hooks/ScreenSizeHook";
import { getPreviusSigners } from "../../../store/contract/signatoriesList";


const CustomText = styled(Typography)((props) => ({
    fontWeight: 900,
    textAlign: 'center'
}));


const ContentWelcome = () => {
    const userInfoStatus = getUserInfo();
    const screenSizeHook = ScreenSizeHook();
    const {
        session: {
            login: { user, userInfo: userInfoRedux }
        },
        contract: {
            contractCreator: { loading },
            signatories: {
                loading: loadingSigners
            }
        },
        scheduling: {
            communeList,
            juridictionList,
            notaryList,
            notarieList
        }
    } = useSelector((state) => state);

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const stepSignature = [
        {
            step: 1,
            title: "Solicita",
            description:
                "Indica  el tipo de trámite.",
            icon: icons.MANAGE_PROCEDURES_ICON,
        },
        {
            step: 2,
            title: "Elige",
            description:
                "Elige el tipo de firma.",
            icon: icons.REVIEW_PROCEDURES_ICON,
        },
        {
            step: 3,
            title: "Firma",
            description:
                "Firma electrónicamente tus documentos.",
            icon: icons.SIGNATURE_PROCEDURES_ICON,
        },
        {
            step: 4,
            title: " ",
            description:
                "Recibe el documento en tu correo ya firmado.",
            icon: icons.DELIVERY_PROCEDURES_ICON,
        },
    ];


    const getNotaries = async (token) => {

        try {

            const response = await getAllNotaries(token);

            if (response['status'] === 'Success') dispatch(saveSchedulingInfo({ indexe: 'notarieList', value: response['data'] }));

        } catch (error) {
            console.log(error);
        }

    }

    const getNotarys = async (token) => {

        try {

            const response = await getAllNotarys(token);

            if (response['status'] === 'Success') dispatch(saveSchedulingInfo({ indexe: 'notaryList', value: response['data'] }));

        } catch (error) {
            console.log(error);
        }

    }

    const getCommunes = async (token) => {

        try {

            const response = await getAllCommune(token);

            if (response['status'] === 'Success') dispatch(saveSchedulingInfo({ indexe: 'communeList', value: response['data'] }));

        } catch (error) {
            console.log(error);
        }

    }

    const getJuridictions = async (token) => {

        try {

            const response = await getAllJuridiction(token);

            if (response['status'] === 'Success') dispatch(saveSchedulingInfo({ indexe: 'juridictionList', value: response['data'] }));

        } catch (error) {
            console.log(error);
        }

    }

    useEffect(() => {

        const { token, userId } = user;

        getCommunes(token);

        dispatch(getAllTramitStorage(token));

        dispatch(userInfo(token, userId));

        dispatch(getContractsCreated(userId, token, userInfoStatus.profileId));

        dispatch(getContractsCreated(userId, token));

        dispatch(getAgreementsStorage(token, userId));

        dispatch(getPreviusSigners(token));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

        if (!verifiVariable(userInfoRedux)) {

            const { token } = user;

            const { iProfileID } = userInfoRedux;

            if (!verifiVariable(iProfileID) && iProfileID === typeUser.ADMIN) dispatch(getAllAgreementsStorage(token));

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfoRedux]);

    useEffect(() => {

        const { token } = user;

        if (!verifiVariable(communeList) && communeList.length === 0) getCommunes(token);

        if (!verifiVariable(notarieList) && notarieList.length === 0) getNotaries(token);

        if (!verifiVariable(juridictionList) && juridictionList.length === 0) getJuridictions(token);

        if (!verifiVariable(notaryList) && notaryList.length === 0) getNotarys(token);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        console.log('screenSizeHook: ', screenSizeHook);
    }, [screenSizeHook]);

    return (
        <div
            className="detail__container"
            style={{
                padding: `48px ${screenSizeHook === 'xs' ? '0px' : '64px'}`
            }} /* style={styles.body_welcome} */>
            {(loading || loadingSigners) && (
                <Spinner
                    loading={true}
                    type="bar"
                    text={"Cargando contratos, por favor espere"}
                    opacity={true}
                />
            )}
            <Grid container>

                <Grid item xs={12}>

                    <Typography
                        variant="h4"
                        align="center"
                        fontSize={screenSizeHook === 'xs' ? '1.5rem' : '2.1rem'}
                        fontWeight={'700'}
                    >
                        Bienvenido a FirmaVirtual
                    </Typography>

                </Grid>

                <Grid item xs={12}>
                    <div style={styles.subTitle_welcome}>
                        <span
                            style={{ fontSize: "1.2rem" }}
                        >{`${user.firstName} ${user.lastName}`}</span>
                        , ya puedes firmar electrónicamente tus documentos de manera 100% online. Simplemente ingresa en
                        <span style={{ fontSize: "1.2rem" }}> Gestión de trámites</span> y maneja tus contratos.
                    </div>
                </Grid>

                <Grid container spacing={4} style={styles.card_welcome}>
                    {stepSignature.map((signature, idx) => {
                        return (
                            <Grid item xs={6} md={3} key={`step-${idx}`}>
                                <StepSignature
                                    title={signature.title}
                                    icon={signature.icon}
                                    description={signature.description}
                                    step={signature.step}
                                />
                            </Grid>
                        );
                    })}
                </Grid>

                <Divider />

                <Grid item xs={12} style={styles.contract_creation_welcome}>
                    <Btn
                        variant="contained"
                        text="Comienza un  nuevo  trámite"
                        sx={styles.buttonBlueStatusMenu}
                        onClick={() => navigate(to.CONTRACT_CREATION)}
                    />
                </Grid>

                <Grid item xs={12}>

                    <CustomText
                        color={['primary.main']}
                        fontSize={'1.1rem'}
                        marginBottom={'30px'}
                    >
                        Gracias por usar nuestros servicios
                    </CustomText>

                </Grid>

                <Grid item xs={12}>

                    <CustomText
                        fontSize={'1rem'}
                        marginBottom={'35px'}
                    >
                        Para más información comuníquese con nuestro equipo de soporte al cliente:
                    </CustomText>

                </Grid>

                <Grid item xs={12}>

                    <Typography variant="body1" textAlign={'center'} marginBottom={'20px'}>
                        +56 9 4522 7304 &nbsp; &nbsp; / &nbsp; &nbsp; +56 9 7563 5393 &nbsp; &nbsp; / &nbsp; &nbsp; +56 9 9786 2790
                    </Typography>

                </Grid>

                <Grid item xs={12}>

                    <Typography variant="body1" textAlign={'center'}>
                        contacto@firmavirtual.legal
                    </Typography>

                </Grid>

            </Grid>

        </div>

    );
};

export default ContentWelcome;
