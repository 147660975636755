import { BiDownload } from "react-icons/bi";
import { AiOutlineReload } from "react-icons/ai";
import { GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid';
import { useNavigate } from "react-router-dom";

import { styles } from "../../../../../helpers";
import Btn from "../../../../buttons/Button";
import Text from "../../../../heading/Text";

import { to } from "../../../../../utils/enums";

const CustomToolbar = ({ genKey, updateData }) => {

    const navigate = useNavigate();

    const cvsExportFields = [
        "autoId",
        "sTipoContrato",
        "sTipoFirma",
        "dContractDT",
        "withDocument",
        "NumeroFirmantes",
        "sStatus"
    ];

    return (
        <div className="contracts-list__top-menu">
            <div className="title-menu__status">
                <Text sx={styles.titleStyles} variant="h6">
                    Documentos por tramite.
                </Text>
                <Text sx={styles.subTitleStyles}>
                    Aqui podrás visualizar todos los trámites que tienen un documento adjunto.
                </Text>
            </div>
            <GridToolbarContainer className="buttons-menu__status">
                <GridToolbarFilterButton
                    title="Filtros"
                    // translate={true}
                    lang="es"
                    arrow={false}
                    className="grid-toolbar"
                    sx={styles.buttonGridStatusMenu} />
                <GridToolbarExport
                    title="Descargar"
                    // translate={true}
                    lang="es"
                    sx={styles.buttonGridStatusMenu}
                    startIcon={<BiDownload className="icon--xs icon--blue-light" />}
                    csvOptions={{
                        allColumns: true,
                        fields: cvsExportFields
                    }} />
                <Btn
                    variant="contained"
                    text="Actualizar"
                    sx={styles.buttonStatusMenu}
                    onClick={updateData}
                    startIcon={
                        <AiOutlineReload className="icon--xs icon--blue-light" />
                    }
                />
                <Btn
                    variant="contained"
                    text="Crear Tramite"
                    sx={styles.buttonGoToCreatorMenu}
                    onClick={() => navigate(to.CONTRACT_CREATION)}
                />
            </GridToolbarContainer>
        </div>
    );
}

export default CustomToolbar;