import { isValidPhoneNumber } from "libphonenumber-js";

const cleanText = (cadena) => {
    // Definimos los caracteres que queremos eliminar
    // eslint-disable-next-line
    var specialChars = "!@#$^&%*()+=-[]/{}|:<>?,.";

    // Los eliminamos todos
    for (var i = 0; i < specialChars.length; i++) {
        cadena = cadena.replace(new RegExp("\\" + specialChars[i], "gi"), "");
    }

    // Lo queremos devolver limpio en minusculas
    cadena = cadena.toLowerCase();

    // Quitamos espacios
    cadena = cadena.replace(/ /g, "");

    // Quitamos acentos y "ñ". Fijate en que va sin comillas el primer parametro
    cadena = cadena.replace(/á/gi, "a");
    cadena = cadena.replace(/é/gi, "e");
    cadena = cadena.replace(/í/gi, "i");
    cadena = cadena.replace(/ó/gi, "o");
    cadena = cadena.replace(/ú/gi, "u");
    cadena = cadena.replace(/ñ/gi, "n");
    return cadena;
};

const BASE64_MARKER = ";base64,";

const validatePhoneNumber = (phoneNumber) => isValidPhoneNumber(phoneNumber);

const validation = {
    email: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,8})+$/,
    rut: /^[0-9]\d{5,7}-[0-9kK]/,
    phone: /^(\+56)(\s?)(0?2?9?)?(\s?)[0-9]\d{7}$/,
    passport: /^[A-Za-z0-9]{9}$/,
    number: /^[0-9]+$/,
    nombre: /^[a-zA-Z0-9ÑñÁáÉéÍíÓóÚúÜü\s]+$/
};

//old expresion validate phone: /^(\+?56)?(\s?)(0?9)(\s?)[0-9]\d{7}$/,

const textErrorAsync =
    "Ha ocurrido un error al realizar su peticion, por favor intente nuevamente o comuniquese con un administrador del sistema";

const nextStep =
    'puede presionar "Siguiente" para continuar con los requisitos';

const bodyTextStyles = {
    color: "quinary.xdark",
    fontSize: "1.3rem",
};

const searchDuplicate = (array, itemSearch) => {
    const search = array.reduce((acc, item) => {
        acc[item[itemSearch]] = ++acc[item[itemSearch]] || 0;
        return acc;
    }, {});

    const duplicate = array.filter((item) => {
        return search[item[itemSearch]];
    });

    return duplicate;
};

const delaySuccess = (n) => {
    return new Promise(function (resolve) {
        setTimeout(resolve, n * 1000);
    });
};

const delayError = (n) => {
    return new Promise(function (resolve, reject) {
        setTimeout(reject, n * 1000);
    });
};

const getNombres = (arrayNombres) => {
    let nombres = arrayNombres.toString();

    return nombres.replace(/,/gi, " ");
};

const limpiarEspacios = (cadena) => {
    let string = cadena;

    return string.replace(/ /gi, "");
};

const convertDataURIToBinary = (dataURI) => {
    const base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    const base64 = dataURI.substring(base64Index);
    const raw = window.atob(base64);
    const rawLength = raw.length;
    const array = new Uint8Array(new ArrayBuffer(rawLength));

    for (let i = 0; i < rawLength; i++) {
        array[i] = raw.charCodeAt(i);
    }
    return array;
};

const getClosestScaleNumber = (array, num) => {
    let i = 0;
    let minDiff = 1000;
    let closestScaleNumber;

    for (i in array) {
        const m = Math.abs(num - array[i]);
        if (m < minDiff) {
            minDiff = m;
            closestScaleNumber = array[i];
        }
    }

    return closestScaleNumber;
};

const signedBoxWidth = 276;

const signedBoxHeight = 80;

const GetDigVer = (rut) => {

    const splitRut = rut.split("-");

    let RutSolo = splitRut[0];

    let digver;
    let once = 11;
    let largo = 0;
    let suma = 0;
    let resto = 0;
    let fin = 0;
    let dig = 0;
    largo = RutSolo.trim().length;
    let multiplo = 2;
    // eslint-disable-next-line
    while (largo != 0) {
        dig = RutSolo.substr(largo - 1, 1);
        largo = largo - 1;
        suma = suma + (dig * multiplo);
        multiplo = multiplo + 1;
        if (multiplo > 7) {
            multiplo = 2;
        }
    }
    resto = suma - (Math.floor(suma / once) * once);
    fin = once - resto;
    // eslint-disable-next-line
    if (fin == 10) {
        digver = "K";
    } else {
        // eslint-disable-next-line
        if (fin == 11) {
            digver = 0;
        } else {
            digver = fin;
        }
    }
    // eslint-disable-next-line
    return digver == splitRut[1];
}

const checkRut = (rut) => {
    const data = rut.split("-");
    const digits = data[0];

    if ([undefined, null, ''].includes(data[1])) return false;

    const checkDigitRut = isNaN(Number(data[1])) ? data[1].toUpperCase() : Number(data[1]);
    /**
     * Realizamos multiplicación por los digitos 2, 3, 4, 5, 6, 7
     * El resultado obtenido lo dividimos por 11
     * Tomamos el resultado sin decimales
     * lo multimplicamos por 11
     * Restamos a la suma la multiplicación por 11
     * Luego al 11 le restamos el resultade de la suma - la multiplicación por 11
     * ya con eso conseguimos el valor de verificación del RUT
     *
     * Ahora bien, si como resultado final del digito verficador nos da el número 11, el dígito verificador será 0
     * y si es 10 el dígito será la letra K
     */
    let suma = 0;

    if (digits.length === 8) {
        suma +=
            digits[7] * 2 +
            digits[6] * 3 +
            digits[5] * 4 +
            digits[4] * 5 +
            digits[3] * 6 +
            digits[2] * 7 +
            digits[1] * 2 +
            digits[0] * 3;
    } else {
        suma +=
            digits[6] * 2 +
            digits[5] * 3 +
            digits[4] * 4 +
            digits[3] * 5 +
            digits[2] * 6 +
            digits[1] * 7 +
            digits[0] * 2;
    }

    const restDiv = Math.trunc(suma / 11) * 11;

    let checkDigit = 11 - (suma - restDiv);

    if (checkDigit === 11) {
        checkDigit = 0;
    } else if (checkDigit === 10) {
        checkDigit = "K";
    }

    return checkDigit === checkDigitRut;
};

export { GetDigVer, validatePhoneNumber, cleanText, validation, searchDuplicate, delaySuccess, delayError, getNombres, textErrorAsync, nextStep, bodyTextStyles, limpiarEspacios, convertDataURIToBinary, getClosestScaleNumber, signedBoxWidth, signedBoxHeight, checkRut };
