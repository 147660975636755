import RenderCell from '../atoms/renderCell';
import RenderActions from '../atoms/renderActions';
import RenderStatus from '../atoms/renderStatus';
import RenderCreatedAt from '../atoms/renderCreatedAt';
import WithDocument from '../atoms/withDocument';
import RenderSigners from '../atoms/renderSigners';
import RenderContractDetail from "../atoms/renderContractDetail";

const localizedTextsMap = {
    columnMenuUnsort: "Sin ordenar",
    columnMenuSortAsc: "De menor a mayor",
    columnMenuSortDesc: "De mayor a menor",
    columnMenuFilter: "Filtro",
    columnMenuHideColumn: "Ocultar",
    columnMenuShowColumns: "Mostrar columnas",
    toolbarExport: "Descargar",
    toolbarFilters: "Filtros",
    filterOperatorContains: "Contiene",
    filterOperatorEquals: "Igual a",
    filterOperatorStartsWith: "Empieza con",
    filterOperatorEndsWith: "Termina con",
    filterOperatorIsEmpty: "Esta vacio",
    filterOperatorIsNotEmpty: "No esta vacio",
    filterPanelInputLabel: "Valor",
    filterPanelInputPlaceholder: "Filtrar por valor",
};

const gridColumns = [
    {
        field: "autoId",
        headerAlign: "center",
        headerName: "ID",
        headerClassName: "grid__header-item",
        cellClassName: "grid__row-item text--uppercase-all",
        width: 80,
        renderCell: (params) => <RenderCell item={params.value} />
    },
    {
        field: "sTipoContrato",
        headerAlign: "center",
        headerName: "Tipo de Tramite",
        headerClassName: "grid__header-item",
        cellClassName: "grid__row-item text--uppercase text--center",
        width: 270,
        renderCell: (params) => <RenderContractDetail data={params.row} />
    },
    {
        field: "sTipoFirma",
        headerAlign: "center",
        headerName: "Tipo de Firma",
        headerClassName: "grid__header-item",
        cellClassName: "grid__row-item text--uppercase text--center",
        width: 270,
        renderCell: (params) => <RenderCell item={params.value} />
    },
    {
        field: "dContractDT",
        headerAlign: "center",
        headerName: "Fecha creación",
        headerClassName: "grid__header-item",
        cellClassName: "grid__row-item text--center",
        width: 180,
        disableColumnMenu: true,
        renderCell: (params) => <RenderCreatedAt item={params.value} />
    },
    {
        field: "withDocument",
        headerAlign: "center",
        headerName: "Doct. cargado",
        headerClassName: "grid__header-item",
        cellClassName: "grid__row-item text--center",
        width: 130,
        renderCell: (params) => <WithDocument item={params.value} />
    },
    {
        field: "NumeroFirmantes",
        headerAlign: "center",
        headerName: "Firmantes",
        headerClassName: "grid__header-item",
        rowClassName: "grid__row-item",
        width: 120,
        disableColumnMenu: true,
        renderCell: (params) => <RenderSigners item={params.value} />
    },
    {
        field: "sStatus",
        headerAlign: "center",
        headerName: "Estado",
        headerClassName: "grid__header-item",
        rowClassName: "grid__row-item",
        width: 240,
        disableColumnMenu: true,
        renderCell: (params) => <RenderStatus status={params.value} />
    },
    {
        field: "sContractID",
        headerAlign: "center",
        headerName: "Action",
        headerClassName: "grid__header-item",
        rowClassName: "grid__row-item",
        width: 100,
        disableColumnMenu: true,
        renderCell: (params) => <RenderActions id={params.value} />
    }
];

export {
    gridColumns,
    localizedTextsMap
};