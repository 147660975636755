import { useEffect, useState } from 'react';

const StatusHook = (status) => {

    const [data, setData] = useState(undefined);

    useEffect(() => {

        switch (status) {
            case "PAIDOUT":
                return setData({ class: "status__container approved", text: "Pagado" });
            default:
                return setData({ class: "status__container pending", text: "Pendiente de pago" });
        }

    }, [status]);

    return data;

};

export default StatusHook;