import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Text } from "../../components";

export default function PopUp(props) {
    const {
        open,
        classes,
        onClose,
        ariaLabelledby,
        ariaDescribedby,
        onBackdropClick,
        title,
        children,
        icon,
        height
    } = props;

    const boxStyles = {
        display: 'flex',
        flexDirection: 'column',
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        borderRadius: "15px",
        bgcolor: "white.main",
        // p: 8,
        width: '370px',
        height: height ? height : '335px',
        textAlign: 'center',
        padding: '10px'
    };

    const titleStyles = {
        fontSize: "1.5rem",
        color: "black.main",
        fontWeight: "500"
    };

    return (
        <Modal
            open={open}
            classes={classes}
            onClose={onClose}
            aria-labelledby={ariaLabelledby}
            aria-describedby={ariaDescribedby}
            onBackdropClick={onBackdropClick}
        >
            <Box sx={boxStyles}>
                <div className="popup-icon--top-center">{icon}</div>
                <Text sx={titleStyles} mgButton variant="h5">
                    {title}
                </Text>
                <div className="popup-content" style={{ fontSize: '1.5rem', display: 'flex', flexDirection: 'column' }}>{children}</div>
            </Box>
        </Modal>
    );
}
