import { Fragment, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';

import { BsPencilSquare } from "react-icons/bs";

import { PDFDocument } from "pdf-lib";
import FilePreview from "react-file-preview-latest";

import { DataPreviewStyles } from "./index.css";
import { asyncBase64ToFile, to, typeUser, verifiVariable } from "../../../utils/enums";
import { contractStoreFilesRequest, mergeDocs, sendContractPriotity } from "../../../utils/api";
import { addMemeber, createContract, clearCreateContract } from "../../../store/contract/contractDetail";
import { clearForm } from "../../../store/formTramit";
import Spinner from "../../spinners/Spinner";
import { Context } from "../../../context/utilsContext";
import ScreenSizeHook from "../../../hooks/ScreenSizeHook";
import Text from "../../heading/Text";

const stepNumberStyles = {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#00adea',
    height: '30px',
    width: '30px',
    borderRadius: '5px',
    color: 'white.main',
    fontSize: "1.3rem",
    fontWeight: "500",
    marginBottom: "0",
    marginTop: "0",
    marginRight: '10px'
};

const CustomTextNumber = styled(Typography)((props) => ({
    marginRight: '10px'
}));

const CustomSubtitle1 = styled(Typography)((props) => ({
    fontSize: '0.9rem',
    fontFamily: [
        '"PoppinsBold"'
    ].join(','),
}));

const EditCustomButtom = styled(Button)((props) => ({
    borderRadius: '7px',
    fontStyle: 'normal',
    boxShadow: 'none',
    fontWeight: 'bolder',
    textTransform: 'none',
    fontSize: 16,
    marginTop: '11px',
    marginLeft: '37px',
    background: props.theme.palette.primary.light,
    '&:hover': {
        background: props.theme.palette.primary.light
    },
    fontFamily: [
        '"PoppinsBold"'
    ].join(','),
}));

const DataPreview = () => {
    const { nextPage, setNextPage, toResumen, setToResumen } = useContext(Context);
    const navigate = useNavigate();
    const styles = DataPreviewStyles();
    const dispatch = useDispatch();
    const screenSizeHook = ScreenSizeHook();
    const {
        session: {
            login: {
                user,
                userInfo
            }
        },
        contract: {
            contractCreator: {
                contract: {
                    detail
                }
            }
        },
        formTramit: {
            fourthStep,
            previewPdfSigned,
            thirdStep: {
                fileName
            },
            firstStep: {
                procedureid,
                contractTypeFee,
                typeAgrement
            },
            secondStep
        }
    } = useSelector((state) => state);
    // eslint-disable-next-line
    const [firmantes, setFirmante] = useState(![null, undefined, ''].includes(secondStep) ? secondStep : []);
    const [loading, setLoading] = useState(false);
    const [filePreview, setFilePreview] = useState(undefined);

    const CustomTextTitle = styled(Typography)((props) => ({
        color: props.theme.palette.black.main,
        fontSize: screenSizeHook === 'xs' ? '1rem' : '1.2rem',
        marginBottom: screenSizeHook === 'xs' ? '0px' : '0.35em'
    }));

    const verifyRol = (rol) => {

        if (rol.length === 1) {

            if (rol.includes('Firmante')) {
                return 0;
            } else if (rol.includes('Pagador')) {
                return 3;
            } else if (rol.includes('Pre Aprobador')) {
                return 2;
            }

        } else {

            if (rol.includes('Firmante') && rol.includes('Pagador')) {
                return 0;
            } else if (rol.includes('Pagador') && rol.includes('Pre Aprobador')) {
                return 4;
            }

        }

    }

    const createTramit = () => {

        const { token } = user;

        const contractRequestPayload = {
            sOwnerType: "NATURAL",
            sPaymentStatus: "PENDING",
            iPaymentService: 1,//TODO para peru
            iContractTypeFeeID: contractTypeFee.iContractTypeFeeID,
            iSignedCount: firmantes.length,
            callback: "",
            iAgreementId: 0,
            iPriceId: 0,
            signers: firmantes.map((item) => {

                const firmante = {
                    ...item,
                    order: 1,
                    rol: verifyRol(item['rol']),
                    payment: '0.00',
                    type: 'NATURAL',
                    portion: item['pay']
                };

                delete firmante['pay'];

                return firmante;
            })
        };

        dispatch(addMemeber(contractRequestPayload));
        dispatch(createContract(contractRequestPayload, token, (!verifiVariable(userInfo) && userInfo['iProfileID'] === typeUser.ADMIN) ? typeAgrement['agreementId'] : false));

    }

    useEffect(() => {

        if (previewPdfSigned) asyncBase64ToFile(previewPdfSigned, 'preview').then((file) => setFilePreview(file));

    }, [previewPdfSigned]);

    useEffect(() => {

        if (nextPage !== 0) createTramit();

        return () => {
            setNextPage(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nextPage]);

    useEffect(() => {

        if (!verifiVariable(toResumen)) navigate(toResumen);

        return () => {
            setToResumen(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toResumen]);

    useEffect(() => {

        (async () => {

            try {

                if (![undefined, null, ''].includes(detail)) {

                    setLoading(true);

                    const { token } = user;
                    const pdfDoc = await PDFDocument.load(fourthStep, { ignoreEncryption: true });
                    const formData = new FormData();
                    const { sContractID: contractID } = detail;

                    const randomID = Math.floor(Math.random() * (999999999 - 0));

                    const pdfBytes = await pdfDoc.save();

                    const filePdf = new File([pdfBytes], fileName, { type: 'application/pdf' });

                    formData.append(
                        "fileUp",
                        filePdf,
                        `${contractID}_${randomID}.pdf`
                    );

                    await contractStoreFilesRequest(formData, token);

                    await mergeDocs(contractID, token);

                    await sendContractPriotity(contractID, token);

                    dispatch(clearCreateContract());

                    dispatch(clearForm());

                    navigate(to.CONTRACT_STATUS);

                    setLoading(false);

                }

            } catch (error) {
                console.error(error);
                setLoading(false);
            }

        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detail]);

    return (
        <div className="procedure-creation__form-container">

            {loading && (
                <Spinner
                    loading={true}
                    type="bar"
                    text={"Creando Trámite, por favor espere"}
                    opacity={true}
                />
            )}

            <div className={`${styles.formContainer} form__container`}>

                <Grid container spacing={2}>

                    <Grid item xs={12} sm={5}>

                        <CustomTextTitle variant="h6" gutterBottom textAlign={screenSizeHook === 'xs' ? 'center' : 'unset'}>
                            Resumen de la solicitud
                        </CustomTextTitle>

                    </Grid>

                    <Grid item xs={12} sm={7}>

                        <Typography variant="subtitle1" gutterBottom color={['gray.main']} textAlign={screenSizeHook === 'xs' ? 'center' : 'unset'}>
                            confirme los datos para seguir con la solicitud
                        </Typography>

                    </Grid>

                    <Grid
                        item xs={12}
                        className={styles.titles}
                        sx={{
                            alignItems: (screenSizeHook === 'xs' ? 'center !important' : 'end'),
                            justifyContent: (screenSizeHook === 'xs' ? 'space-between' : 'flex-start')
                        }}
                    >

                        {
                            screenSizeHook === 'xs' &&
                            <div className={`procedure-creation-step step--active`}  >
                                <Text sx={stepNumberStyles} align="left" mgButton variant="h6">
                                    1
                                </Text>
                            </div>
                        }

                        {
                            screenSizeHook !== 'xs' &&
                            <CustomTextNumber variant="h5" gutterBottom color={['primary.light']}>
                                1
                            </CustomTextNumber>
                        }

                        <CustomTextTitle variant="h6" gutterBottom>
                            Datos del trámite
                        </CustomTextTitle>

                        {
                            screenSizeHook !== 'xs' &&
                            <EditCustomButtom variant="contained" onClick={() => navigate(to.CONTRACT_CREATION)}>
                                Editar respuestas
                            </EditCustomButtom>
                        }

                        {
                            screenSizeHook === 'xs' &&
                            <IconButton onClick={() => navigate(to.CONTRACT_CREATION)}>
                                <BsPencilSquare className="icon--blue-light icon--lg" />
                            </IconButton>
                        }

                    </Grid>

                    <Grid item xs={12} /* sm={4} */ className={styles.subTitles}>

                        <CustomSubtitle1 variant="subtitle1" gutterBottom color={['gray.main']}>
                            ¿Qué trámite necesitas gestionar?
                        </CustomSubtitle1>

                        <Typography variant="subtitle1" gutterBottom color={['gray.main']}>
                            {![null, undefined, ''].includes(procedureid) ? procedureid.name : ''}
                        </Typography>

                    </Grid>

                    <Grid item xs={12} /* sm={8} */ className={styles.subTitles}>

                        <CustomSubtitle1 variant="subtitle1" gutterBottom color={['gray.main']}>
                            ¿Qué tipo de firma necesitas?
                        </CustomSubtitle1>

                        <Typography variant="subtitle1" gutterBottom color={['gray.main']}>
                            {![null, undefined, ''].includes(contractTypeFee) ? contractTypeFee.label : ''}
                        </Typography>

                    </Grid>

                    <Grid
                        item
                        xs={12}
                        className={styles.titles}
                        sx={{
                            alignItems: (screenSizeHook === 'xs' ? 'center !important' : 'end'),
                            justifyContent: (screenSizeHook === 'xs' ? 'space-between' : 'flex-start')
                        }}
                    >

                        {
                            screenSizeHook === 'xs' &&
                            <div className={`procedure-creation-step step--active`}  >
                                <Text sx={stepNumberStyles} align="left" mgButton variant="h6">
                                    2
                                </Text>
                            </div>
                        }

                        {
                            screenSizeHook !== 'xs' &&
                            <CustomTextNumber variant="h5" gutterBottom color={['primary.light']}>
                                2
                            </CustomTextNumber>
                        }

                        <CustomTextTitle variant="h6" gutterBottom>
                            Datos participantes
                        </CustomTextTitle>

                        {
                            screenSizeHook === 'xs' &&
                            <IconButton onClick={() => navigate(to.CONTRACT_CREATION_SIGNATORIES)}>
                                <BsPencilSquare className="icon--blue-light icon--lg" />
                            </IconButton>
                        }

                        {
                            screenSizeHook !== 'xs' &&
                            <EditCustomButtom variant="contained" onClick={() => navigate(to.CONTRACT_CREATION_SIGNATORIES)}> Editar respuestas </EditCustomButtom>
                        }

                    </Grid>

                    {firmantes.map((item, index) => (
                        <Fragment key={item.rutId} >

                            <Grid item xs={12} className={styles.subTitles}>

                                <CustomSubtitle1 variant="subtitle1" gutterBottom color={['primary.light']}>
                                    Participante {index + 1}
                                </CustomSubtitle1>

                            </Grid>

                            <Grid item xs={12} sm={4} className={styles.subTitles}>

                                <CustomSubtitle1 variant="subtitle1" gutterBottom color={['gray.main']}>
                                    Nombre
                                </CustomSubtitle1>

                                <Typography variant="subtitle1" gutterBottom color={['gray.main']}>
                                    {item.full_name}
                                </Typography>

                            </Grid>

                            <Grid item xs={12} sm={8} className={styles.subTitles}>

                                <CustomSubtitle1 variant="subtitle1" gutterBottom color={['gray.main']}>
                                    RUC (sin puntos y con guión) o Pasaporte (solo números)
                                </CustomSubtitle1>

                                <Typography variant="subtitle1" gutterBottom color={['gray.main']}>
                                    {item.rutId}
                                </Typography>

                            </Grid>

                            <Grid item xs={12} sm={4} className={styles.subTitles}>

                                <CustomSubtitle1 variant="subtitle1" gutterBottom color={['gray.main']}>
                                    Correo electrónico
                                </CustomSubtitle1>

                                <Typography variant="subtitle1" gutterBottom color={['gray.main']}>
                                    {item.email}
                                </Typography>

                            </Grid>

                            <Grid item xs={12} sm={8} className={styles.subTitles}>

                                <CustomSubtitle1 variant="subtitle1" gutterBottom color={['gray.main']}>
                                    Número de celular
                                </CustomSubtitle1>

                                <Typography variant="subtitle1" gutterBottom color={['gray.main']}>
                                    {item.phone}
                                </Typography>

                            </Grid>


                            <Grid item xs={12} sm={8} className={styles.subTitles}>

                                <CustomSubtitle1 variant="subtitle1" gutterBottom color={['gray.main']}>
                                    Rol del participante
                                </CustomSubtitle1>

                                <Typography variant="subtitle1" gutterBottom color={['gray.main']}>
                                    {item.rol.toString()}
                                </Typography>

                            </Grid>

                        </Fragment>
                    ))}


                    <Grid
                        item
                        xs={12}
                        className={styles.titles}
                        sx={{
                            alignItems: (screenSizeHook === 'xs' ? 'center !important' : 'end'),
                            justifyContent: (screenSizeHook === 'xs' ? 'space-between' : 'flex-start')
                        }}
                    >


                        {
                            screenSizeHook === 'xs' &&
                            <div className={`procedure-creation-step step--active`}  >
                                <Text sx={stepNumberStyles} align="left" mgButton variant="h6">
                                    3
                                </Text>
                            </div>
                        }

                        {
                            screenSizeHook !== 'xs' &&
                            <CustomTextNumber variant="h5" gutterBottom color={['primary.light']}>
                                3
                            </CustomTextNumber>
                        }

                        <CustomTextTitle variant="h6" gutterBottom textAlign={'left'}>
                            Documentos
                        </CustomTextTitle>

                        {
                            screenSizeHook === 'xs' &&
                            <IconButton onClick={() => navigate(to.CONTRACT_CREATION_DOCUMENTS)}>
                                <BsPencilSquare className="icon--blue-light icon--lg" />
                            </IconButton>
                        }

                        {
                            screenSizeHook !== 'xs' &&
                            <EditCustomButtom variant="contained" onClick={() => navigate(to.CONTRACT_CREATION_DOCUMENTS)}> Cambiar documento </EditCustomButtom>
                        }

                    </Grid>

                    {filePreview && (
                        <Grid
                            item
                            xs={12}
                            sx={{
                                '& > div': {
                                    width: screenSizeHook === 'xs' ? '300px !important' : '424px !important'
                                }
                            }}
                        >

                            <FilePreview
                                type={"file"}
                                file={filePreview}
                                onError={() => console.log('error')}
                            />

                        </Grid>
                    )}

                </Grid>

            </div>

        </div>
    );
}

export default DataPreview;