import React, { Fragment, useContext, useEffect } from "react";

import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import AddBoxIcon from '@mui/icons-material/AddBox';

import ScreenSizeHook from "../../../../../hooks/ScreenSizeHook";
import Text from "../../../../heading/Text";
import { theme } from "../../../../../theme/theme";
import { Context } from "../../../../../context/utilsContext";


const ActionsButton = styled(Button)({
    fontStyle: 'normal',
    boxShadow: 'none',
    fontWeight: 'bolder',
    textTransform: 'none',
    fontSize: 16,
    padding: '11px 28px',
    border: '1px solid',
    borderRadius: '7px',
    lineHeight: 1.5,
    backgroundColor: theme.palette.primary.light,
    // backgroundColor: '#0063cc',
    borderColor: theme.palette.primary.light,
    fontFamily: [
        '"PoppinsBold"'
    ].join(','),
    '&:hover': {
        // backgroundColor: '#0069d9',
        backgroundColor: theme.palette.primary.xlight,
        // borderColor: '#0062cc',
        borderColor: theme.palette.primary.xlight,
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#0062cc',
        borderColor: '#005cbf',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
});

const AddParticipantButton = styled(Button)((props) => ({
    borderRadius: '7px',
    fontStyle: 'normal',
    boxShadow: 'none',
    fontWeight: 'bolder',
    textTransform: 'none',
    fontSize: '0.9rem',
    padding: '11px 28px',
    width: '95%',
    fontFamily: [
        '"PoppinsBold"'
    ].join(','),
}));

const StepTrackerIndicator = ({ current, step, title, subtitle, onClick, cursor, disableNextButtoms, disableBackButtom, goBack }) => {
    const screenSizeHook = ScreenSizeHook();
    const { setNextPage, nextPage, addParticipant, setAddParticipant } = useContext(Context);

    const widthItem = ['xl'].includes(screenSizeHook) ? 270 : 260;
    const widthtrackItem = 295;


    const CustomTextStep4 = styled(Typography)((props) => ({
        marginTop: ['lg', 'xl', 'xs'].includes(screenSizeHook) ? '16px' : '7px',
        color: props.theme.palette.black.main,
        fontWeight: 100
    }));

    const stepContainerActionsButtoms = {
        marginTop: '25px',
        width: `${widthItem}px`,
        display: 'flex',
        justifyContent: 'flex-start',
        // marginLeft: '13px',
        marginBottom: '19px'
    }

    const stepNumberStyles = {
        color: current ? "white.main" : "primary.light",
        fontSize: "1.3rem",
        fontWeight: "500",
        marginBottom: "0",
        marginTop: "0",
    };

    const titleStyles = {
        fontFamily: 'PoppinsBold',
        color: "black.main",
        fontWeight: "500",
        marginBottom: "0",
        marginTop: "0",
        fontSize: '1rem'
    };

    const subTitleStyles = {
        color: "quinary.dark",
        fontWeight: "300",
        marginBottom: "0",
        fontSize: '0.9rem'
    };

    const trackItem = {
        paddingTop: '15px',
        width: `${widthtrackItem}px`,
        flexDirection: 'row',
        cursor
    };

    const containerAddparticipant = {
        width: `${widthtrackItem}px`,
        marginTop: ['lg', 'xl', 'xs'].includes(screenSizeHook) ? '20px' : '0px'
    }

    useEffect(() => {
        setAddParticipant(0);

        return () => {
            setAddParticipant(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setNextPage(0);

        return () => {
            setNextPage(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>


            <div className="procedure-creation__track-item" onClick={onClick} style={trackItem}>

                <div
                    className={`procedure-creation-step ${current ? "step--active" : ""
                        }`}
                >
                    <Text sx={stepNumberStyles} align="left" mgButton variant="h6">
                        {step}
                    </Text>
                </div>

                <div className="procedure-creation-description" style={{ marginLeft: '6px' }}>
                    <Text sx={titleStyles} align="left" mgButton variant="h7" color={false}>
                        {title}
                    </Text>
                    <Text sx={subTitleStyles}>{subtitle}</Text>
                </div>

            </div>

            {step === '2' && (<Stack direction="row" spacing={2} style={containerAddparticipant}>

                <AddParticipantButton
                    variant="outlined"
                    startIcon={<AddBoxIcon className="icon--blue icon--lg" />}
                    fullWidth
                    onClick={() => {
                        setAddParticipant((1 + addParticipant));
                    }}
                >
                    Agregar participante
                </AddParticipantButton>

            </Stack>)}

            {step === '4' && (<CustomTextStep4 variant="subtitle2" gutterBottom>
                Recuerde asignar la firma de los participantes en cada cédula además del espacio en donde tiene que firmar.
            </CustomTextStep4>)}

            <Stack style={stepContainerActionsButtoms} spacing={2} direction="row">

                <ActionsButton variant="contained" color="primary" disabled={disableBackButtom} onClick={() => goBack()}>
                    Volver
                </ActionsButton>

                <ActionsButton disabled={disableNextButtoms} variant="contained" color="primary" onClick={() => {

                    setNextPage((1 + nextPage));

                }}>
                    {step === '5' ? 'Finalizar' : 'Siguiente'}
                </ActionsButton>

            </Stack>

        </Fragment>
    );
};

export default StepTrackerIndicator;
