import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";

import { BsFillTrashFill } from "react-icons/bs";
import { FaPen } from "react-icons/fa";

import { Box } from "@mui/material";

import { styles } from "../../../../../helpers";

import { Context } from "../../../../../context/utilsContext";
import { getEditSigner, saveFormInfo } from "../../../../../store/formTramit";



const RenderActions = ({ item }) => {
    const {
        formTramit: {
            secondStep
        }
    } = useSelector((state) => state);
    const dispatch = useDispatch();

    const { deleteParticipant, setDeleteParticipant, setAddParticipant, addParticipant } = useContext(Context);


    const removeParticipant = () => {

        const arrayData = [...secondStep];

        dispatch(saveFormInfo({ indexe: 'secondStep', value: arrayData.filter((signatori) => signatori['rutId'] !== item['rutId']) }));

        setDeleteParticipant((1 + deleteParticipant));
    }

    return (
        <Box
            sx={styles.actionButtonsContainerStyles}
            className="flex--1 flex--center"
        >

            <FaPen
                className="icon--blue-light"
                style={{
                    marginRight: '10px',
                    cursor: 'pointer'
                }}
                onClick={() => {
                    dispatch(getEditSigner(item));
                    setAddParticipant((1 + addParticipant));
                }}
            />

            <BsFillTrashFill className="icon--red" style={{ cursor: 'pointer' }} onClick={() => removeParticipant()} />

        </Box>
    );
};

export default RenderActions;
