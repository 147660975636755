import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { MdLockOutline, MdLockOpen } from "react-icons/md";

import Grid from '@mui/material/Grid';

import _ from "lodash";

import { PopUp, Text, Btn } from "../../components";
import { bodyTextStyles, validation } from "../../utils/validations";
import { to, apiMessage, optionsSteps } from "../../utils/enums";
import { store } from "../../store/storeConfig";
//import { ReactComponent as GoogleIcon} from "../../assets/icons/google.svg";
import { logSession } from "../../store/auth/userLogin";
import { Steps } from 'intro.js-react';
import stepsLogin from "../toursSteps/tourLogin";

// import GoogleLogin from "react-google-login";


const SessionForm = () => {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalDescription, setModalDescription] = useState("");
    const [modalButtonText, setModalButtonText] = useState("");
    const [checkLogin, setCheckLogin] = useState(false);
    const [stepsEnabled, setStepsEnabled] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        setStepsEnabled(false);
        if (checkLogin) {
            const unsubscribe = store.subscribe(() => {
                const { login } = store.getState().session;

                if (!_.isEmpty(login.user)) {
                    if (login.user.logged) {
                        localStorage.setItem("session", JSON.stringify(login.user));
                        navigate(to.DASHBOARD_WELCOME);
                    }
                }

                if (login.message === apiMessage.WRONG_LOGIN) {
                    setModalTitle("Ha ocurrido un error");
                    setModalDescription("Por favor verifica tu correo y contraseña.");
                    setModalButtonText("Volver a intentar");
                    setShowModal(true);
                    setLoading(false);
                }
            });

            return () => unsubscribe();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkLogin]);

    // const oauthOnSubmit = (response) => {
    //     setLoading(true);

    //     if (response?.error) {
    //         setShowModal(true);
    //         setLoading(false);
    //     } else {
    //         store.dispatch(
    //             logSession({
    //                 login: response.profileObj?.email,
    //                 password: response.tokenId,
    //             }),
    //         );
    //     }
    // };

    const onSubmit = (data) => {
        setLoading(true);

        store.dispatch(
            logSession({
                login: data.login,
                password: data.password,
            }),
        );

        setCheckLogin(true);
    };

    const togglePasswordView = () => {
        setShowPassword(!showPassword);
    };

    const renderInputPasswordButton = () => {
        if (showPassword) {
            return (
                <MdLockOpen
                    className="icon--md icon--blue icon-pointer"
                    onClick={togglePasswordView}
                />
            );
        } else {
            return (
                <MdLockOutline
                    className="icon--md icon--blue icon-pointer"
                    onClick={togglePasswordView}
                />
            );
        }
    };

    const handleModal = () => {
        setShowModal(!showModal);
        setModalTitle("");
        setModalDescription("");
        setModalButtonText("");
    };

    const displayModal = () => {
        return (
            <PopUp
                open={showModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                title={modalTitle}
                onBackdropClick={handleModal}
            >
                <Text
                    sx={bodyTextStyles}
                    align="center"
                    variant="body1"
                    paragraph
                >
                    {modalDescription}
                </Text>
                <Btn
                    variant="contained"
                    text={modalButtonText}
                    size="lg"
                    color="primary"
                    type="submit"
                    buttonClass="button-submit"
                    onClick={handleModal}
                    fullWidth
                />
            </PopUp>
        );
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>

            <Steps
                enabled={stepsEnabled}
                steps={stepsLogin}
                initialStep={0}
                onExit={() => setStepsEnabled(false)}
                options={optionsSteps}
            />

            <Grid container justifyContent={'center'}>

                <Grid item xs={12} sm={9} md={8} sx={{ marginRight: 2, marginLeft: 2 }}>
                    <div className="input-container">
                        <label>Correo electrónico</label>
                        <input
                            type="text"
                            className={errors.login && "input-container--error"}
                            placeholder="tucorreo@mail.com"
                            {...register("login", {
                                required: true,
                                pattern: validation.email,
                            })}
                        />
                        {errors.login?.type === "required" && (
                            <span className="input--error">
                                Este campo es obligatorio
                            </span>
                        )}
                        {errors.login?.type === "pattern" && (
                            <span className="input--error">
                                Ingresa un correo electrónico valido
                            </span>
                        )}
                    </div>
                </Grid>

                <Grid item xs={12} sm={9} md={8} sx={{ marginRight: 2, marginLeft: 2 }}>
                    <div className="input-container">
                        <label>Contraseña</label>
                        <div className="input-container">
                            <div className="input-container--relative">
                                <input
                                    type={!showPassword ? "password" : "text"}
                                    className={
                                        errors.password && "input-container--error"
                                    }
                                    {...register("password", { required: true })}
                                />
                                <div className="input-icon--right show-password">
                                    {renderInputPasswordButton()}
                                </div>
                            </div>
                            {errors.password?.type === "required" && (
                                <span className="input--error">
                                    Este campo es obligatorio
                                </span>
                            )}
                        </div>
                    </div>
                </Grid>

                <Grid item xs={12} sm={9} md={8} sx={{ marginRight: 2, marginLeft: 2 }}>
                    <div className="input-container flex--around">
                        <div className="flex--start">
                            <input
                                type="checkbox"
                                {...register("recover_account")}
                            />
                            <span>Recuérdame</span>
                        </div>
                        <Link to="/recover-account" className="option-password-recovery">
                            Haz olvidado tu contraseña
                        </Link>
                    </div>
                </Grid>

                <Grid item xs={12} sm={9} md={8} sx={{ marginRight: 2, marginLeft: 2 }}>
                    <Btn
                        variant="contained"
                        text="Ingresar"
                        size="lg"
                        color="primary"
                        type="submit"
                        buttonClass="button-submit buttom-login"
                        loading={loading}
                        fullWidth
                    />
                </Grid>

                <div className="flex--center oaauth-container" sx={{ marginRight: 2, marginLeft: 2 }}>
                    {/* <Btn
                        text="Inicio con Google"
                        size="lg"
                        color="black"
                        type="submit"
                        buttonClass="button-oaauth-signup"
                        startIcon={<GoogleIcon height="21px" />}
                        fullWidth
                    /> */}
                    {/* <GoogleLogin
                        clientId="57861268598-6up8nlrg14mij17ogpf3hmmui62o9igb.apps.googleusercontent.com"
                        buttonText="Login con google"
                        render={(renderProps) => (
                            <Btn
                                text="Inicio con Google"
                                size="lg"
                                color="black"
                                type="submit"
                                buttonClass="button-oaauth-signup"
                                startIcon={<GoogleIcon height="21px" />}
                                onClick={renderProps.onClick}
                                fullWidth
                            />
                        )}
                        onSuccess={oauthOnSubmit}
                        onFailure={oauthOnSubmit}
                        cookiePolicy={"single_host_origin"}
                    /> */}
                </div>

                <div className="flex--center signup-link option-register" sx={{ marginRight: 2, marginLeft: 2 }}>
                    <span className="whitespace--right-md">
                        ¿Nuevo en nuestra plataforma?
                    </span>
                    <Link to="/signup-account">Crea una cuenta</Link>
                </div>



            </Grid>

            {displayModal()}

        </form>
    );
}

export default SessionForm;
