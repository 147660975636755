import React, { useState, Fragment } from "react";
import { Stack, ToggleButton, ButtonGroup } from "@mui/material";
import { BsChevronDown } from "react-icons/bs";
import { FaSignOutAlt } from "react-icons/fa";
import { useNavigate, useLocation } from "react-router-dom";
import storage from "redux-persist/lib/storage";

import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import Typography from '@mui/material/Typography';

import {
    dashboardMenu,
    manageProcedures,
    path,
    to,
    typeUser,
} from "../../utils/enums";
import { Btn, PopUp, Text } from "../../components";
import { endSession } from "../../store/auth/userLogin";
import { ReactComponent as StackIcon } from "../../assets/icons/stack.svg";
import { ReactComponent as DocIcon } from "../../assets/icons/doc.svg";
import { ReactComponent as ArchiveIcon } from "../../assets/icons/archive.svg";
import NavLink from "../../components/routing/NavLink";
import { bodyTextStyles } from "../../utils/validations";
import ModalUpdateUserPerfil from "../modalUpdateUserPerfil/ModalUpdateUserPerfil";
import { getUserInfo } from "../storeSelector/storeSelector";

const Menuaside = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [showModal, setShowModal] = useState(false);
    const [actionModal, setActionModal] = useState("");
    const [expanded, setExpanded] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalMessage, setModalMessage] = useState("");
    const [modalButtonTitle, setModalButtonTitle] = useState("");
    const userInfo = getUserInfo();

    const styleAccordionConfig = {
        width: "100%",
        boxShadow: "none",
        border: "none",
        "&:before": {
            display: "none",
        },
    };

    const styleAccordionSummaryConfig = {
        padding: "0",
        margin: "0",
    };

    const styleButtonGroupConfig = {
        width: "100%",
        padding: "0 15px",
        paddingTop: "10px",
    };

    const styleToggleButtonConfig = {
        textTransform: "none",
        fontSize: "1rem",
        padding: "12px 8px",
        border: "none",
        justifyContent: "space-between",
    };

    const styleButtonConfig = {
        textTransform: "none",
        fontSize: "1rem",
        padding: "12px 8px",
        borderBottom: "0px",
        justifyContent: "flex-start",
        color: "quinary.xdark",
    };

    // const openTab = (url) => window.open(url, "_blank");

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const endUserSession = () => {
        endSession();

        if ("caches" in window) {
            caches.keys().then((names) => {
                // Delete all the cache files
                names.forEach((name) => {
                    caches.delete(name);
                });
            });
        }

        storage.removeItem('persist:root');
        storage.removeItem('session');
        window.location.reload(true);

        if (!localStorage.getItem("session")) {
            navigate(to.HOME);
        }

    };

    const openModal = (msg) => {
        switch (msg) {
            case "endSession":
                setModalTitle("Cerrar sesión");
                setModalMessage(
                    "¿Estas a punto de cerrar tu sesión, estas seguro(a) de esto?"
                );
                setModalButtonTitle("Si, cerrar sesión");
                setActionModal("endSession");
                break;
            default:
                setModalTitle("");
                setModalMessage("");
                setModalButtonTitle("");
                break;
        }
        setShowModal(true);
    };

    const handleModal = () => {
        switch (actionModal) {
            case "endSession":
                endUserSession();
                break;
            default:
                endUserSession();
                break;
        }
    };

    const displayModal = () => {
        return (
            <PopUp
                open={showModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                title={modalTitle}
                onBackdropClick={handleModal}
            >
                <Text
                    sx={bodyTextStyles}
                    align="center"
                    variant="body1"
                    paragraph
                >
                    {modalMessage}
                </Text>
                <Btn
                    variant="contained"
                    text={modalButtonTitle}
                    size="lg"
                    sx={{ marginTop: 6 }}
                    color="primary"
                    type="submit"
                    buttonClass="button-submit"
                    onClick={handleModal}
                />
            </PopUp>
        );
    };

    return (
        <Fragment>
            <div className="menu-aside__container">
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={0}
                    sx={styleButtonGroupConfig}
                >
                    <ToggleButton
                        onClick={() => navigate(to.DASHBOARD_WELCOME)}
                        value={path.DASHBOARD_WELCOME}
                        fullWidth={true}
                        selected={
                            location === to.DASHBOARD_WELCOME ? true : false
                        }
                        color={
                            location === to.DASHBOARD_WELCOME
                                ? "primary"
                                : "standard"
                        }
                        sx={styleToggleButtonConfig}
                    >
                        <NavLink
                            to={to.DASHBOARD_WELCOME}
                            className="flex--center"
                            inactiveClassName="secctionlink--inactive"
                            activeClassName="secctionlink--active"
                        >
                            <StackIcon className="icon--xs icon--grey-dark" />
                            <span className="menu-aside__item-text">Home</span>
                        </NavLink>
                    </ToggleButton>

                    {/* <ToggleButton
                        onClick={() => navigate(to.DASHBOARD_HOME)}
                        value={dashboardMenu.MENU_ITEM_HOME}
                        fullWidth={true}
                        selected={location === to.DASHBOARD_HOME ? true : false}
                        color={
                            location === to.DASHBOARD_HOME
                                ? "primary"
                                : "standard"
                        }
                        sx={styleToggleButtonConfig}
                    >
                        <NavLink
                            to={to.DASHBOARD_HOME}
                            className="flex--center"
                            inactiveClassName="secctionlink--inactive"
                            activeClassName="secctionlink--active"
                        >
                            <StackIcon className="icon--xs icon--grey-dark" />
                            <span className="menu-aside__item-text">
                                Dashboard
                            </span>
                        </NavLink>
                    </ToggleButton> */}

                    <Accordion
                        expanded={expanded === dashboardMenu.MENU_ITEM_MANAGE}
                        onChange={handleAccordionChange(
                            dashboardMenu.MENU_ITEM_MANAGE
                        )}
                        sx={styleAccordionConfig}
                    >
                        <AccordionSummary
                            sx={styleAccordionSummaryConfig}
                            focusVisibleClassName="menu-aside__item-focus"
                        >
                            <ToggleButton
                                value={dashboardMenu.MENU_ITEM_MANAGE}
                                fullWidth={true}
                                sx={styleToggleButtonConfig}
                            >
                                <NavLink
                                    to={to.MANAGEMENT_PROCEDURE_DASHBOARD_HOME}
                                    className="flex--center"
                                    inactiveClassName="secctionlink--inactive"
                                    activeClassName="secctionlink--active"
                                >
                                    <DocIcon className="icon--xs icon--grey-dark" />
                                    <span className="menu-aside__item-text">
                                        Gestión de trámites
                                    </span>
                                </NavLink>
                                <BsChevronDown className="icon--xs icon--blue-light" />
                            </ToggleButton>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ul className="menu-aside__list">
                                <li>
                                    <NavLink
                                        to={to.CONTRACT_CREATION}
                                        className="navlink"
                                        inactiveClassName="navlink--inactive"
                                        activeClassName="navlink--active"
                                    >
                                        {manageProcedures.PROCEDURES_CREATION}
                                    </NavLink>
                                </li>

                                {
                                    userInfo.profileId === typeUser.ADMIN && (
                                        <Fragment>
                                            <li>
                                                <NavLink
                                                    to={to.CREATE_SCHEDULE}
                                                    className="navlink"
                                                    inactiveClassName="navlink--inactive"
                                                    activeClassName="navlink--active"
                                                >
                                                    {manageProcedures.SCHEDULE_CREATION}
                                                </NavLink>
                                            </li>

                                            <li>
                                                <NavLink
                                                    to={to.MANAGE_SCHEDULE_HISTORY}
                                                    className="navlink"
                                                    inactiveClassName="navlink--inactive"
                                                    activeClassName="navlink--active"
                                                >
                                                    {manageProcedures.MANAGE_SCHEDULE}
                                                </NavLink>
                                            </li>
                                        </Fragment>
                                    )
                                }

                                <li>
                                    <NavLink
                                        to={to.CONTRACT_STATUS}
                                        className="navlink"
                                        inactiveClassName="navlink--inactive"
                                        activeClassName="navlink--active"
                                    >
                                        {manageProcedures.MANAGE_REVIEWS}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to={to.CONTRACT_APROVED}
                                        className="navlink"
                                        inactiveClassName="navlink--inactive"
                                        activeClassName="navlink--active"
                                    >
                                        {manageProcedures.MANAGE_PAYMENTS}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to={to.CONTRACT_SIGNATURE_PENDING}
                                        className="navlink"
                                        inactiveClassName="navlink--inactive"
                                        activeClassName="navlink--active"
                                    >
                                        {manageProcedures.MANAGE_SIGNETURES}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to={to.MANAGE_NOTARY}
                                        className="navlink"
                                        inactiveClassName="navlink--inactive"
                                        activeClassName="navlink--active"
                                    >
                                        {manageProcedures.MANAGE_NOTARIES}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to={to.MANAGE_DELIVERY}
                                        className="navlink"
                                        inactiveClassName="navlink--inactive"
                                        activeClassName="navlink--active"
                                    >
                                        {manageProcedures.MANAGE_DELIVERY}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to={to.DOCUMENT_MANAGE}
                                        className="navlink"
                                        inactiveClassName="navlink--inactive"
                                        activeClassName="navlink--active"
                                    >
                                        {manageProcedures.MANAGE_DOCUMENTATION}
                                    </NavLink>
                                </li>
                            </ul>
                        </AccordionDetails>
                    </Accordion>

                    {/* <ToggleButton
                        onClick={() => navigate(to.FV_AVANZADA)}
                        value={dashboardMenu.MENU_ITEM_HOME}
                        fullWidth={true}
                        selected={location === to.DASHBOARD_HOME ? true : false}
                        color={
                            location === to.DASHBOARD_HOME
                                ? "primary"
                                : "standard"
                        }
                        sx={styleToggleButtonConfig}
                    >
                        <NavLink
                            to={to.FV_AVANZADA}
                            className="flex--center"
                            inactiveClassName="secctionlink--inactive"
                            activeClassName="secctionlink--active"
                        >
                            <StackIcon className="icon--xs icon--grey-dark" />
                            <span className="menu-aside__item-text">
                                {manageProcedures.REGISTER_FEA}
                            </span>
                        </NavLink>
                    </ToggleButton> */}
                    {userInfo.profileId === typeUser.ADMIN ? (
                        <Accordion
                            expanded={
                                expanded ===
                                dashboardMenu.MENU_ITEM_ADMINISTRATION
                            }
                            onChange={handleAccordionChange(
                                dashboardMenu.MENU_ITEM_ADMINISTRATION
                            )}
                            sx={styleAccordionConfig}
                        >
                            <AccordionSummary sx={styleAccordionSummaryConfig}>
                                <ToggleButton
                                    onClick={() =>
                                        navigate(
                                            to.ADMINISTRATION_DASHBOARD_HOME
                                        )
                                    }
                                    value={
                                        dashboardMenu.MENU_ITEM_ADMINISTRATION
                                    }
                                    fullWidth={true}
                                    sx={styleToggleButtonConfig}
                                >
                                    <NavLink
                                        to={to.ADMINISTRATION_DASHBOARD_HOME}
                                        className="flex--center"
                                        inactiveClassName="secctionlink--inactive"
                                        activeClassName="secctionlink--active"
                                    >
                                        <ArchiveIcon className="icon--xs icon--grey-dark" />
                                        <span className="menu-aside__item-text">
                                            Administración
                                        </span>
                                    </NavLink>
                                    <BsChevronDown className="icon--xs icon--blue-light" />
                                </ToggleButton>
                            </AccordionSummary>
                            <AccordionDetails>
                                <ul className="menu-aside__list">
                                    <li>
                                        <NavLink
                                            to={to.MANAGE_USERS}
                                            className="navlink"
                                            inactiveClassName="navlink--inactive"
                                            activeClassName="navlink--active"
                                        >
                                            {manageProcedures.MANAGE_USERS}
                                        </NavLink>
                                    </li>
                                    {/* <li>
                                    <NavLink
                                        to={to.MANAGE_CLIENTS}
                                        className="navlink"
                                        inactiveClassName="navlink--inactive"
                                        activeClassName="navlink--active"
                                    >
                                        {manageProcedures.MANAGE_CLIENTS}
                                    </NavLink>
                                </li> */}

                                    <li>
                                        <NavLink
                                            to={to.MANAGE_PROVIDERS}
                                            className="navlink"
                                            inactiveClassName="navlink--inactive"
                                            activeClassName="navlink--active"
                                        >
                                            {manageProcedures.MANAGE_SUPPLIERS}
                                        </NavLink>
                                    </li>

                                    <li>
                                        <NavLink
                                            to={to.MANAGE_PAYMENT_HISTORY}
                                            className="navlink"
                                            inactiveClassName="navlink--inactive"
                                            activeClassName="navlink--active"
                                        >
                                            {
                                                manageProcedures.MANAGE_PAYMENT_HISTORY
                                            }
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to={to.MANAGE_PROCEDURES_HISTORY}
                                            className="navlink"
                                            inactiveClassName="navlink--inactive"
                                            activeClassName="navlink--active"
                                        >
                                            {
                                                manageProcedures.MANAGE_PROCEDURES_HISTORY
                                            }
                                        </NavLink>
                                    </li>
                                    {/* <li>
                                    <NavLink
                                        to={to.MANAGE_INTEGRATION}
                                        className="navlink"
                                        inactiveClassName="navlink--inactive"
                                        activeClassName="navlink--active"
                                    >
                                        {manageProcedures.MANAGE_INTEGRATION}
                                    </NavLink>
                                </li> */}
                                    {/* <li>
                                    <NavLink
                                        to={to.DISCOUNT_COUPONS}
                                        className="navlink"
                                        inactiveClassName="navlink--inactive"
                                        activeClassName="navlink--active"
                                    >
                                        {manageProcedures.DISCOUNT_COUPONS}
                                    </NavLink>
                                </li> */}
                                    {
                                        <li>
                                            <NavLink
                                                to={to.MANAGE_AGREEMENTS}
                                                className="navlink"
                                                inactiveClassName="navlink--inactive"
                                                activeClassName="navlink--active"
                                            >
                                                {
                                                    manageProcedures.MANAGE_AGREEMENTS
                                                }
                                            </NavLink>
                                        </li>
                                    }

                                </ul>
                            </AccordionDetails>
                        </Accordion>
                    ) : null}

                </Stack>

                <ButtonGroup
                    variant="text"
                    orientation="vertical"
                    sx={styleButtonGroupConfig}
                >
                    <ModalUpdateUserPerfil styledButton={styleButtonConfig} />

                    <br />

                    <Btn
                        text="Cerrar sesión"
                        buttonClass="button-link"
                        startIcon={
                            <FaSignOutAlt className="icon--xs icon--grey-dark" />
                        }
                        onClick={() => openModal("endSession")}
                        sx={styleButtonConfig}
                    />

                </ButtonGroup>

                {/* <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={0}
                    sx={styleButtonGroupConfig}
                >
                    <Accordion
                        expanded={
                            expanded ===
                            dashboardMenu.MENU_ITEM_MANAGE_HELP
                        }
                        onChange={handleAccordionChange(
                            dashboardMenu.MENU_ITEM_MANAGE_HELP
                        )}
                        sx={styleAccordionConfig}
                    >
                        <AccordionSummary sx={styleAccordionSummaryConfig}>
                            <ToggleButton
                                fullWidth={true}
                                sx={styleToggleButtonConfig}
                            >
                                <NavLink
                                    to={to.ADMINISTRATION_DASHBOARD_HOME}
                                    className="flex--center"
                                    inactiveClassName="secctionlink--inactive"
                                    activeClassName="secctionlink--active"
                                >
                                    <FaQuestion className="icon--xs icon--grey-dark" />
                                    <span className="menu-aside__item-text">
                                        Ayuda
                                    </span>
                                </NavLink>
                                <BsChevronDown className="icon--xs icon--blue-light" />
                            </ToggleButton>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ul className="menu-aside__list">
                                <li onClick={() => openTab('https://api.whatsapp.com/send/?phone=56945227304&text&type=phone_number&app_absent=0')}>
                                    <NavLink

                                        to={"#"}
                                        className="navlink"
                                        inactiveClassName="navlink--inactive"
                                        activeClassName="navlink--active"
                                    >
                                        Comercial
                                    </NavLink>
                                </li>

                                <li onClick={() => openTab('https://api.whatsapp.com/send/?phone=56930139035&text&type=phone_number&app_absent=0')}>
                                    <NavLink
                                        to={"#"}
                                        className="navlink"
                                        inactiveClassName="navlink--inactive"
                                        activeClassName="navlink--active"
                                    >
                                        Soporte
                                    </NavLink>
                                </li>

                                <li>
                                    <HelpTramits
                                        title={'Seguir estatus de tramites'}
                                        url={
                                            "https://www.loom.com/embed/0299ee5541c54023b5a478d67fc51a34"
                                        }
                                    />
                                </li>

                                <li>
                                    <HelpTramits
                                        title={'Cómo firman el documento'}
                                        url={
                                            "https://www.loom.com/embed/79771c02fbb647b289cb3925ada57fdd"
                                        }
                                    />
                                </li>

                                <li>
                                    <HelpTramits
                                        title={'Cómo pagar el servicio'}
                                        url={
                                            "https://www.loom.com/embed/787f477dd26d4a298f274551586b504b"
                                        }
                                    />
                                </li>

                                <li>
                                    <HelpTramits
                                        title={'Cómo aprobar los trámites'}
                                        url={
                                            "https://www.loom.com/embed/ff6a0e62962a4a0d8432a99292b50496"
                                        }
                                    />
                                </li>

                                <li>
                                    <HelpTramits
                                        title={'Cómo crear trámites'}
                                        url={
                                            "https://www.loom.com/embed/0299ee5541c54023b5a478d67fc51a34"//TODO verificar
                                        }
                                    />
                                </li>
                            </ul>
                        </AccordionDetails>

                    </Accordion>

                </Stack> */}

                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Typography variant="subtitle1" color={'quinary.dark'} gutterBottom>
                        Versión: 1.0.0
                    </Typography>

                </Stack>

            </div>
            {displayModal()}
        </Fragment>
    );
};

export default Menuaside;
