import React, { useState } from "react";
import { useSelector } from "react-redux";
import { BiSubdirectoryLeft } from "react-icons/bi";

import Button from "@material-ui/core/Button";
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import Confirm from "../confirm";
import { FormularioSolicitudStyles } from "../../index.css";

const TypeForm = ({ children, signers, otherSteps, watch, lastPage }) => {
  const {
    session: {
      login: {
        user
      }
    },
    contract: {
      contractCreator: {
        contract
      }
    },
    formSolicitud: {
      // eslint-disable-next-line
      errorStep
    },
  } = useSelector((state) => state);
  const styles = FormularioSolicitudStyles();
  const [fields, setFields] = useState(0);
  const [createContract, setCreateContract] = useState(false);
  children = [...children, ...signers, ...otherSteps, <Confirm watch={watch} />];

  const nextField = () => {
    if (fields < children.length) setFields((prev) => prev + 1);
  };

  /* const nextField = () => {
    if (fields < children.length) setFields((prev) => prev + 1);
  }; */

  const prevField = () => {
    if (fields > 0) setFields((prev) => prev - 1);
  };

  React.useEffect(() => {

    lastPage(children[children.length - 1]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {

    const isEmpty = Object.entries(contract).length !== 0;

    setCreateContract(isEmpty);

  }, [contract]);



  return (
    <React.Fragment>

      <form className={styles.formBox}>

        {children[fields]}

        {/* <ButtonGroup
          disableElevation
          size="small"
          variant="contained"
          color="primary"
        >
          {fields < children.length - 1 && fields > 0 && (
            <Button onClick={prevField}>back</Button>
          )}

          {fields < children.length - 2 && (
            <Button onClick={nextField}>Next</Button>
          )}

          {fields === children.length - 2 && (
            <Button onClick={nextField}>Submit</Button>
          )}

        </ButtonGroup> */}

        {/* rowSpacing={8} columnSpacing={-35} */}
        {/* <Grid container className={styles.buttomBox}>

          <Button
            variant="contained"
            color="customFormButtomSolicitud"
            size="large"
          // onClick={() => setShowSteps(true)}
          >
            COMENZAR
          </Button>

          <Typography id="textPulsar" variant="body2" gutterBottom>
            Pulsa <strong>Enter</strong>
          </Typography>

          <BiSubdirectoryLeft />

        </Grid> */}

        <Grid container>

          <Grid item xs={1}>

          </Grid>

          {fields !== (children.length - 1) && (<Grid item xs={11} className={styles.buttomBox}>

            {!createContract && fields < children.length - 1 && fields > 0 && (

              <Button
                variant="contained"
                style={{ backgroundColor: "rgb(245, 162, 38)", marginRight: '15px' }}
                size="large"
                onClick={prevField}
              >
                Atras
              </Button>

            )}

            {user && user.token && (<React.Fragment>

              <Button
                variant="contained"
                style={{ backgroundColor: "rgb(245, 162, 38)" }}
                size="large"
                onClick={nextField}
                disabled={errorStep}
              >
                Aceptar
              </Button>

              <Typography id="textPulsar" variant="body2" gutterBottom>
                Pulsa <strong>Enter</strong>
              </Typography>

              <BiSubdirectoryLeft />

            </React.Fragment>)}

          </Grid>)}

        </Grid>

      </form>

    </React.Fragment>
  );
}

export default TypeForm;
