import React from "react";
import { ReactComponent as DeliveryProcedureIcon } from "../assets/icons/deliveryprocedures.svg";
import { ReactComponent as DocumentProcedureIcon } from "../assets/icons/documentsprocedures.svg";
import { ReactComponent as ManageProcedureIcon } from "../assets/icons/manageprocedures.svg";
import { ReactComponent as NewProcedureIcon } from "../assets/icons/newprocedure.svg";
import { ReactComponent as NotariesProcedureIcon } from "../assets/icons/notariesprocedure.svg";
import { ReactComponent as PaymentProcedureIcon } from "../assets/icons/paymentprocedures.svg";
import { ReactComponent as ReviewProcedureIcon } from "../assets/icons/reviewprocedures.svg";
import { ReactComponent as SignatureProcedureIcon } from "../assets/icons/signatureprocedures.svg";
import { ReactComponent as StatusProcedureIcon } from "../assets/icons/statusprocedures.svg";

export const verifiVariable = (value) => {
    return [null, undefined, ''].includes(value);
}

export const blobToFile = (theBlob, fileName) => {
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
}

export const asyncBlobToBase64 = (blob) => {

    const reader = new FileReader();

    reader.readAsDataURL(blob);

    return new Promise(resolve => {
        reader.onloadend = () => {
            resolve(reader.result);
        };
    });

};

export const asyncBase64ToFile = (dataurl, filename) => {
    return new Promise(function (resolve, reject) {

        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        resolve(new File([u8arr], filename, { type: mime }));

    });
};

export const asyncFileToBase64 = (file) => {
    return new Promise(function (resolve, reject) {

        const reader = new FileReader()

        reader.readAsDataURL(file)

        reader.onload = function () {
            resolve(reader.result);
        }

        reader.onerror = function (error) {
            reject(error);
        };

    });
};

export const fileToBase64 = (file, cb) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
        cb(null, reader.result)
    }
    reader.onerror = function (error) {
        cb(error, null)
    }
}

export const base64toBlob = (base64Data) => {
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        const begin = sliceIndex * sliceSize;
        const end = Math.min(begin + sliceSize, bytesLength);

        const bytes = new Array(end - begin);
        for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
            bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: "application/pdf" });
}

export const openBase64NewTab = (base64Pdf) => {
    var blob = base64toBlob(base64Pdf);
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, "pdfBase64.pdf");
    } else {
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl);
    }
}

export const randomRgba = () => {
    const o = Math.round, r = Math.random, s = 255;
    return 'rgba(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ',1)';//' + r().toFixed(1) + '
}

export const optionsSteps = {
    nextLabel: 'Siguiente',
    prevLabel: 'Atrás',
    skipLabel: 'Omitir',
    doneLabel: 'Listo'
}

export const taxes = {
    IVA: 19,
};

console.log("REACT_APP_PROD", process.env.REACT_APP_PROD);

console.log(process.env.REACT_APP_PROD === "true" ? "FEA" : "FES");


export const ramdonCharacters = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';// 0123456789
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
};


export const api = {
    BASE_SCHEDULE_URI: process.env.REACT_APP_X_API_SCHEDULE_URI,
    USUARIO_UACH: process.env.REACT_APP_USUARIO_UACH,
    USUARIO_FIRMA_VIRTUAL: process.env.REACT_APP_USUARIO_FIRMA_VIRTUAL,
    PASSWORD_FIRMA_VIRTUAL: process.env.REACT_APP_PASSWORD_FIRMA_VIRTUAL,
    PASSWORD_UACH: process.env.REACT_APP_PASSWORD_UACH,
    BASE_URI: process.env.REACT_APP_API_URI,
    BASE_URI_CLV_UNI: process.env.REACT_APP_API_URI_CLV_UNI,
    BASE_URI_CHALLE: process.env.REACT_APP_API_URI_CHALLE,
    BASE_URI_RA: process.env.REACT_APP_API_URI_RA,
    BASE_URI_ESIGNER: process.env.REACT_APP_API_URI_ESIGNER,
    X_API_KEY: process.env.REACT_APP_X_API_KEY,
    X_API_APP: process.env.REACT_APP_X_API_APP,
    PRODUCCION: process.env.REACT_APP_PROD,
    GET_ALL_COMMUNE: '/api/v1/commune/list',
    GET_ONE_USER: '/api/v1/userapp/',
    GET_ALL_JURIDICTIONS: '/api/v1/juridiction/list',
    GET_ALL_NOTARYS: '/api/v1/notary/list',
    GET_ALL_NOTARIES: '/api/v1/notario/list',
    GET_BLACK_LIST: (date) => `/api/v1/scheduled/list/1/${date}`,
    GET_NOTARY: '/api/v1/notary/view/',
    UPDATE_SCHEDULE: '/api/v1/scheduled/update',
    WEB_PAY: '/api/v2/contract/pay',
    CREATE_SCHEDULE: '/api/v1/scheduled/create',
    CREATE_TRAMIT_SCHEDULE: '/api/v2/create-contract',
    SAVE_SCHEDULE_DIRECTION: '/api/v1/scheduled-directions/create',
    SCHEDULE_CONTARCT_DETAIL: '/api/v2/contract/detail/',
    SCHEDULE_DETAIL: '/api/v1/scheduled/view/',
    GET_SCHEDULE_LIST: (date) => `/api/v1/scheduled/list/1/${date}`,
    DELETE_SCHEDULE: `/api/v1/scheduled/delete/`,
    usuarioDescargaCertificado: "QTjjnCc13jjXogRbHaFdPw==",
    claveDescargaCertificado: "t4PqNpWj5c12NZK3Gd3tvQ==",
    rutEmpresa: "769697624",
    RESET_PASS: "/pwd/reset",
    NEW_PASS: "/pwd/update",
    UPLOAD_FILE: "/api/v1/up-file",
    CONTRACT_CREATION: "/api/v1/contract",
    CONTRACT_TYPES: "/api/v1/contract-type/all/0",
    GET_CONTRACT_FILES: "/api/v1/filelist",
    SORT_CONTRACT_FILES: "/api/v1/sortfilelist",
    DELETE_CONTRACT_FILES: "/api/v1/deletefilelist",
    MERGE_CONTRACT_FILES: "/api/v1/contract/merge",
    DOWNLOAD_CONTRACT_FILE: "/api/v1/contract/download/doc",
    DOWNLOAD_COMPILED_CONTRACT_FILE: "/api/v1/contract/download",
    DOWNLOAD_COMPILED_TRAMIT_FILE: "/api/v2/contract/download",
    DOWNLOAD_MEMBER_CONTRACT_FILE: "/api/v1/draft/download",
    APROVED_MEMBER_CONTRACT: "/api/v1/draft/agree",
    UNAPROVED_MEMBER_CONTRACT: "/api/v1/draft/disagree",
    UP_NOTARY_DOC: "/api/v1/notaryfile",
    DELETE_CONTARCT_DOC: "/api/v1/contract/doc/delete",
    SEND_CONTRACT_EMAIL: "/api/v1/contract/send/draft",
    DELETE_CONTRACT: "/api/v1/contract/delete",
    UPDATE_MEMBER_INFO: "/api/v1/signer/upd",
    DELETE_MEMBER: "/api/v1/signer/del",
    GET_ALL_CONTRACT: "/api/v1/contract",
    GET_ALL_CONTRACT_TYPE: "/api/v1/contract-type",
    AGREEMENT_CREATION: "/api/v1/organization/createfull",
    GET_ALL_ORGANIZATIONS: "/api/v1/organization/all",
    GET_AGREEMENT_DETAIL: "/api/v1/organization/find",
    DELETE_AGREEMENT: "/api/v1/organization/deletefull",
    UPDATE_ALL_SIGNER_BY_RUT: (idContract) => `/api/v1/signer/upd/${idContract}/RUT`,
    UPDATE_ALL_SIGNER_BY_EMAIL: (idContract) => `/api/v1/signer/upd/${idContract}/EMAIL`,
    SEND_PRIORITY: (idContract) => `/api/v1/contract/send/draft-priority/${idContract}`,
    SEND_EMAIL_SIGN: (idContract) => `/api/v1/fvl/sign/${idContract}`,
    RESET_CONTRACT: (idContract) => `/api/v2/contract/reset/${idContract}`
};

export const apiMessage = {
    WRONG_LOGIN: "Wrong login or password",
    SUCCESS: "success",
    FAIL: "fail",
};

export const docStatus = {
    SUCCESS: "SUCCESS",
    ACTIVE: "ENABLE",
    NOT_ACTIVE: "DISABLE",
    FAIL: "FAIL",
};

export const path = {
    HOME: "/",
    ACCOUNT_LOGIN: "/logindata",
    USER_INFO: "/userapp",
    ACCOUNT_SIGNUP: "/signup-account",
    ACCOUNT_RECOVER: "/recover-account",
    ACCOUNT_RESET: "/reset-account",
    SESSION: "/session",
    CONTRACT_CREATION_FORM: "contract-creation-form",
    CONTRACT_CREATOR: "type",
    CONTRACT_CREATOR_DOCS: "attachments",
    CONTRACT_SORT_FILES: "contract-sort-files",
    CONTRACTS_CREATED_LIST: "contracts-status",
    CONTRACTS_APROVED_LIST: "contracts-aproved",
    CONTRACTS_SIGNATURE_LIST: "contracts-pending-signature",
    CONTRACTS_CREATED_DETAIL: "contract-detail-review",
    NEW_CONTRACTS_CREATED_DETAIL: "new-contract-detail-review",
    SCHEDULE_CONTRACTS_DETAIL: "schedule-contract-detail-review",
    CONTRACTS_PAYMENT_DETAIL: "contract-detail-payment",
    CONTRACTS_SIGNATURE_DETAIL: "contract-detail-signature",
    CONTRACTS_NOTARY_DETAIL: "contract-detail-notary",
    CONTRACTS_DELIVERY_DETAIL: "contract-detail-delivery",
    MANAGE_NOTARY_LIST: "list-notarys",
    MANAGE_USERS: "manage-users",
    MANAGE_CLIENTS: "manage-clients",
    MANAGE_PAYMENT_HISTORY: "manage-payments",
    MANAGE_PROCEDURES_HISTORY: "manage-procedures",
    MANAGE_SCHEDULE_HISTORY: "manage-schedule",
    MANAGE_INTEGRATION: "manage-integration",
    DISCOUNT_COUPONS: "discount-coupons",
    DASHBOARD: "dashboard",
    DASHBOARD_HOME: "home",
    DOCUMENT_MANAGE: "document-manage",
    DASHBOARD_CONTENT: "dashboard",
    ADMINISTRATION_DASHBOARD_HOME: "administration-home",
    MANAGE_DELIVERY: "list-delivery",
    MANAGEMENT_PROCEDURE_DASHBOARD_HOME: "management-home",
    FIRMA_VIRTUAL_AVANZADA: "firma-virtual-avanzada",
    DASHBOARD_WELCOME: "welcome",
    MANAGE_PROVIDERS: "manage-providers",
    CONVENTIONS: "conventions",
    FORMULARIO_SOLICITUD: "formulario-solicitud-de-firma",
    MANAGE_AGREEMENTS: "agreements",
    MANAGE_AGREEMENTS_CREATION: "create-agreement",
    FORM_UACH: "formulario-uach",
    UPLOAD_FILE: "upload-file",
    DETAIL_CONTRACT: "detail-contract",
    CREATE_PROCEDURE: "create-procedure",
    CREATE_SCHEDULE: "create-schelude",
    DATA_PROCEDURE: "data",
    DATA_SCHEDULE_PROCEDURE: "data-schedule",
    SIGNATORIES: "signatories",
    SIGNATORIES_SCHEDULE: "signatories-schedule",
    DOCUMENTS: "documents",
    DOCUMENTS_SCHEDULE: "documents-schedule",
    ADD_SIGNATURES: "add-signatures",
    SCHEDULE_PAY: "schedule-pay",
    SCHEDULING: "scheduling",
    DATA_PREVIEW: "data-preview"
};

export const externalLink = {
    FV_APP_PDF: "https://firmavirtual.web.app/",
};

export const to = {
    HOME: path.HOME,
    LOGIN: path.ACCOUNT_LOGIN,
    SIGNUP: path.ACCOUNT_SIGNUP,
    RECOVER: path.ACCOUNT_RECOVER,
    RESET: path.ACCOUNT_RESET,
    FORMULARIO_SOLICITUD_FIRMA: `${path.FORMULARIO_SOLICITUD}`,
    FV_AVANZADA: `${path.SESSION}/${path.DASHBOARD_CONTENT}/${path.FIRMA_VIRTUAL_AVANZADA}`,
    MANAGE_DELIVERY: `${path.SESSION}/${path.DASHBOARD_CONTENT}/${path.MANAGE_DELIVERY}`,
    DOCUMENT_MANAGE: `${path.SESSION}/${path.DASHBOARD_CONTENT}/${path.DOCUMENT_MANAGE}`,
    ADMINISTRATION_DASHBOARD_HOME: `${path.SESSION}/${path.DASHBOARD_CONTENT}/${path.ADMINISTRATION_DASHBOARD_HOME}`,
    MANAGEMENT_PROCEDURE_DASHBOARD_HOME: `${path.SESSION}/${path.DASHBOARD_CONTENT}/${path.MANAGEMENT_PROCEDURE_DASHBOARD_HOME}`,
    DASHBOARD_HOME: `${path.SESSION}/${path.DASHBOARD}/${path.DASHBOARD_HOME}`,
    CONTRACT_STATUS: `${path.SESSION}/${path.DASHBOARD}/${path.CONTRACTS_CREATED_LIST}`,
    CONTRACT_APROVED: `${path.SESSION}/${path.DASHBOARD}/${path.CONTRACTS_APROVED_LIST}`,
    CONTRACT_SIGNATURE_PENDING: `${path.SESSION}/${path.DASHBOARD}/${path.CONTRACTS_SIGNATURE_LIST}`,
    CONTRACT_DETAIL: `${path.SESSION}/${path.DASHBOARD}/${path.CONTRACTS_CREATED_DETAIL}`,
    SCHEDULE_DETAIL: `${path.SESSION}/${path.DASHBOARD}/${path.SCHEDULE_CONTRACTS_DETAIL}`,
    CONTRACTS_PAYMENT: `${path.SESSION}/${path.DASHBOARD}/${path.CONTRACTS_PAYMENT_DETAIL}`,
    CONTRACTS_SIGNATURE: `${path.SESSION}/${path.DASHBOARD}/${path.CONTRACTS_SIGNATURE_DETAIL}`,
    MANAGE_NOTARY: `${path.SESSION}/${path.DASHBOARD}/${path.MANAGE_NOTARY_LIST}`,
    CONTRACTS_NOTARY: `${path.SESSION}/${path.DASHBOARD}/${path.CONTRACTS_NOTARY_DETAIL}`,
    CONTRACTS_DELIVERY: `${path.SESSION}/${path.DASHBOARD}/${path.CONTRACTS_DELIVERY_DETAIL}`,
    // CONTRACT_CREATION: `${path.SESSION}/${path.DASHBOARD}/${path.CONTRACT_CREATION_FORM}/${path.CONTRACT_CREATOR}`,
    CONTRACT_CREATION: `${path.SESSION}/${path.DASHBOARD}/${path.CREATE_PROCEDURE}/${path.DATA_PROCEDURE}`,
    CONTRACT_CREATION_SIGNATORIES: `${path.SESSION}/${path.DASHBOARD}/${path.CREATE_PROCEDURE}/${path.SIGNATORIES}`,
    SCHEDULE_CREATION_SIGNATORIES: `${path.SESSION}/${path.DASHBOARD}/${path.CREATE_SCHEDULE}/${path.SIGNATORIES_SCHEDULE}`,
    CONTRACT_CREATION_DOCUMENTS: `${path.SESSION}/${path.DASHBOARD}/${path.CREATE_PROCEDURE}/${path.DOCUMENTS}`,
    SCHEDULE_CREATION_DOCUMENTS: `${path.SESSION}/${path.DASHBOARD}/${path.CREATE_SCHEDULE}/${path.DOCUMENTS_SCHEDULE}`,
    CONTRACT_CREATION_ADD_SIGNATURES: `${path.SESSION}/${path.DASHBOARD}/${path.CREATE_PROCEDURE}/${path.ADD_SIGNATURES}`,
    SCHEDULE_CREATION_PAY: `${path.SESSION}/${path.DASHBOARD}/${path.CREATE_SCHEDULE}/${path.SCHEDULE_PAY}`,
    SCHEDULING_CREATION: `${path.SESSION}/${path.DASHBOARD}/${path.CREATE_SCHEDULE}/${path.SCHEDULING}`,
    CONTRACT_CREATION_DATA_PREVIEW: `${path.SESSION}/${path.DASHBOARD}/${path.CREATE_PROCEDURE}/${path.DATA_PREVIEW}`,
    CONTRACT_CREATOR_DOCS: `${path.SESSION}/${path.DASHBOARD}/${path.CONTRACT_CREATION_FORM}/${path.CONTRACT_CREATOR_DOCS}`,
    CONTRACT_SORT_FILES: `${path.SESSION}/${path.DASHBOARD}/${path.CONTRACT_SORT_FILES}`,
    MANAGE_USERS: `${path.SESSION}/${path.DASHBOARD}/${path.MANAGE_USERS}`,
    MANAGE_CLIENTS: `${path.SESSION}/${path.DASHBOARD}/${path.MANAGE_CLIENTS}`,
    MANAGE_PAYMENT_HISTORY: `${path.SESSION}/${path.DASHBOARD}/${path.MANAGE_PAYMENT_HISTORY}`,
    MANAGE_SCHEDULE_HISTORY: `${path.SESSION}/${path.DASHBOARD}/${path.MANAGE_SCHEDULE_HISTORY}`,
    MANAGE_PROCEDURES_HISTORY: `${path.SESSION}/${path.DASHBOARD}/${path.MANAGE_PROCEDURES_HISTORY}`,
    MANAGE_INTEGRATION: `${path.SESSION}/${path.DASHBOARD}/${path.MANAGE_INTEGRATION}`,
    DISCOUNT_COUPONS: `${path.SESSION}/${path.DASHBOARD}/${path.DISCOUNT_COUPONS}`,
    DASHBOARD_WELCOME: `${path.SESSION}/${path.DASHBOARD}/${path.DASHBOARD_WELCOME}`,
    MANAGE_PROVIDERS: `${path.SESSION}/${path.DASHBOARD}/${path.MANAGE_PROVIDERS}`,
    MANAGE_AGREEMENTS: `${path.SESSION}/${path.DASHBOARD}/${path.MANAGE_AGREEMENTS}`,
    MANAGE_AGREEMENTS_CREATION: `${path.SESSION}/${path.DASHBOARD}/${path.MANAGE_AGREEMENTS_CREATION}`,
    DASHBOARD_UPLOAD_FILE: `${path.SESSION}/${path.DASHBOARD}/${path.DETAIL_CONTRACT}/${path.UPLOAD_FILE}`,
    CREATE_PROCEDURE: `${path.SESSION}/${path.DASHBOARD}/${path.CREATE_PROCEDURE}`,
    CREATE_SCHEDULE: `${path.SESSION}/${path.DASHBOARD}/${path.CREATE_SCHEDULE}`
};

export const dashboardMenu = {
    MENU_ITEM_HOME: "homeDashboard",
    MENU_ITEM_MANAGE: "manageAccount",
    MENU_ITEM_ADMINISTRATION: "administrateAccount",
    MENU_ITEM_MANAGE_HELP: "ManageHelp",
};

export const manageProcedures = {
    PROCEDURES_CREATION: "Crear trámites",
    SCHEDULE_CREATION: "Notaria a domicilio",
    PROCEDURES_BY_STATUS: "Trámites por Status",
    MANAGE_SUBMITIONS: "Gestión de Ingreso",
    MANAGE_REVIEWS: "Gestión de Revisión",
    MANAGE_PAYMENTS: "Gestión de Pagos",
    MANAGE_SIGNETURES: "Gestión de Firmas",
    MANAGE_NOTARIES: "Gestión Notarias",
    MANAGE_DELIVERY: "Gestión Entregas",
    MANAGE_DOCUMENTATION: "Gestión Documentos",
    MANAGE_USERS: "Administrar usuarios",
    MANAGE_CLIENTS: "Administrar clientes",
    MANAGE_PAYMENT_HISTORY: "Historial de pagos",
    MANAGE_PROCEDURES_HISTORY: "Historial de trámites",
    MANAGE_SUPPLIERS: "Administrar proveedores",
    MANAGE_INTEGRATION: "Administrar integración",
    DISCOUNT_COUPONS: "Cupones de descuento",
    REGISTER_FEA: "Registro FEA",
    MANAGE_AGREEMENTS: "Convenios",
    MANAGE_SCHEDULE: "Gestion de trámites a domicilio",
};

export const icons = {
    NEW_PROCEDURES_ICON: <NewProcedureIcon />,
    STATUS_PROCEDURES_ICON: <StatusProcedureIcon />,
    MANAGE_PROCEDURES_ICON: <ManageProcedureIcon style={{ width: '85px', height: '98px' }} />,
    DELIVERY_PROCEDURES_ICON: <DeliveryProcedureIcon style={{ width: '85px', height: '98px' }} />,
    DOCUMENTS_PROCEDURES_ICON: <DocumentProcedureIcon />,
    NOTARIES_PROCEDURES_ICON: <NotariesProcedureIcon />,
    PAYMENT_PROCEDURES_ICON: <PaymentProcedureIcon />,
    REVIEW_PROCEDURES_ICON: <ReviewProcedureIcon style={{ width: '85px', height: '98px' }} />,
    SIGNATURE_PROCEDURES_ICON: <SignatureProcedureIcon style={{ width: '85px', height: '98px' }} />,
};

export const procedureStep = {
    STEP_1: "step1",
    STEP_2: "step2",
    STEP_3: "step3",
};

export const contractTypes = {
    REAL_STATE: "REAL_ESTATE",
    LABOR: "LABOR",
    POWER: "POWER",
    SOCIETY: "SOCIETY",
    PERSONAL: "PERSONAL",
    VEHICLE: "VEHICLE",
};

export const colorsTheme = {
    PRIMARY: "#004f9f",
    PRIMARY_LIGHT: "#00adea",
    PRIMARY_DARK: "#003c77",
    SECONDARY: "#ff326e",
    SECONDARY_XLIGHT: "#fff5f8",
    SECONDARY_LIGHT: "#f86262",
    SECONDARY_DARK: "#cf3966",
    TERTIRARY: "#fa8724",
    TERTIRARY_XLIGHT: "#fff8dd",
    TERTIRARY_LIGHT: "#ffb676",
    TERTIRARY_DARK: "#db6d0d",
    QUATERNARY: "#5cc858",
    QUATERNARY_XLIGHT: "#e8fff3",
    QUATERNARY_LIGHT: "#87e483",
    QUATERNARY_DARK: "#4ca149",
    QUINARY: "#cfd7ed",
    QUINARY_LIGHT: "#f8faff",
    QUINARY_DARK: "#a0aac7",
    QUINARY_XDARK: "#747c91",
    SENARY: "#7239ea",
    SENARY_LIGHT: "#9667fd",
    SENARY_DARK: "#4e289e",
    SENARY_XDARK: "#372266",
};

export const filterName = {
    FILTER_ID: "filter-id",
    FILTER_ID_LABEL: "filter-id-label",
    FILTER_BY_CREATION_DATE: "filter-by-creation-date",
    FILTER_BY_CREATION_DATE_LABEL: "filter-by-creation-date-label",
    FILTER_BY_MEMBERS: "filter-by-members",
    FILTER_BY_MEMBERS_LABEL: "filter-by-members-label",
    FILTER_BY_SIGNATURE: "filter-by-signature",
    FILTER_BY_SIGNATURE_LABEL: "filter-by-signature-label",
    FILTER_BY_CONTRACT: "filter-by-contract",
    FILTER_BY_CONTRACT_LABEL: "filter-by-contract-label",
};

export const itemTypes = {
    CARD: "card",
    SIGNATURE_CONTAINER: "signatureContainer",
};

export const typeUser = {
    'WEBUSER': 1,
    'ADMIN': 7
}