import React, { useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import CssBaseline from '@mui/material/CssBaseline';
import { PersistGate } from "redux-persist/integration/react";
import {
    Authsession,
    ContentDetail,
    ContentWrapper,
    Procedurecreationform,
    ProcedureCreationStep2,
    ProcedureCreationStep3,
    // ContractDetail,
    ContractPaymentDetail,
    Board,
    ManageUsers,
    ManageClients,
    ManagePayments,
    ManageProcedures,
    ManageIntegration,
    ManageDiscountCoupons,
    Notarys,
    HomeAdministration,
    HomeManagementProcedures,
    DocManagement,
    ListDelivery,
    FirmaVirtualAvanzada,
    ContentWelcome,
    Providers,
    FormularioSolicitud,
    ManagerAgreements,
    RevisionManagement,
    PaymentManagement,
    SignatureManagement,
    FormularioUach,
    DetailContracFileUpload,
    HeaderContracFileUpload,
    PaperworkHeader,
    Paperwork,
    Signatories,
    Documents,
    AddSignatures,
    DataPreview,
    ContractDetailReview,
    ManageSchedule,
    ScheduleDetailReview,
} from "./components";
import storeContext from "./context/storeContext";
import { Provider } from 'react-redux';
import { persistor, store } from "./store/storeConfig";
import "./styles/index.scss";
import { theme } from "./theme/theme";
import { api, path } from "./utils/enums";
import {
    Login,
    RecoverAccount,
    ResetAccount,
    SignUpAccount
} from "./views/session";
import { NotFound } from "./views/static";
import StepsperAddAgreements from "./components/dashboard/managerAgreements/molecules/stepsperAddAgreements";
import BrowserNameHook from "./hooks/BrowserNameHook";
import SheduleHeader from "./components/schedulingProcess/scheduleHeader";
import SchedulePaperwork from "./components/schedulingProcess/schedulePaperwork";
import ScheduleSignatories from "./components/schedulingProcess/signatories";
import ScheduleDocuments from "./components/schedulingProcess/scheduleDocuments";
import Scheduling from "./components/schedulingProcess/scheduling";
import SchedulePay from "./components/schedulingProcess/schedulePay";

const App = () => {

    const navegador = BrowserNameHook();

    useEffect(() => {//TODO para recargar la pagina en caso de que de error en modo produccion, para el modo de desarrollo estara desactivado

        window.onerror = () => {
            if (api.PRODUCCION === 'true') window.location.reload();
        }

    }, []);

    useEffect(() => {
        console.log('navegador: ', navegador);
        // console.log('Validate: ', /*  validation.passport() */ /* validator.isPassportNumber('131929474', 'CA') */);
    }, [navegador]);

    return (
        <storeContext.Provider value={store}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        {/* <div>Prueba</div> */}
                        <BrowserRouter>
                            <Routes>
                                <Route path={path.FORMULARIO_SOLICITUD} element={<FormularioSolicitud />} />

                                <Route path={path.HOME} element={<Login />} />

                                <Route
                                    path={path.ACCOUNT_LOGIN}
                                    element={<Login />}
                                />

                                <Route
                                    path={path.ACCOUNT_RECOVER}
                                    element={<RecoverAccount />}
                                />

                                <Route
                                    path={path.ACCOUNT_RESET}
                                    element={<ResetAccount />}
                                />

                                <Route
                                    path={path.ACCOUNT_SIGNUP}
                                    element={<SignUpAccount />}
                                />

                                <Route
                                    path={`${path.ACCOUNT_SIGNUP}/agreement/:id`}
                                    element={<SignUpAccount />}
                                />

                                <Route
                                    path={path.FORM_UACH}
                                    element={<FormularioUach />}
                                />

                                {/* This should go to nested routes */}
                                <Route path={path.SESSION} element={<Authsession />}>
                                    <Route path={path.DASHBOARD_CONTENT} element={<ContentWrapper />}>

                                        <Route path={path.MANAGE_PROVIDERS} element={<Providers />} />
                                        <Route path={path.MANAGE_DELIVERY} element={<ListDelivery />} />
                                        <Route path={path.DOCUMENT_MANAGE} element={<DocManagement />} />
                                        <Route path={path.DASHBOARD_HOME} element={<ContentDetail />} />
                                        <Route path={path.DASHBOARD_WELCOME} element={<ContentWelcome />} />
                                        <Route path={path.ADMINISTRATION_DASHBOARD_HOME} element={<HomeAdministration />} />
                                        <Route path={path.FIRMA_VIRTUAL_AVANZADA} element={<FirmaVirtualAvanzada />} />
                                        <Route path={path.MANAGEMENT_PROCEDURE_DASHBOARD_HOME} element={<HomeManagementProcedures />} />
                                        <Route path={path.CONTRACTS_CREATED_LIST} element={<RevisionManagement />} />
                                        <Route path={path.CONTRACTS_APROVED_LIST} element={<PaymentManagement />} />
                                        <Route path={path.CONTRACTS_SIGNATURE_LIST} element={<SignatureManagement />} />
                                        <Route path={path.CONTRACTS_CREATED_DETAIL} element={<ContractDetailReview />} />{/* ContractDetailReview  ContractDetail */}
                                        <Route path={path.NEW_CONTRACTS_CREATED_DETAIL} element={<ContractDetailReview />} />
                                        <Route path={path.CONTRACTS_PAYMENT_DETAIL} element={<ContractPaymentDetail />} />
                                        <Route path={path.CONTRACTS_SIGNATURE_DETAIL} element={<ContractDetailReview />} />
                                        <Route path={path.MANAGE_NOTARY_LIST} element={<Notarys />} />
                                        <Route path={path.CONTRACTS_NOTARY_DETAIL} element={<ContractDetailReview />} />
                                        <Route path={path.CONTRACTS_DELIVERY_DETAIL} element={<ContractDetailReview />} />
                                        <Route path={path.CONTRACT_SORT_FILES} element={<Board />} />
                                        <Route path={path.MANAGE_USERS} element={<ManageUsers />} />
                                        <Route path={path.MANAGE_CLIENTS} element={<ManageClients />} />
                                        <Route path={path.MANAGE_PAYMENT_HISTORY} element={<ManagePayments />} />
                                        <Route path={path.MANAGE_PROCEDURES_HISTORY} element={<ManageProcedures />} />
                                        <Route path={path.MANAGE_SCHEDULE_HISTORY} element={<ManageSchedule />} />
                                        <Route path={path.MANAGE_INTEGRATION} element={<ManageIntegration />} />
                                        <Route path={path.DISCOUNT_COUPONS} element={<ManageDiscountCoupons />} />
                                        <Route path={path.MANAGE_AGREEMENTS} element={<ManagerAgreements />} />
                                        <Route path={path.MANAGE_AGREEMENTS_CREATION} exact element={<StepsperAddAgreements />} />
                                        <Route path={path.SCHEDULE_CONTRACTS_DETAIL} element={<ScheduleDetailReview />} />

                                        <Route path={path.CREATE_SCHEDULE} element={<SheduleHeader />}>

                                            <Route index element={<SchedulePaperwork />} />

                                            <Route path={path.DATA_SCHEDULE_PROCEDURE} element={<SchedulePaperwork />} />

                                            <Route path={path.SIGNATORIES_SCHEDULE} element={<ScheduleSignatories />} />

                                            <Route path={path.DOCUMENTS_SCHEDULE} element={<ScheduleDocuments />} />

                                            <Route path={path.SCHEDULING} element={<Scheduling />} />

                                            <Route path={path.SCHEDULE_PAY} element={<SchedulePay />} />

                                        </Route>

                                        <Route path={path.CREATE_PROCEDURE} element={<PaperworkHeader />}>

                                            <Route index element={<Paperwork />} />

                                            <Route path={path.DATA_PROCEDURE} element={<Paperwork />} />

                                            <Route path={path.SIGNATORIES} element={<Signatories />} />

                                            <Route path={path.DOCUMENTS} element={<Documents />} />

                                            <Route path={path.ADD_SIGNATURES} element={<AddSignatures />} />

                                            <Route path={path.DATA_PREVIEW} element={<DataPreview />} />

                                        </Route>

                                        <Route path={path.DETAIL_CONTRACT} element={<HeaderContracFileUpload />}>

                                            <Route index element={<DetailContracFileUpload />} />

                                            <Route path={path.UPLOAD_FILE} element={<DetailContracFileUpload />} />

                                        </Route>

                                        <Route path={path.CONTRACT_CREATION_FORM} element={<Procedurecreationform />}>
                                            <Route index element={<ProcedureCreationStep2 />} />
                                            <Route
                                                path={path.CONTRACT_CREATOR}
                                                element={
                                                    <ProcedureCreationStep2 />
                                                }
                                            />
                                            <Route
                                                path={
                                                    path.CONTRACT_CREATOR_DOCS
                                                }
                                                element={
                                                    <ProcedureCreationStep3 />
                                                }
                                            />
                                        </Route>
                                    </Route>

                                </Route>
                                {/* End */}
                                <Route path="*" element={<NotFound />} />
                            </Routes>
                        </BrowserRouter>
                    </ThemeProvider>
                </PersistGate>
            </Provider>
        </storeContext.Provider>
    );
}

export default App;