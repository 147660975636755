import React from 'react';
import {Link, useLocation} from "react-router-dom";

const Navlink = ({to, className, activeClassName, inactiveClassName, ...rest}) => {
    const location = useLocation();
    let isActive = location.pathname === to;
    let allActiveClassName = `${className} ${isActive ? activeClassName : inactiveClassName}`;

    return (
        <Link className={allActiveClassName} to={to} {...rest} />
    );
}

export default Navlink;
