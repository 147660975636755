import RenderUserName from '../atoms/renderUserName'
import RenderActions from '../atoms/renderActions';
import RenderCell from "../atoms/renderCell";

const gridColumns = [
    {
        field: "iUserID",
        headerAlign: "center",
        headerName: "ID",
        headerClassName: "grid__header-item",
        cellClassName: "grid__row-item text--uppercase text--center",
        width: 80,
        renderCell: (params) => <RenderCell item={params.value} />,
    },
    {
        field: "iProfileID",
        headerAlign: "center",
        headerName: "Nombre y apellido",
        headerClassName: "grid__header-item",
        cellClassName: "grid__row-item text--uppercase text--left",
        width: 270,
        renderCell: (params) => <RenderUserName data={params.row} />,
    },
    {
        field: "sEmail",
        headerAlign: "center",
        headerName: "Correo electrónico",
        headerClassName: "grid__header-item",
        cellClassName: "grid__row-item",
        width: 270,
    },
    {
        field: "sLogin",
        headerAlign: "center",
        headerName: "Nombre de usuario",
        headerClassName: "grid__header-item",
        cellClassName: "grid__row-item",
        width: 220,
        disableColumnMenu: true,
    },
    {
        field: "sRUT",
        headerAlign: "center",
        headerName: "Ruc",
        headerClassName: "grid__header-item",
        cellClassName: "grid__row-item",
        width: 220,
        disableColumnMenu: true,
    },
    {
        field: "sStatus",
        headerAlign: "center",
        headerName: "Acciones",
        headerClassName: "grid__header-item",
        rowClassName: "grid__row-item",
        width: 120,
        disableColumnMenu: true,
        renderCell: (params) => <RenderActions user={params.row} />,
    },
];

const localizedTextsMap = {
    columnMenuUnsort: "Sin ordenar",
    columnMenuSortAsc: "De menor a mayor",
    columnMenuSortDesc: "De mayor a menor",
    columnMenuFilter: "Filtro",
    columnMenuHideColumn: "Ocultar",
    columnMenuShowColumns: "Mostrar columnas",
    toolbarExport: "Descargar",
    toolbarFilters: "Filtros",
    filterOperatorContains: "Contiene",
    filterOperatorEquals: "Igual a",
    filterOperatorStartsWith: "Empieza con",
    filterOperatorEndsWith: "Termina con",
    filterOperatorIsEmpty: "Esta vacio",
    filterOperatorIsNotEmpty: "No esta vacio",
    filterPanelInputLabel: "Valor",
    filterPanelInputPlaceholder: "Filtrar por valor",
};

export { gridColumns, localizedTextsMap };