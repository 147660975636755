import { useEffect, useState } from "react";
import { FaWhatsapp } from "react-icons/fa";

import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";

const actions = [
    {
        name: "Comercial",
        icon: <ContactPhoneIcon />,
        link: "https://api.whatsapp.com/send/?phone=56945227304&text&type=phone_number&app_absent=0",
    },
    {
        name: "Soporte",
        icon: <SupportAgentIcon />,
        link: "https://api.whatsapp.com/send/?phone=56930139035&text&type=phone_number&app_absent=0",
    },
];

const WhatsappIconSpeedDial = ({ screen }) => {
    const [open, setOpen] = useState(false);

    const [mediaQuery, setMediaQuery] = useState({});

    useEffect(() => {

        let css = {
            position: "fixed",
            left: "2vw",
            display: "flex",
            ".css-1ks1y9e-MuiButtonBase-root-MuiFab-root-MuiSpeedDial-fab": {
                width: "48px",
                height: "48px",
            }
        };

        if (screen === "sm") {
            css = Object.assign(css, { bottom: "35px" });
        } else if (screen === "xs") {
            css = Object.assign(css, { bottom: "40px" });
        } else if (screen === "md") {
            css = Object.assign(css, { bottom: "25px" });
        } else {
            css = Object.assign(css, { bottom: "30px" });
        }

        setMediaQuery(css);

    }, [screen]);

    return (
        <SpeedDial
            className="icon--white"
            ariaLabel="SpeedDial tooltip example"
            direction="up"
            sx={mediaQuery}
            color="#dee0df"
            icon={
                <FaWhatsapp className="icon--white" style={{ fontSize: 28 }} />
            }
            onClick={() => setOpen((open) => !open)}
            open={open}
            FabProps={{
                sx: {
                    bgcolor: "success.main",
                    "&:hover": {
                        bgcolor: "success.main",
                    },
                },
            }}
        >
            {actions.map((action) => (
                <SpeedDialAction
                    tooltipPlacement="right"
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    tooltipOpen
                    onClick={() => window.open(action.link, "_blank")}
                />
            ))}
        </SpeedDial>
    );
};

export default WhatsappIconSpeedDial;
