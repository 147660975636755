import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { theme } from '../../../theme/theme';

export const AddSignaturesStyles = makeStyles(() => {

    return createStyles({
        canvasContainer: {
            width: '100%',
            height: 'auto',
            overflow: 'auto',
            background: '#333',
            textAlign: 'center',
            border: 'solid 3px'
        },
        pdfViewer: {
            background: '#ccc',
            position: 'relative',
            margin: '2em auto'
        },
        stepBox: {
            width: "100%"
        },
        mainContainer: {
            width: 'auto'
        },
        textIcon: ({ iconColor }) => ({
            fill: iconColor
        }),
        boxIcon: {
            position: 'absolute',
            bottom: '43px',
            fill: 'red',
            left: '60px',
            zIndex: 999,
            cursor: 'pointer'
        },
        dragableBox: ({ layerX, layerY }) => ({
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'end',
            alignItems: 'baseline',
            position: 'absolute',
            left: `${layerX}px`,
            bottom: `${layerY}px`,
            background: 'transparent',
            cursor: 'move',
            '& p': {
                fontSize: '2rem'
            }
        }),
        signatoriesList: {
            backgroundColor: 'background.paper !important'
        },
        mainContainerDialog: {
            '& div.MuiPaper-root': {
                borderRadius: '10px'
            }
        },
        gooBackButton: {
            backgroundColor: theme.palette.quinary.light,
            color: theme.palette.primary.light,
            paddingLeft: "10px",
            paddingRight: "10px",
            fontWeight: "500",
            textTransform: "none",
            float: "left",
            fontSize: '0.9375rem',
            padding: '8px 10px'
        },
        navigationControls: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            alignItems: 'center'
        },
        hintStyles: {
            color: theme.palette.black.main,
            fontWeight: "300",
            marginBottom: "0"
        },
        titleStyles: {
            color: theme.palette.black.main,
            fontWeight: "500",
            marginBottom: "0",
            fontSize: "1.2rem"
        },
        titleContainer: {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '10px'
        },/* 
        gooBackButton: {
            backgroundColor: theme.palette.quinary.light,
            color: theme.palette.primary.light,
            paddingLeft: "10px",
            paddingRight: "10px",
            fontWeight: "500",
            textTransform: "none",
            float: "left",
            fontSize: '0.9375rem',
            padding: '8px 10px'
        }, */
        buttonSubmitStyles: {
            backgroundColor: theme.palette.primary.light,
            paddingLeft: "10px",
            paddingRight: "10px",
            fontWeight: "500",
            textTransform: "none",
            float: "right",
            fontSize: '0.9375rem',
            padding: '8px 10px'
        }
    });

});