import React from 'react';
import { useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Grid';

import { icons, manageProcedures, to } from '../../../utils/enums';
import { normalizeString } from "../../../utils/transform";
import CardOption from './molecules/cardOption';

const HomeAdministration = () => {
    const navigate = useNavigate();

    return (
        <div className="content-detail__container">

            <div className="detail__container">

                <Grid container spacing={2}>

                    <Grid item xs={12} sm={6} lg={4}>

                        <CardOption
                            title={manageProcedures.MANAGE_USERS}
                            id={normalizeString(manageProcedures.MANAGE_USERS)}
                            subtitle="Ir al tramite"
                            icon={icons.NEW_PROCEDURES_ICON}
                            onClick={() => navigate(to.MANAGE_USERS)}
                        />

                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>

                        <CardOption
                            title={manageProcedures.MANAGE_CLIENTS}
                            id={normalizeString(manageProcedures.MANAGE_CLIENTS)}
                            subtitle="Ir al tramite"
                            icon={icons.REVIEW_PROCEDURES_ICON}
                            onClick={() => navigate(to.MANAGE_CLIENTS)}
                        />

                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>

                        <CardOption
                            title={manageProcedures.MANAGE_CLIENTS}
                            id={normalizeString(manageProcedures.MANAGE_CLIENTS)}
                            subtitle="Ir al tramite"
                            icon={icons.REVIEW_PROCEDURES_ICON}
                            onClick={() => navigate(to.MANAGE_CLIENTS)}
                        />

                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>

                        <CardOption
                            title={manageProcedures.MANAGE_PAYMENT_HISTORY}
                            id={normalizeString(manageProcedures.MANAGE_PAYMENT_HISTORY)}
                            subtitle="Ir al tramite"
                            icon={icons.PAYMENT_PROCEDURES_ICON}
                            onClick={() => navigate(to.MANAGE_PAYMENT_HISTORY)}
                        />

                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>

                        <CardOption
                            title={manageProcedures.MANAGE_PROCEDURES_HISTORY}
                            id={normalizeString(manageProcedures.MANAGE_PROCEDURES_HISTORY)}
                            subtitle="Ir al tramite"
                            icon={icons.SIGNATURE_PROCEDURES_ICON}
                            onClick={() => navigate(to.MANAGE_PROCEDURES_HISTORY)}
                        />

                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>

                        <CardOption
                            title={manageProcedures.MANAGE_SUPPLIERS}
                            id={normalizeString(manageProcedures.MANAGE_SUPPLIERS)}
                            subtitle="Ir al tramite"
                            icon={icons.NOTARIES_PROCEDURES_ICON}
                            onClick={() => navigate(to.MANAGE_PROVIDERS)}
                        />

                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>

                        <CardOption
                            title={manageProcedures.MANAGE_INTEGRATION}
                            id={normalizeString(manageProcedures.MANAGE_INTEGRATION)}
                            subtitle="Ir al tramite"
                            icon={icons.DELIVERY_PROCEDURES_ICON}
                            onClick={() => navigate(to.MANAGE_INTEGRATION)}
                        />

                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>

                        <CardOption
                            title={manageProcedures.DISCOUNT_COUPONS}
                            id={normalizeString(manageProcedures.DISCOUNT_COUPONS)}
                            subtitle="Ir al tramite"
                            icon={icons.DOCUMENTS_PROCEDURES_ICON}
                            onClick={() => navigate(to.DISCOUNT_COUPONS)}
                        />

                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>

                        <CardOption
                            title={manageProcedures.REGISTER_FEA}
                            id={normalizeString(manageProcedures.REGISTER_FEA)}
                            subtitle="Ir al tramite"
                            icon={icons.MANAGE_PROCEDURES_ICON}
                            onClick={() => navigate(to.DASHBOARD_HOME)}
                        />

                    </Grid>

                </Grid>

            </div>

        </div>
    );
};

export default HomeAdministration;