import "regenerator-runtime/runtime";
import axios from "axios";
import jwt_decode from "jwt-decode";
import * as actions from "../actions/api";


const uri = process.env.REACT_APP_API_URI;

const prod = process.env.REACT_APP_PROD

const api =
    ({ dispatch }) =>
        (next) =>
            async (action) => {
                if (action.type !== actions.apiCallStart.type) return next(action);

                const {
                    url,
                    method,
                    data,
                    onStart,
                    onSuccess,
                    onError,
                    contentType,
                    authorization,
                } = action.payload;

                if (onStart) dispatch({ type: onStart });

                next(action);
                try {

                    console.log('nice');

                    if (![null, undefined, ''].includes(authorization)) {

                        // eslint-disable-next-line
                        const timeNow = Math.floor((new Date).getTime() / 1000);

                        const { exp } = jwt_decode(authorization.replace('Bearer ', ''));

                        if (timeNow >= exp) {

                            localStorage.removeItem('persist:root');
                            localStorage.removeItem('session');

                            window.location.href = `${window.location.protocol}//${window.location.hostname}${prod === 'true' ? '' : ':' + window.location.port}`;

                        }

                    }

                    // const previousSignersList = getPreviousSignersList();// Obtiene todos los firmantes de contratos anteriores

                    const response = await axios.request({
                        baseURL: uri,
                        url,
                        method,
                        data,
                        withCredentials: false,
                        headers: {
                            "Access-Control-Allow-Origin": "*",
                            "Content-type": contentType
                                ? contentType
                                : "application/json",
                            Authorization: authorization,
                        },
                    });

                    /* var resquestUserContracts = url.indexOf(urlContractOwner);

                    const userContracts = response.data;

                    // valida que no existe ningun error a la hora de recorrer los contratos y que es valido que se estan consultando los contratos del usuario
                    if (resquestUserContracts >= 0 && (userContracts.message !== undefined && typeof userContracts.message === 'object') && previousSignersList.length === 0) {

                        dispatch(startLoadingSigners());

                        const signerList = await getFirmantes(
                            method,
                            data,
                            false,
                            {
                                "Access-Control-Allow-Origin": "*",
                                "Content-type": contentType
                                    ? contentType
                                    : "application/json",
                                Authorization: authorization,
                            },
                            userContracts
                        );

                        dispatch(addSigners(signerList));

                    }

                    dispatch(stopLoadingSigners()); */

                    dispatch(actions.apiCallSuccess(response.data));
                    if (onSuccess) {
                        dispatch({ type: onSuccess, payload: response.data });
                    }
                } catch (error) {
                    console.error('error', error.message);

                    // dispatch(stopLoadingSigners());// stop loading signers

                    dispatch(actions.apiCallFail(error.message));
                    if (onError) {
                        dispatch({ type: onError, payload: error.message });
                    }
                }
            };

export default api;
