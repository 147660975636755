import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { MdLockOutline, MdKeyboardArrowLeft, MdLockOpen } from "react-icons/md";

import Grid from "@mui/material/Grid";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

import { Btn } from "../../components";
import {
    validation,
    validatePhoneNumber
} from "../../utils/validations";
import { store } from "../../store/storeConfig";
import { setSession } from "../../store/auth/userSignUp";
import { useSelector } from "react-redux";
import ScreenSizeHook from "../../hooks/ScreenSizeHook";
import { optionsSteps, ramdonCharacters, verifiVariable } from "../../utils/enums";
import { Steps } from "intro.js-react";
import stepsRegister from "../toursSteps/tourRegister";

export default function SignUpAccountForm() {
    const navigation = useNavigate();
    const [passwordMatch, setPasswordMatch] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showRePassword, setShowRePassword] = useState(false);
    const [stepsEnabled, setStepsEnabled] = useState(false);
    const screen = ScreenSizeHook();
    const { id: iAgreement } = useParams();

    const {
        session: {
            signup: {
                resetForm,
                status
            }
        }
    } = useSelector((state) => state);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        watch,
    } = useForm();

    const userTypeWatch = watch("sUserType");

    useEffect(() => {
        setStepsEnabled(false);
        reset();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetForm]);

    useEffect(() => {
        console.log('status ===>> ', status);
    }, [status]);

    useEffect(() => {
        // console.log('state ===>> ', state);
        console.log('iAgreement ===>>', iAgreement)
    }, [iAgreement]);

    const inputMrMl = useMemo(() => {
        let estilos = {};

        switch (screen) {
            case "xl":
                estilos = { marginRight: 8, marginLeft: 8 };
                break;

            case "lg":
                estilos = { marginRight: 8, marginLeft: 8 };
                break;

            case "md":
                estilos = { marginRight: 3.5, marginLeft: 3.5 };
                break;

            case "sm":
                estilos = { marginRight: 6, marginLeft: 6 };
                break;

            default:
                estilos = { marginRight: 2, marginLeft: 2 };
                break;
        }

        return estilos;
    }, [screen]);

    const onSubmit = (data) => {
        if (data.sPassword !== data.rePassword) {
            setPasswordMatch(!passwordMatch);
        } else {
            store.dispatch(
                setSession({
                    iAgreement: !verifiVariable(iAgreement) ? iAgreement : 0,
                    iProfileID: 1,
                    sRUT: data.sRUT,
                    sFirstName: data.sFirstName,
                    sLastName: data.sLastName,
                    sEmail: data.sEmail,
                    sPhone: data.sPhone,
                    sLogin: ramdonCharacters(8),
                    sPassword: data.sPassword,
                    sComments:
                        userTypeWatch === "LEGAL"
                            ? data.sComments
                            : `User ${data.sFirstName} created`,
                    sUserType: data.sUserType
                })
            );
        }
    };

    const togglePasswordView = (field, setField) => {
        setField(!field);
    };

    const renderInputPasswordButton = (field, setField) => {
        if (field) {
            return (
                <MdLockOpen
                    className="icon--md icon--blue icon-pointer"
                    onClick={() => togglePasswordView(field, setField)}
                />
            );
        } else {
            return (
                <MdLockOutline
                    className="icon--md icon--blue icon-pointer"
                    onClick={() => togglePasswordView(field, setField)}
                />
            );
        }
    };

    const styles = {
        width: "100%",
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)}>

            <Steps
                enabled={stepsEnabled}
                steps={stepsRegister}
                initialStep={0}
                onExit={() => setStepsEnabled(false)}
                options={optionsSteps}
            />

            <Grid container justifyContent={"center"}>
                <Grid
                    item
                    xs={12}
                    sm={5}
                    sx={{ marginRight: 2, marginLeft: 2 }}
                >
                    <div className="input-container" style={styles}>
                        <label>Nombre</label>
                        <input
                            maxLength={30}
                            type="text"
                            className={
                                errors.sFirstName && "input-container--error"
                            }
                            placeholder="Vicente"
                            {...register("sFirstName", {
                                required: true,
                                maxLength: 30,
                                pattern: validation.nombre,
                            })}
                        />
                        {errors.sFirstName?.type === "required" && (
                            <span className="input--error">
                                Este campo es obligatorio
                            </span>
                        )}
                        {errors.sFirstName?.type === "maxLength" && (
                            <span className="input--error">
                                Solo estan permitidos 30 caracteres
                            </span>
                        )}

                        {errors.sFirstName?.type === "pattern" && (
                            <span className="input--error">
                                No estan permitidos los caracteres especiales
                            </span>
                        )}
                    </div>
                </Grid>

                <Grid
                    item
                    xs={12}
                    sm={5}
                    sx={{ marginRight: 2, marginLeft: 2 }}
                >
                    <div className="input-container" style={styles}>
                        <label>Apellido</label>
                        <input
                            maxLength={30}
                            type="text"
                            className={
                                errors.sLastName && "input-container--error"
                            }
                            placeholder="Contreras"
                            {...register("sLastName", {
                                required: true,
                                maxLength: 30,
                                pattern: validation.nombre,
                            })}
                        />
                        {errors.sLastName?.type === "required" && (
                            <span className="input--error">
                                Este campo es obligatorio
                            </span>
                        )}
                        {errors.sLastName?.type === "maxLength" && (
                            <span className="input--error">
                                Solo estan permitidos 30 caracteres
                            </span>
                        )}

                        {errors.sLastName?.type === "pattern" && (
                            <span className="input--error">
                                No estan permitidos los caracteres especiales
                            </span>
                        )}
                    </div>
                </Grid>

                <Grid
                    item
                    xs={12}
                    sm={5}
                    sx={{ marginRight: 2, marginLeft: 2 }}
                >
                    <div className="input-container" style={styles}>
                        {userTypeWatch === "LEGAL" ? (
                            <label>RUC Empresa (sin puntos y con guion)</label>
                        ) : (
                            <label>
                                RUC
                            </label>
                        )}
                        <input
                            type="text"
                            className={errors.sRUT && "input-container--error"}
                            placeholder="11111111111 ó 111111111"
                            {...register("sRUT", {
                                required: true,
                                /* validate: {
                                    isPassPortOrRut: () => {
                                        if (
                                            [
                                                checkRut(getValues("sRUT")),
                                                validation.passport.test(
                                                    getValues("sRUT")
                                                ),
                                            ].includes(true)
                                        ) {
                                            return true;
                                        } else {
                                            return "...";
                                        }
                                    },
                                }, */
                            })}
                        />
                        <span className="input--hint">
                            Ej: 11111111111 ó 111111111
                        </span>
                        {errors.sRUT?.type === "required" && (
                            <span className="input--error">
                                Este campo es obligatorio
                            </span>
                        )}
                        {errors.sRUT?.type === "isPassPortOrRut" && (
                            <span className="input--error">
                                Ingresa un RUT ó Pasaporte válido
                            </span>
                        )}
                    </div>
                </Grid>

                <Grid
                    item
                    xs={12}
                    sm={5}
                    sx={{ marginRight: 2, marginLeft: 2 }}
                >
                    <div className="input-container" style={styles}>
                        <label>Número de teléfono</label>
                        <input
                            type="text"
                            className={
                                errors.sPhone && "input-container--error"
                            }
                            placeholder="+51912345478"
                            {...register("sPhone", {
                                required: true,
                                validate: {
                                    validPhoneNumber: (value) =>
                                        validatePhoneNumber(value) ||
                                        "Formato de numero telefonico invalido",
                                    validateBlanks: (value) => !/\s/g.test(value) || 'No estan permitidos los espacios en blanco'
                                }
                            })}
                        />
                        {errors.sPhone?.type === "required" && (
                            <span className="input--error">
                                Este campo es obligatorio
                            </span>
                        )}
                        {errors.sPhone?.type === "validPhoneNumber" && (
                            <span className="input--error">
                                Número de teléfono no válido
                            </span>
                        )}
                        {errors.sPhone?.type === "validateBlanks" && (
                            <span className="input--error">
                                No estan permitidos los espacios en blanco
                            </span>
                        )}
                    </div>
                </Grid>

                <Grid
                    item
                    xs={12}
                    sm={5}
                    sx={{ marginRight: 2, marginLeft: 2 }}
                >
                    <div className="input-container" style={styles}>
                        <label>Correo electrónico</label>
                        <input
                            type="text"
                            className={
                                errors.sEmail && "input-container--error"
                            }
                            placeholder="tucorreo@mail.com"
                            {...register("sEmail", {
                                required: true,
                                pattern: validation.email,
                            })}
                        />
                        {errors.sEmail?.type === "required" && (
                            <span className="input--error">
                                Este campo es obligatorio
                            </span>
                        )}
                        {errors.sEmail?.type === "pattern" && (
                            <span className="input--error">
                                Ingresa un correo electrónico válido
                            </span>
                        )}
                    </div>
                </Grid>

                <Grid
                    item
                    xs={12}
                    sm={5}
                    sx={{ marginRight: 2, marginLeft: 2 }}
                >
                    <FormControl>
                        <label id="demo-controlled-radio-buttons-group">
                            Tipo de Usuario
                        </label>

                        <RadioGroup
                            row
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                        >
                            <FormControlLabel
                                value="LEGAL"
                                {...register("sUserType", {
                                    required: true,
                                })}
                                control={<Radio />}
                                label="Empresa"
                            />

                            <FormControlLabel
                                value="NATURAL"
                                {...register("sUserType", {
                                    required: true,
                                })}
                                control={<Radio />}
                                label="Natural"
                            />
                        </RadioGroup>

                        {errors.sUserType?.type === "required" && (
                            <span className="input--error">
                                Debe seleccionar una opción
                            </span>
                        )}
                    </FormControl>
                </Grid>

                {userTypeWatch === "LEGAL" && (
                    <Grid item xs={12} sm={11} md={11} sx={inputMrMl}>
                        <div className="input-container" style={styles}>
                            <label>Nombre de Empresa</label>
                            <input
                                maxLength={30}
                                type="text"
                                className={
                                    errors.sComments && "input-container--error"
                                }
                                placeholder="Nombre de la Empresa"
                                {...register("sComments", {
                                    required: true,
                                    maxLength: 30,
                                    pattern: validation.nombre,
                                })}
                            />
                            {errors.sComments?.type === "required" && (
                                <span className="input--error">
                                    Este campo es obligatorio
                                </span>
                            )}
                            {errors.sComments?.type === "maxLength" && (
                                <span className="input--error">
                                    Solo estan permitidos 30 caracteres
                                </span>
                            )}

                            {errors.sComments?.type === "pattern" && (
                                <span className="input--error">
                                    No estan permitidos los caracteres
                                    especiales
                                </span>
                            )}
                        </div>
                    </Grid>
                )}

                <Grid
                    item
                    xs={12}
                    sm={5}
                    sx={{ marginRight: 2, marginLeft: 2 }}
                >
                    <div className="input-container" style={styles}>
                        <label>Contraseña</label>
                        <div className="input-container">
                            <div className="input-container--relative">
                                <input
                                    type={!showPassword ? "password" : "text"}
                                    className={
                                        errors.sPassword &&
                                        "input-container--error"
                                    }
                                    {...register("sPassword", {
                                        required: true,
                                        minLength: 8,
                                        maxLength: 128,
                                        validate: {
                                            regexMayuscula: (value) => /[A-Z]/g.test(value) || 'Debe contener al menos una letra mayuscula',
                                            regexMinuscula: (value) => /[a-z]/g.test(value) || 'Debe contener al menos una letra minuscula',
                                            regexNumero: (value) => /\d/g.test(value) || 'Debe contener al menos un numero',
                                            // eslint-disable-next-line
                                            regexCaracterEspecial: (value) => /[@_!#$%^&*()<>?/\|}{~:]/g.test(value) || 'Debe contener al menos un caracter especial'
                                        }
                                    })}
                                />
                                <div className="input-icon--right show-password">
                                    {renderInputPasswordButton(
                                        showPassword,
                                        setShowPassword
                                    )}
                                </div>
                            </div>


                            {errors.sPassword?.type === "regexMayuscula" && (
                                <span className="input--error">
                                    Debe contener al menos una letra mayuscula
                                </span>
                            )}

                            {errors.sPassword?.type === "regexMinuscula" && (
                                <span className="input--error">
                                    Debe contener al menos una letra minuscula
                                </span>
                            )}

                            {errors.sPassword?.type === "regexNumero" && (
                                <span className="input--error">
                                    Debe contener al menos un numero
                                </span>
                            )}

                            {errors.sPassword?.type === "regexCaracterEspecial" && (
                                <span className="input--error">
                                    Debe contener al menos un caracter especial
                                </span>
                            )}
                            
                            {errors.sPassword?.type === "required" && (
                                <span className="input--error">
                                    Este campo es obligatorio
                                </span>
                            )}
                            {errors.sPassword?.type === "minLength" && (
                                <span className="input--error">
                                    Por su seguridad, debe ingresar una
                                    contrasena de mas de 8 caracteres.
                                </span>
                            )}
                            {errors.sPassword?.type === "maxLength" && (
                                <span className="input--error">
                                    La cantidad maxima de caracteres para su
                                    contrasena debe ser de 128 caracteres.
                                </span>
                            )}
                        </div>
                    </div>
                </Grid>

                <Grid
                    item
                    xs={12}
                    sm={5}
                    sx={{ marginRight: 2, marginLeft: 2 }}
                >
                    <div className="input-container" style={styles}>
                        <label>Repita la contraseña</label>
                        <div className="input-container">
                            <div className="input-container--relative">
                                <input
                                    type={!showRePassword ? "password" : "text"}
                                    className={
                                        errors.rePassword || passwordMatch
                                            ? "input-container--error"
                                            : ""
                                    }
                                    {...register("rePassword", {
                                        required: true,
                                        minLength: 8,
                                        maxLength: 128,
                                    })}
                                />
                                <div className="input-icon--right">
                                    {renderInputPasswordButton(
                                        showRePassword,
                                        setShowRePassword
                                    )}
                                </div>
                            </div>
                            {errors.rePassword?.type === "required" && (
                                <span className="input--error">
                                    Este campo es obligatorio
                                </span>
                            )}
                            {errors.rePassword?.type === "minLength" && (
                                <span className="input--error">
                                    Por su seguridad, debe ingresar una
                                    contrasena de mas de 8 caracteres.
                                </span>
                            )}
                            {errors.rePassword?.type === "maxLength" && (
                                <span className="input--error">
                                    La cantidad maxima de caracteres para su
                                    contrasena debe ser de 128 caracteres.
                                </span>
                            )}
                            {passwordMatch && (
                                <span className="input--error">
                                    La contraseña no coinciden
                                </span>
                            )}
                        </div>
                    </div>
                </Grid>

                <Grid
                    item
                    xs={12}
                    sm={9}
                    md={8}
                    sx={{ marginRight: 2, marginLeft: 2 }}
                >
                    <Btn
                        variant="contained"
                        text="Registrarse"
                        size="lg"
                        color="primary"
                        type="submit"
                        buttonClass="button-submit buttom-register"
                        fullWidth
                    />
                </Grid>

                <Grid
                    item
                    xs={12}
                    sm={9}
                    md={8}
                    sx={{ marginRight: 2, marginLeft: 2 }}
                >
                    <div className="flex--center back-option">
                        <Btn
                            variant="text"
                            text="Atrás para iniciar sesión"
                            buttonClass="button-link"
                            onClick={() => navigation("/")}
                            startIcon={
                                <MdKeyboardArrowLeft className="icon--md icon--blue" />
                            }
                        />
                    </div>
                </Grid>
            </Grid>
        </form>
    );
}
