import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    /* typography: {
        fontFamily: [
            '"PoppinsBold"'
        ].join(',')
    }, */
    palette: {
        customBlueLight: {
            main: "#00adea"
        },
        white: {
            main: "#FFFFFF",
        },
        black: {
            main: "#000000",
        },
        gray: {
            main: "#808080",
            light: "#D3D3D3",
            dark: "#A9A9A9"
        },
        primary: {
            main: "#004f9f",
            xlight: "#6ACEF1",
            light: "#00adea",
            dark: "#003c77",
        },
        secondary: {
            main: "#ff326e",
            xlight: "#fff5f8",
            light: "#f86262",
            dark: "#cf3966",
        },
        tertirary: {
            main: "#fa8724",
            xlight: "#fff8dd",
            light: "#ffb676",
            dark: "#db6d0d",
        },
        quaternary: {
            main: "#5cc858",
            xlight: "#e8fff3",
            light: "#87e483",
            dark: "#4ca149",
        },
        quinary: {
            main: "#b4bacc",
            light: "#f6f9ff",
            dark: "#a0aac7",
            xdark: "#747c91",
        },
        senary: {
            main: "#7239ea",
            light: "#9667fd",
            dark: "#4e289e",
            xdark: "#372266",
        },
        success: {
            main: "#5cc858",
        },
        error: {
            main: "#cf3966",
        },
        info: {
            main: "#ffb676",
        },
        warning: {
            main: "#f86262",
        },
        customFormButtomSolicitud: {
            main: '#f39201'
        }
    },
    spacing: 8,
});

export { theme };
