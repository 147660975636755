import { Fragment, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { MdModeEdit, MdOutlineCancelPresentation, MdCloudUpload } from "react-icons/md";

import FilePreview from "react-file-preview-latest";

import ControlCameraIcon from '@mui/icons-material/ControlCamera';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import { styled } from '@mui/material/styles';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { ReactComponent as UploadIcon } from "../../img/upload-icon.svg";
import { ReactComponent as FolderIcon } from "../../img/folder.svg";
import { ReactComponent as CloseIcon } from "../../img/close-icon.svg";

import { ContractDetailReviewStyles } from "../../index.css";
import { asyncFileToBase64, fileToBase64 } from "../../../../../utils/enums";
import { mergeDocuments } from "../../../../paperworkProcess/documents/services";
import Text from "../../../../heading/Text";

let counter = 0;

const UploadCustomButtom = styled(Button)((props) => ({
    borderRadius: '7px',
    fontStyle: 'normal',
    boxShadow: 'none',
    fontWeight: 'bolder',
    textTransform: 'none',
    fontSize: 16,
    padding: '11px 81px',
    marginTop: '11px',
    marginBottom: '20px',
    border: `1px solid ${props.theme.palette.primary.light}`,
    '&:hover': {
        border: `1px solid ${props.theme.palette.primary.light}`
    },
    fontFamily: [
        '"PoppinsBold"'
    ].join(','),
}));

const TextIconfolder = styled(Typography)((props) => ({
    color: props.theme.palette.gray.dark,
    fontFamily: [
        '"PoppinsBold"'
    ].join(',')
}));

const MergeCustomButtom = styled(Button)((props) => ({
    borderRadius: '7px',
    fontStyle: 'normal',
    boxShadow: 'none',
    fontWeight: 'bolder',
    textTransform: 'none',
    fontSize: 16,
    padding: '11px 32px',
    marginTop: '11px',
    border: `1px solid ${props.theme.palette.primary.light}`,
    '&:hover': {
        border: `1px solid ${props.theme.palette.primary.light}`
    },
    fontFamily: [
        '"PoppinsBold"'
    ].join(',')
}));

const CancelMergeCustomButtom = styled(Button)((props) => ({
    borderRadius: '7px',
    fontStyle: 'normal',
    boxShadow: 'none',
    fontWeight: 'bolder',
    textTransform: 'none',
    color: props.theme.palette.secondary.main,
    fontSize: 16,
    padding: '11px 32px',
    marginTop: '11px',
    border: `1px solid ${props.theme.palette.secondary.light}`,
    '&:hover': {
        border: `1px solid ${props.theme.palette.secondary.light}`
    },
    fontFamily: [
        '"PoppinsBold"'
    ].join(',')
}));

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    bgcolor: 'background.paper',
    boxShadow: '0px 4px 11px 5px rgba(0, 79, 159, 0.04)',
    borderRadius: '7px',
    p: 4
};

const ChangeModalDocs = ({ loading, textLoading, resultMergeDocs }) => {
    const styles = ContractDetailReviewStyles();
    const inputMergeRef = useRef(null);
    const inputFileRef = useRef(null);
    const dragItem = useRef(null);
    const dragOverItem = useRef(null);
    const {
        session: {
            login: {
                user
            }
        }
    } = useSelector((state) => state);
    const [open, setOpen] = useState(false);
    const [showMergeContainer, setShowMergeContainer] = useState(false);
    const [arrayMerge, setArrayMerge] = useState([]);


    const handleFileClick = () => {
        inputFileRef.current.click();
    };

    const handleFileChange = event => {
        const fileObj = event.target.files && event.target.files[0];

        if (!fileObj) {
            return;
        }

        // 👇️ reset file input
        event.target.value = null;

        // 👇️ is now empty
        // console.log(event.target.files);

        // 👇️ can still access file object here
        // console.log(fileObj);
        // console.log(fileObj.name);

        fileToBase64(fileObj, (err, result) => {
            if (result) {

                const docPdfBase64 = result.replace('data:application/pdf;base64,', '');

                counter = counter + 1;

                resultMergeDocs({ file: docPdfBase64, counter });

                handleClose();

                setArrayMerge([]);

            }
        });

    };

    const mergeDocs = async () => {

        try {

            loading(true);
            textLoading('Uniendo documentos');

            const { token } = user;

            const data = {
                file_sort: [...arrayMerge.map((item, index) => {
                    return {
                        document: item['docPdfBase64'],
                        order: 1 + index
                    }
                })]
            };

            const response = await mergeDocuments(token, data);

            counter = counter + 1;

            resultMergeDocs({ file: response['data']['file'], counter });

            handleClose();

            setArrayMerge([]);

            loading(false);

        } catch (error) {
            loading(false);
            console.log(error);
        }

    };

    const removeFileFromArray = (name) => {
        const arrayData = [...arrayMerge].filter((item) => item['name'] !== name);
        setArrayMerge(arrayData);
    }

    const handleSort = () => {
        //duplicate items
        let _arrayMerge = [...arrayMerge]

        //remove and save the dragged item content
        const draggedItemContent = _arrayMerge.splice(dragItem.current, 1)[0];

        //switch the position
        _arrayMerge.splice(dragOverItem.current, 0, draggedItemContent);

        //reset the position ref
        dragItem.current = null;
        dragOverItem.current = null;

        //update the actual array
        setArrayMerge(_arrayMerge);
    }

    const handleMergeClick = () => {
        inputMergeRef.current.click();
    };

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setArrayMerge([]);
    };


    const handleFileMergeChange = async event => {

        const arrayData = [...arrayMerge];

        for (let index = 0; index < event.target.files.length; index++) {
            const element = event.target.files[index];

            const restDocPdfBase64 = await asyncFileToBase64(element);

            const docPdfBase64 = restDocPdfBase64.split("base64,");

            arrayData.push({ file: element, name: element.name, docPdfBase64: docPdfBase64[1] });
        }

        event.target.value = null;

        setArrayMerge(arrayData);

    };


    return (

        <Fragment>

            <Tooltip
                title={
                    <Typography variant="subtitle1" color={['white.main']} gutterBottom>
                        Reemplazar Contrato
                    </Typography>
                }
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                arrow
            >
                <UploadIcon onClick={() => handleOpen()} />
            </Tooltip>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500
                    }
                }}
            >
                <Fade in={open}>

                    <Box sx={style}>

                        <div className={styles.buttomClose}>
                            <CloseIcon onClick={handleClose} />
                        </div>

                        <Typography id="transition-modal-title" variant="h6" color={['black.main']}>
                            Adjuntar documentos
                        </Typography>

                        <Typography id="transition-modal-description" sx={{ mt: 2, fontWeight: 700, fontSize: '1rem' }}>
                            Adjunta los documentos necesarios para continuar con el  trámite.
                        </Typography>

                        <Typography id="transition-modal-description" sx={{ fontWeight: 400, fontSize: '1rem' }}>
                            Recuerda adjuntar como mínimo contrato a firmar y cédulas de los participantes o firmantes.
                        </Typography>

                        <Typography id="transition-modal-description" sx={{ mt: 2, fontWeight: 700, fontSize: '1rem' }} color={['primary.main']}>
                            Recuerda que todos los documentos deben de estar unidos para ser parte de un (1) archivo PDF.
                        </Typography>


                        {!showMergeContainer && (<div className={`${styles.fileButomsContainer} form__container`}> {/* && !fileTramit */}

                            <div className={styles.uploadButtomContainer}>

                                <input
                                    hidden
                                    accept='application/pdf'
                                    style={{ display: 'none' }}
                                    ref={inputFileRef}
                                    type="file"
                                    onChange={handleFileChange}
                                />

                                <Text className={`${styles.subTitleStyles} ${styles.titleStyles}`} align="center">
                                    ¿Ya cuentas con todos tus archivos unidos en (1) sólo PDF?
                                </Text>

                                <UploadCustomButtom
                                    onClick={() => handleFileClick()}
                                    variant="outlined"
                                    color="primary"
                                    startIcon={
                                        <MdCloudUpload className='icon--blue icon--lg' />
                                    }
                                >
                                    Subir archivo
                                </UploadCustomButtom>

                                <div>o</div>

                                <Text className={`${styles.subTitleStyles} ${styles.titleStyles}`} sx={{ marginTop: '11px' }} align="center">
                                    ¿Necesitas unir tus documentos en un solo archivo PDF?
                                </Text>

                                <MergeCustomButtom
                                    onClick={() => setShowMergeContainer((validate) => !validate)}
                                    variant="outlined"
                                    color="primary"
                                    startIcon={
                                        <MdModeEdit
                                            style={{
                                                borderRadius: '5px'
                                            }}
                                            className='icon--white icon--md icon-back-ground-color-blue'
                                        />}
                                >
                                    Unir PDF con mis archivos
                                </MergeCustomButtom>

                            </div>

                        </div>)}

                        {showMergeContainer && (<div className={`${styles.fileButomsContainer} form__container`}>

                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>

                                {arrayMerge.length >= 2 && (<MergeCustomButtom
                                    onClick={() => mergeDocs()}
                                    variant="outlined"
                                    color="primary"
                                    startIcon={
                                        <MdModeEdit
                                            style={{
                                                borderRadius: '5px'
                                            }}
                                            className='icon--white icon--md icon-back-ground-color-blue'
                                        />}
                                >
                                    Unir archivos
                                </MergeCustomButtom>)}

                                <CancelMergeCustomButtom
                                    style={{ marginLeft: arrayMerge.length >= 2 ? '20px' : '0px' }}
                                    onClick={() => {
                                        setShowMergeContainer((value) => !value);
                                        setArrayMerge([]);
                                    }}
                                    variant="outlined"
                                    color="primary"
                                    startIcon={
                                        <MdOutlineCancelPresentation
                                            style={{
                                                borderRadius: '5px',
                                            }}
                                            className='icon--white icon--md icon-back-ground-color-red'
                                        />}
                                >
                                    Cancelar
                                </CancelMergeCustomButtom>

                            </div>

                            {arrayMerge.map((item, index) => {
                                return (
                                    <div
                                        className={styles.fileMergePreview}
                                        key={item['name'] + index}
                                        draggable
                                        onDragStart={(e) => (dragItem.current = index)}
                                        onDragEnter={(e) => (dragOverItem.current = index)}
                                        onDragEnd={handleSort}
                                        onDragOver={(e) => e.preventDefault()}
                                    >
                                        <div className={styles.conatinerCloseButtomPreview}>
                                            <ControlCameraIcon className='icon--blue-light icon-move' />

                                            <DisabledByDefaultIcon className='icon--red icon-pointer' onClick={() => removeFileFromArray(item['name'])} />
                                        </div>

                                        <Typography variant="subtitle1" gutterBottom align='center' color={'gray.dark'}>
                                            {item['name'].length < 19 ? item['name'] : item['name'].substring(0, 15) + '...'}
                                        </Typography>

                                        <FilePreview
                                            type={"file"}
                                            file={item['file']}
                                            onError={() => console.log('error')}
                                        />

                                    </div>
                                );
                            })}

                            <div className={styles.folderIconContainer} onClick={handleMergeClick}>

                                <input
                                    hidden
                                    accept='image/png, image/jpeg, image/jpg, application/pdf, .docx, .docm, .doc, .pptx, .pptm, .potx, .ppsx'
                                    style={{ display: 'none' }}
                                    ref={inputMergeRef}
                                    type="file"
                                    multiple
                                    onChange={handleFileMergeChange}
                                />

                                <FolderIcon />

                                <TextIconfolder variant="subtitle1" gutterBottom>
                                    Añadir archivo
                                </TextIconfolder>

                            </div>

                        </div>)}

                    </Box>

                </Fade>

            </Modal>

        </Fragment>

    );

}

export default ChangeModalDocs;