import { contractTypes } from "../utils/enums";

const procedureType = [
    // { name: "Inmobiliario", value: contractTypes.REAL_STATE },
    { name: "Laboral", value: contractTypes.LABOR }/* ,
    { name: "Poderes", value: contractTypes.POWER },
    { name: "Personales", value: contractTypes.PERSONAL },
    { name: "Sociedades", value: contractTypes.SOCIETY },
    { name: "Vehículo", value: contractTypes.VEHICLE }, */
];

const contractType = [
    /* {
        name: "Orden de arriendo",
        value: "ORDEN DE ARRIENDO",
        procedureType: contractTypes.REAL_STATE,
    },
    {
        name: "Promesa de compraventa",
        value: "PROMESA DE COMPRAVENTA",
        procedureType: contractTypes.REAL_STATE,
    },
    {
        name: "Contrato de arriendo",
        value: "CONTRATO DE ARIENDO",
        procedureType: contractTypes.REAL_STATE,
    },
    {
        name: "Mandato de administración",
        value: "MANDATO DE ADMINISTRACION",
        procedureType: contractTypes.REAL_STATE,
    },
    {
        name: "Prórroga de promesa de compraventa",
        value: "PRORROGA DE PROMESA DE COMPRAVENTA",
        procedureType: contractTypes.REAL_STATE,
    },
    {
        name: "Resciliación de promesa de compraventa",
        value: "RESCILIACION DE PROMESA DE COMPRAVENTA",
        procedureType: contractTypes.REAL_STATE,
    },
    {
        name: "Resciliación de contrato de arriendo",
        value: "RESCILIACION DE CONTRATO DE ARRIENDO",
        procedureType: contractTypes.REAL_STATE,
    },
    {
        name: "Carta de contra oferta",
        value: "CARTA DE CONTRA OFERTA",
        procedureType: contractTypes.REAL_STATE,
    },
    {
        name: "Orden de venta",
        value: "ORDEN DE VENTA",
        procedureType: contractTypes.REAL_STATE,
    },
    {
        name: "Carta de oferta",
        value: "CARTA OFERTA",
        procedureType: contractTypes.REAL_STATE,
    },
    {
        name: "Orden de visita",
        value: "ORDEN DE VISITA",
        procedureType: contractTypes.REAL_STATE,
    }, */

    {
        name: "Carta de cese",
        value: "CARTA DE CESE",
        procedureType: contractTypes.LABOR
    },
    {
        name: "Liquidación por beneficios sociales",
        value: "LIQUIDACION POR BENEFICIOS SOCIALES",
        procedureType: contractTypes.LABOR
    },
    {
        name: "Certificado de trabajo",
        value: "CERTIFICADO DE TRABAJO",
        procedureType: contractTypes.LABOR
    },
    {
        name: "contrato de confidencialidad y no divulgación",
        value: "CONTRATO DE CONFIDENCIALIDAD Y NO DIVULGACION",
        procedureType: contractTypes.LABOR
    },
    {
        name: "Politicas internas y otros codigos de conducta",
        value: "POLITICAS INTERNAS Y OTROS CODIGOS DE CONDUCTA",
        procedureType: contractTypes.LABOR

    }/* ,
    {
        name: "Poder simple",
        value: "PODER SIMPLE",
        procedureType: contractTypes.POWER,
    },
    {
        name: "Certificado de estado civil",
        value: "CERTIFICADO DE ESTADO CIVIL",
        procedureType: contractTypes.PERSONAL,
    },
    {
        name: "Declaración jurada",
        value: "DECLARACION JURADA",
        procedureType: contractTypes.PERSONAL,
    },
    {
        name: "Legalización copia cédula de identidad",
        value: "LEGALIZACION COPIA DE CEDULA DE IDENTIDAD",
        procedureType: contractTypes.PERSONAL,
    },
    {
        name: "Uso de domicilio",
        value: "USO DE DOMICILIO",
        procedureType: contractTypes.PERSONAL,
    },
    {
        name: "Certificación domicilio",
        value: "CERTIFICACIÓN DOMICILIO",
        procedureType: contractTypes.PERSONAL,
    },
    {
        name: "Permiso de mudanza",
        value: "PERMISO DE MUDANZA",
        procedureType: contractTypes.PERSONAL,
    },
    {
        name: "Salvoconducto",
        value: "SALVO CONDUCTO",
        procedureType: contractTypes.PERSONAL,
    },
    {
        name: "Cesión de Contrato",
        value: "CESION DE CONTRATO",
        procedureType: contractTypes.SOCIETY,
    },
    {
        name: "Finiquito Empresarial",
        value: "FINIQUITO EMPRESARIAL",
        procedureType: contractTypes.SOCIETY,
    },
    {
        name: "Comercial",
        value: "COMERCIAL",
        procedureType: contractTypes.SOCIETY,
    },
    {
        name: "Promesa de compraventa de vehículo",
        value: "PROMESA DE COMPRAVENTA DE VEHICULO",
        procedureType: contractTypes.VEHICLE,
    }, */
];

const signaturesType = [
    {
        name: "Firma Electrónica - SIN Notaría (sin certificación, ni protocolización notarial) - Sólo firma electrónica",
        value: "SIGNATURE",
    },
    {
        name: "Firma Electrónica + Certificación Notarial Online (ideal para contratos de arriendos, administración, certificados laborales, salvoconductos, declaraciones juradas, poderes simples, etc. )",
        value: "SIGNATURE_CERT_NOTARIAL",
    },
    {
        name: "Mayor Seguridad Legal Digital: Firma electrónica + Protocolización Notarial Online con repertorio y fecha (ideal para arriendos más seguros, promesas, contratos laborales, etc)",
        value: "SIGNATURE_PROT_NOTARIAL",
    },
];

export { procedureType, contractType, signaturesType };
