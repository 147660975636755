import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { DataGrid } from "@mui/x-data-grid";

import CustomToolbar from "./molecules/CustomToolbar";
import { styles } from "../../../helpers";
import ContractListHeader from "../ContractListHeader";
import { gridColumns, localizedTextsMap } from "./constants";
import { getContractsCreated } from "../../../store/contract/contractDetail";
import { Spinner } from "../..";
import { Box } from "@mui/system";

const RevisionManagement = () => {
    const {
        session: {
            login: { user },
        },
        contract: {
            contractCreator: { loading, contractListActive },
        },
    } = useSelector((state) => state);
    const dispatch = useDispatch();
    const genKey = () =>
        setKey(
            Math.random()
                .toString()
                .split(".")
                .reduce((a, b) => a + b)
        );
    const [key, setKey] = useState("");
    const [dataGrid, setDataGrid] = useState([]);
    const [spinner, setSpinner] = useState(false);

    useEffect(() => {
        const { userId, token, profileId } = user;

        if (![null, undefined].includes(userId)) {
            dispatch(getContractsCreated(userId, token, profileId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        const { status, message } = contractListActive;

        if (["success"].includes(status)) {
            setDataGrid(message);
        }
    }, [contractListActive]);

    const updateData = () => {
        const { userId, token, profileId } = user;
        setSpinner(true);
        if (![null, undefined].includes(userId)) {
            dispatch(getContractsCreated(userId, token, profileId));
        }

        setTimeout(() => {
            setSpinner(false);
        }, 5000);
    };

    const renderList = useCallback(() => {
        const arrayList = dataGrid.filter(
            (item) => item.sStatus === "CREATED" || item.sStatus === "REVIEW" || item.sStatus === "REJECTED"
        );

        return (
            <div
                className="container--relative contracts-status-list__container"
                style={{ height: "auto", width: "100%" }}
            >
                {(spinner || loading) ? (
                    <Box
                        sx={{ height: "150px" }}
                        className="container--relative contracts-status-list__container"
                    >
                        <Spinner loading={true} type="bar" opacity={true} />
                    </Box>
                ) : (
                    <div style={{ display: "flex", height: "100%" }}>
                        <div style={{ flexGrow: 1 }}>
                            <DataGrid
                                rows={arrayList}
                                columns={gridColumns}
                                pageSize={8}
                                rowsPerPageOptions={[8]}
                                autoHeight={true}
                                autoPageSize={true}
                                getRowId={(row) => row.sContractID}
                                getCellClassName={null}
                                rowHeight={56}
                                loading={spinner || loading}
                                sx={styles.gridContainerStyles}
                                localeText={localizedTextsMap}
                                disableSelectionOnClick={true}
                                components={{
                                    Toolbar: () => (
                                        <CustomToolbar
                                            genKey={genKey}
                                            updateData={() => updateData()}
                                        />
                                    ),
                                    NoRowsOverlay: () => (
                                        <div className="data-grid__no-rows">
                                            No hay registros
                                        </div>
                                    ),
                                }}
                                sortingOrder={["desc", "asc"]}
                                initialState={{
                                    sorting: {
                                        sortModel: [
                                            {
                                                field: "dContractDT",
                                                sort: "desc",
                                            },
                                        ],
                                    },
                                }}
                            />
                        </div>
                    </div>
                )}
            </div>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataGrid, spinner, loading]);

    return (
        <div className="content-detail__container" key={key}>
            <ContractListHeader />
            {renderList()}
        </div>
    );
};

export default RevisionManagement;
